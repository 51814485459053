

import { configureStore } from '@reduxjs/toolkit'

import tokenInfoReducer from './getBearortoken';
import serviceInfoReducer from './getServices';
import cultureReducer from './CultureSlice'
import alertReducer from './alertSlice'

import languageReducer from './language';
export default configureStore({
  reducer: {
    tokenInfo: tokenInfoReducer,
    serviceInfo: serviceInfoReducer,
    language: languageReducer,
    alert: alertReducer,
    culture: cultureReducer,

  },
})