import { lang } from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch, Link, Routes, Route } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";



function InnerBanner({ title }) {

  const [searchParams, setSearchParams] = useSearchParams();
  const lang = useSelector((state) => state.language.data);

  let appView = searchParams.get("isMobileApp")
  const pathArray = window.location.pathname.split("/");
  return (

    <div className='inner_banner'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-9'>
            <div className='inner_banner_hd'>
              <h2><i className='squre_hd'></i> {title}</h2>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='back_arrow'>
              {appView != "true" && <>
              {pathArray[1] == "services" &&
                <Link to={process.env.REACT_APP_BASE_URL}>
                  <i className='back_arrowicon'></i>{lang.back }
                </Link>
                ||
                <Link to={process.env.REACT_APP_BASE_URL+'services'}>
                  <i className='back_arrowicon'></i>{lang.back }
                </Link>
              }
             </>}


            </div>
          </div>
        </div>
      </div>
    </div>

  )
} export default InnerBanner;