import React, { useEffect, useState } from 'react';


import InnerBanner from '../../components/common/inner_banner';

import MeetNAssistDetail from "../../assets/images/meet_assist_service.jpg"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from 'react-autocomplete';
import {  Link} from 'react-router-dom';
import moment from "moment";

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { getAirportList, getTimeData, postServices, setPhoneCodeList } from '../../services/common'
import { getServiceDetailsById } from '../../scripts/common';
import ServiceBoxLeft from './ServiceBoxLeft';
import UserList from '../../components/common/UserList';
import { useNavigate } from 'react-router-dom';


import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import FulloverLoader from '../../components/common/FulloverLoader';

const serviceId = 23;

function GovernmentalServices({ setShowLoginModal }) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm();
  let lang = useSelector((state) => state.language.data);
  const current_culture = useSelector(state => state.culture.value);
  let myProfile = JSON.parse(localStorage.getItem("myProfile"));
  
  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState('');
  const [phoneCode, setPhoneCode] = useState([])
  const [mobileCode, setMobileCode] = useState(process.env.REACT_APP_MOBCODE);
  const [walletData, setWalletData] = useState(null)
  const tokenData = useSelector((state) => state.tokenInfo);

  let navigate = useNavigate();
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [serviceDetails, setserviceDetails] = useState();
  const [selectedService, setSelectedService] = useState("")
  useEffect(() => {
    window.scrollTo(0, 0);

    const serviceDetailsData = getServiceDetailsById(serviceId);
    setserviceDetails(serviceDetailsData)

    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList()

      setPhoneCode(phoneCodeList)
    }

    fetchPhoneCode()
  }, []);

  
  const onSubmit = async (data) => {
    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true)
    } else {
      setIsLoading(true)
console.log(data)

      let governmentalServicesParams = {
        ServiceID: serviceId,
        /* OnBehalfId: onBehalfId, */
        Data: JSON.stringify({
          name: data.name,
          email: data.email,
          mobile_code: data.mobileCode,
          contact_number: data.contact_number,
          service_type: data.select_service_type,
         /*  service_description: data.serviceDescription, */
          additional_information: data.additional_information,
          
          // fieldOrder:'name,email,mobile_code,contact_number,select_meet_type_assist,date_of_service,estimated_time_for_arrival_departure,number_of_passengers,select_airport,flight_number_with_airline_code,additional_information,special_requests'
        })
      }
      if(onBehalfId) {
        governmentalServicesParams["OnBehalfId"] = onBehalfId
      }
      const response = await postServices(governmentalServicesParams);
      if (response) {
        dispatch(alertActions.setPopUpType('success'))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: "" }))
        dispatch(alertActions.setPopUpData({ heading: 'Success', message: lang.form_submitted_sucessfully  }))
        resetForm();
        setIsLoading(false)

      } else {
        dispatch(alertActions.setPopUpType('error'))
        dispatch(alertActions.setPopUpData({ heading: 'Failed', message: lang.something_went_wrong_please_try_again }))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))

        setIsLoading(false)
      }
    }

  }
  const resetForm = () => {
    reset()
    setMobileCode(process.env.REACT_APP_MOBCODE)
    setSelectedService("")
    setOnBehalfId("")
    setValue('OnBehalfId',"")
    setUserText('')
    setWalletData(null)

  }

  
  return (

    <React.Fragment>
      {
        isLoading &&
        <FulloverLoader />
      }
      <InnerBanner title={lang.governmental_services}></InnerBanner>

      <section className='content_service_main form_bg'>
        <div class='container'>
          <div className='form_breadcrumbs'>
            <ul>
               <li><Link to={process.env.REACT_APP_BASE_URL+'services'}>{lang.services} </Link></li>
              <li><a className='active' href=''>{lang.governmental_services}</a></li>
            </ul>
          </div>
          <div className='service_box'>
            {
              serviceDetails &&
              <ServiceBoxLeft
                serviceDetails={serviceDetails}
              />
            }
            <div className='service_box_col_rht'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='service_detail_formbox formstyle'>
                  <div className='service_detail_formhd'>
                    <h2>{lang.feel_like_contacting_us}</h2>
                    <p>{lang.submit_the_form_below_we_will_get_back_to_you_asap}</p>
                  </div>
                  <hr className='form_hd_hr'>
                  </hr>
                  {(myProfile && myProfile.isAgentLogin && ! tokenData.data.isDefaultUser) &&
                    <UserList
                      register={register}
                      onBehalfId={onBehalfId}
                      setOnBehalfId={setOnBehalfId}
                      errors={errors}
                      control={control}
                      userText={userText}
                      setUserText={setUserText}
                      walletData={walletData}
                      setWalletData={setWalletData}
                    />
                  }
                  <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.name}<span>*</span></label>
                        <input type="text" class="form-control"

                          {...register('name', {
                            required: lang.name_is_required,
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,

                            },
                            pattern: {
                              value: /[A-Za-z]/,
                              message:  lang.please_enter_valid_name,
                            },
                          })}
                        >
                        </input>
                        <ErrorMessage
                          errors={errors}
                          name={'name'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.email}<span>*</span></label>
                        <input type="text" class="form-control"

                          {...register('email', {
                            required:  lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: lang.invalid_email_address,
                            },


                          })}
                        >
                        </input>
                        <ErrorMessage
                          errors={errors}
                          name={'email'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.contact_number}<span>*</span></label>
                        <div class="form-group d-flex flex-wrap">
                          <div class="phonecode form_phone_select_arrow position-relative">
                            <select class="form-control"
                              {...register('mobileCode', {
                                required: {
                                  value: true,
                                  message:
                                  lang.phone_code_is_required,
                                },
                                onChange: (e) => {
                                  setMobileCode(e.target.value)
                                }, value: mobileCode
                              })}
                            >
                              {phoneCode.map((item, key) => {
                                return <option selected={mobileCode == item ? 'selected' : ''} value={item}>{item}</option>
                              })}
                            </select>
                          </div>
                          <div class="phonenumber">
                            <input type="text" class="form-control" placeholder=" "

                              {...register('contact_number', {
                                required: {
                                  value: true,
                                  message:
                                    lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message:
                                    lang.please_enter_atleast_six_numbers,
                                },

                                onChange: e => {

                                },
                              })}
                            >
                            </input>
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={'contact_number'}
                            render={({ message }) => (
                              <span className='error-message'>{message}</span>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group form_select_arrow'>
                        <label>{lang.please_select_the_service}<span>*</span></label>
                        <select name="" id="" class="form-control"
                          {...register('select_service_type', {
                            required: lang.service_type_is_required,
                            onChange: (event) => setSelectedService(event.target.value)

                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="Passport Renewal">{lang.passport_renewal}</option>
                          <option value="Motor Vehicle Registration Papers">{lang.motor_vehichle_reg_papers}</option>
                          {/* <option value="Vehichle Technical Inspections">{lang.vehichle_technical_inspections}</option> */}
                          <option value="Driving Licence Renewal">{lang.driving_licence_renewal}</option>
                          <option value="Medical Check For Domestic Workers">{lang.medical_check_for_domestic_workers}</option>
                          <option value="Residency Renewal">{lang.Residency_renewal}</option>
                          <option value="Civil ID Renewal and Delivery">{lang.civilID_renewal}</option>
                          <option value="Document Attestation">{lang.document_attestation}</option>
                          <option value="Matching English/Arabic Name">{lang.matching_english_or_arabic_name}</option>
                          <option value="Transfer of Information from Old Passport">{lang.transfer_info_from_old_passport}</option>
                          <option value="Services for Kuwaiti Nationalities">{lang.services_for_kuwaiti}</option>
                          <option value="Traffic Fine Payment">{lang.traffic_fine_payment}</option>
                          <option value="Electricity Bills Payment">{lang.electricity_bills_payment}</option>
                          {/* <option value="Other">{lang.select_other}</option> */}
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={'select_service_type'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>
                    </div>
                     {/* {selectedService == "Other" &&
                      <div className='col-lg-12 col-md-12'>
                        <div className='form-group'>
                          <label>{lang.if_you_have_selected_other_please_specify}</label>
                          <textarea class="form-control" name="" rows="" cols=""
                            {...register('serviceDescription', {
                              required: lang.service_description_is_required,

                            })}
                          ></textarea>
                          <ErrorMessage
                            errors={errors}
                            name={'serviceDescription'}
                            render={({ message }) => (
                              <span className='error-message'>{message}</span>
                            )}
                          />
                        </div>

                      </div>
                        } */}
                    <div className='col-lg-12 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.additional_information}</label>
                        <textarea class="form-control" name="" rows="" cols=""
                          {...register('additional_information', {

                          })}
                        ></textarea>
                      </div>
                    </div>
                   {/*  <div className='col-lg-12 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.special_requests}</label>
                        <textarea class="form-control" name="" rows="" cols=""
                          {...register('special_requests', {

                          })}
                        ></textarea>
                      </div>
                    </div> */}
                    <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                      <button type="submit" class="form_submit_btn">{lang.submit}</button>
                    </div>
                    {
                      tokenData.data.isDefaultUser &&
                      <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                        <p className='form_note'>{lang.note_now_you_are_on_guest_user_please} <a href='#' onClick={() => setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}</p>
                      </div>
                    }
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>


    </React.Fragment>

  )
}
export default GovernmentalServices;