import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import ar from "date-fns/locale/ar"; // the locale you want
import {  Link} from 'react-router-dom';

import InnerBanner from "../../components/common/inner_banner";
import { getServiceDetailsById } from "../../scripts/common";
import ServiceBoxLeft from "./ServiceBoxLeft";
import { postServices } from "../../services/common";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import { setPhoneCodeList, getCountryOrCity, getAirline, getHotels } from "../../services/common";

import TourPackagesDetail from "../../assets/images/tour_packages_service.jpg";
import FulloverLoader from "../../components/common/FulloverLoader";
import UserList from '../../components/common/UserList';

function HolidayPackages({setShowLoginModal}) {

  const tokenData = useSelector((state) => state.tokenInfo);
  let lang = useSelector((state) => state.language.data);
  const current_culture = useSelector(state => state.culture.value);
  registerLocale("ar", ar);
  // let myProfile = JSON.parse(localStorage.getItem("myProfile"));
  



  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState('');
  const [departureDate, setDepartureDate] = useState(new Date());
  const [arrivalDate, setArrivalDate] = useState(new Date());
  const [boardType, setBoardType] = useState("room only");
  const [ticketClass, setTicketClass] = useState("First");
  const [serviceDetails, setServiceDetails] = useState();
  const [phoneCode, setPhoneCode] = useState([])
  const [mobCode, setMobCode] = useState(process.env.REACT_APP_MOBCODE)
  const [City, setCity] = useState("")
  const [cityList, setCityList] = useState([])
  const [cityLabel, setCityLabel] = useState("")
  const [prefferedAirline, setPrefferedAirline] = useState("")
  const [airlineLabel, setAirlineLabel] = useState("")
  const [airlineList, setAirlineList] = useState([])
  const [hotel, setHotel] = useState("")
  const [hotelList, setHotelList] = useState([])
  const [serviceId, setServiceId] = useState(19);
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0,0)
    const serviceDetailsData = getServiceDetailsById(serviceId);
    setServiceDetails(serviceDetailsData);

    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList()

      setPhoneCode(phoneCodeList)
    } 

    fetchPhoneCode()
  }, []);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm();

  const resetForm=()=>{
    reset()
    setMobCode(process.env.REACT_APP_MOBCODE)
    setCity("")
    setCityLabel("")
    setCityList([])
    setDepartureDate(new Date())
    setArrivalDate(new Date())
    setHotel("")
    setHotelList("")
    setBoardType("room only")
    setPrefferedAirline("")
    setAirlineLabel("")
    setAirlineList([])
    setTicketClass("First")
    setOnBehalfId("")
    setValue('OnBehalfId',"")
    setUserText('')
    
  }

  const cityRequestHandler = async (e) => {
    setCity(e.target.value);

    const cityResponse = await getCountryOrCity(e.target.value)

    if (cityResponse) {

    setCityList(cityResponse)
    }
  };

  const selectCityHandler = (value,item,onChange) => {
    setCity(value)
    setCityLabel(item._source.displayname)
    onChange(value)

  }

  const hotelRequestHandler = async (e) => {
    setHotel(e.target.value);

    const hotelResponse = await getHotels(e.target.value)

    if (hotelResponse) {

    setHotelList(hotelResponse)
    }
  };

  const selectHotelHandler = (value,onChange) => {
    setHotel(value)
    onChange(value)

  }

  const airlineRequestHandler = async (event) => {

    setPrefferedAirline(event.target.value)
    const airlineListResponse = await getAirline(event.target.value)

    if(airlineListResponse) {
      setAirlineList(airlineListResponse)
      console.log("prefAirline",airlineListResponse)
    }
 }

    const selectAirlineHandler = (value,item, onChange) => {

      setPrefferedAirline(value)
      setAirlineLabel( item._source.airline +' ('+item._source.code+')')
      onChange(value)
    }


  const onSubmit = async (data) => {

    
    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true)
    } else {
    setIsLoading(true)
    console.log(data);

    let tourPackageParams = {
      ServiceID: serviceId,
      /* OnBehalfId: onBehalfId, */
      Data: JSON.stringify({
        name: data.Name,
        email: data.email,
        mobile_code: mobCode,
        contact_number: data.mobileno,
        country_city: cityLabel,
        number_of_adults: data.adultCount,
        number_of_childs: data.childCount,
        name_of_hotel: hotel,
        air_ticket_class: ticketClass,
        board_type: boardType,
        select_tour_package_type: data.tourType,
        preferred_airline: airlineLabel,
        date_of_departure: Moment(departureDate).format("DD/MM/YYYY"),
        date_of_arrival: Moment(arrivalDate).format("DD/MM/YYYY"),
        additional_optional_services: data.optionalServices,
        special_requests: data.specialReq,
      }),
    };

    if(onBehalfId) {
      tourPackageParams["OnBehalfId"] = onBehalfId
    }
    console.log(tourPackageParams);
    const response = await postServices(tourPackageParams);
    
      if (response) {
        dispatch(alertActions.setPopUpType("success"));
        dispatch(
          alertActions.setRedirect({
            redirect: false,
            redirectUrl: process.env.REACT_APP_BASE_URL,
          })
        );
        dispatch(
          alertActions.setPopUpData({
            heading: lang.sucess,
            message: lang.form_submitted_sucessfully,
          })
        );

        resetForm()
        /* alert('form submited')
        navigate(process.env.REACT_APP_BASE_URL, { replace: true }); */
        setIsLoading(false)
      } else {
        dispatch(alertActions.setPopUpType("error"));
        dispatch(
          alertActions.setPopUpData({
            heading: lang.failed,
            message: lang.something_went_wrong_please_try_again,
          })
        );
        dispatch(
          alertActions.setRedirect({
            redirect: false,
            redirectUrl: process.env.REACT_APP_BASE_URL,
          })
        );

        setIsLoading(false)
        /* alert('something went wrong ,please try again') */
      }
     
  }
  };

  return (
    <React.Fragment>
      {isLoading && <FulloverLoader/>}

      <InnerBanner title={lang.holiday_packages}></InnerBanner>

      <section className="content_service_main form_bg">
        <div class="container">
          <div className="form_breadcrumbs">
            <ul>
              <li>
              <Link to={process.env.REACT_APP_BASE_URL+'services'}>{lang.services} </Link>
              </li>
              <li>
                <a className="active" href="">
                  {lang.holiday_packages}
                </a>
              </li>
            </ul>
          </div>
          <div className="service_box">
            {serviceDetails && (
              <ServiceBoxLeft serviceDetails={serviceDetails} />
            )}
            <div className="service_box_col_rht">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="service_detail_formbox formstyle">
                  <div className="service_detail_formhd">
                    <h2>{lang.feel_like_contacting_us}</h2>
                    <p>
                      {/* {lang.submit_the_form_below_we_will_get_back_to_you_asap} */}
                    </p>
                  </div>
                  <hr className="form_hd_hr"></hr>
                  <h3><span style={{lineHeight: 2}}>{lang.holiday_text1}{lang.holiday_text2}{lang.holiday_text3} </span><a target="_blank" href="https://www.qgoonline.com/" rel="noreferrer">www.qgoonline.com</a></h3>
                  
                  {/* {(myProfile && myProfile.isAgentLogin && ! tokenData.data.isDefaultUser) &&
                    <UserList
                      register={register}
                      onBehalfId={onBehalfId}
                      setOnBehalfId={setOnBehalfId}
                      errors={errors}
                      control={control}
                      userText={userText}
                      setUserText={setUserText}

                    />
                  }
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.name}<span>*</span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          {...register("Name", {
                            required: lang.name_is_required,
                            pattern: {
                              value: /[A-Za-z]/,
                              message: lang.please_enter_valid_name,
                            },
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,
                            },

                            value: "",
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"Name"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.email}<span>*</span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          {...register("email", {
                            required: lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: lang.invalid_email_address,
                            },

                            value: "",
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"email"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.contact_number}<span>*</span>
                        </label>
                        <div class="form-group d-flex flex-wrap">
                          <div class="phonecode form_phone_select_arrow position-relative">
                          <select
                              class="form-control"
                              value={mobCode}
                              {...register("phoneCode", {
                                required: {
                                  value: true,
                                  message: lang.phone_code_is_required,
                                },
                                onChange:(e)=>setMobCode(e.target.value),
                                value: mobCode
                              })}
                            >
                              {phoneCode.map((item)=> {
                                return <option value={item}>{item}</option>
                              })}
                              </select>
                              <ErrorMessage
                                errors={errors}
                                name={"phoneCode"}
                                render={({ message }) => (
                                  <span className="error-message">
                                    {message}
                                  </span>
                                )}
                              />
                          </div>
                          <div class="phonenumber">
                            <input
                              type="name"
                              class="form-control"
                              placeholder=" "
                              {...register("mobileno", {
                                required: {
                                  value: true,
                                  message: lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message: lang.please_enter_atleast_six_numbers,
                                },

                                value: "",
                              })}
                            ></input>
                            
                          </div>
						  <ErrorMessage
                              errors={errors}
                              name={"mobileno"}
                              render={({ message }) => (
                                <span className="error-message">{message}</span>
                              )}
                            />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group form_select_arrow DisplayinRmv autocomplete">
                        <label>
                          {lang.select_country_city}<span>*</span>
                        </label>
                        <Controller
                          name={"city"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                        <Autocomplete
                          getItemValue={(item) => current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                          items={cityList}
                          renderItem={(item, isHighlighted) => (
                            <div
                              className={
                                isHighlighted
                                  ? " react-autocomplete-inner active "
                                  : "react-autocomplete-inner"
                              }
                            >
                              {current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                            </div>
                          )}
                          {...register("city", {
                            required: {
                              value: true,
                              message: lang.please_select_a_country_city,
                            },
                          })}
                          onChange={(event) => cityRequestHandler(event)}
                          onSelect={(label,item)=>selectCityHandler(label,item,onChange)}
                          value={City}
                        />
                        )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"city"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.date_of_departure}<span>*</span>
                        </label>
                        <DatePicker
                          minDate={new Date()}
                          locale={current_culture}
                          dateFormat={"dd/MM/yyyy"}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown={true}
                          selected={departureDate}
                          onChange={(value) => {
                            setDepartureDate(value);
                            setArrivalDate(value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.date_of_arrival}<span>*</span>
                        </label>
                        <DatePicker
                          minDate={departureDate}
                          locale={current_culture}
                          dateFormat={"dd/MM/yyyy"}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown={true}
                          selected={arrivalDate}
                          onChange={(value) => {
                            setArrivalDate(value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group form_select_arrow">
                            <label>
                              {lang.adult}<span>*</span>
                            </label>
                            <select
                              name=""
                              id=""
                              class="form-control"
                              {...register("adultCount", {
                                required: {
                                  value: true,
                                  message: lang.please_select_adult_count,
                                },
                              })}
                            >
                              <option value="">{lang.select}</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                            </select>
                            <ErrorMessage
                              errors={errors}
                              name={"adultCount"}
                              render={({ message }) => (
                                <span className="error-message">{message}</span>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group form_select_arrow">
                            <label>
                              {lang.child}<span>*</span>
                            </label>
                            <select
                              name=""
                              id=""
                              class="form-control"
                              {...register("childCount", {
                                required: {
                                  value: true,
                                  message: lang.please_select_children_count,
                                },
                              })}
                            >
                              <option value="">{lang.select}</option>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                            </select>
                            <ErrorMessage
                              errors={errors}
                              name={"childCount"}
                              render={({ message }) => (
                                <span className="error-message">{message}</span>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group DisplayinRmv autocomplete">
                        <label>
                          {lang.name_of_hotel}<span>*</span>
                        </label>
                        <Controller
                          name={"hotel"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                        <Autocomplete
                          getItemValue={(item) => item._source.displayname}
                          items={hotelList}
                          renderItem={(item, isHighlighted) => (
                            <div
                              className={
                                isHighlighted
                                  ? " react-autocomplete-inner active "
                                  : "react-autocomplete-inner"
                              }
                            >
                              {item._source.displayname}
                            </div>
                          )}
                          {...register("hotel", {
                            required: {
                              value: true,
                              message: lang.please_select_a_hotel,
                            },
                          })}
                          onChange={(event) => hotelRequestHandler(event)}
                          onSelect={(label)=>selectHotelHandler(label,onChange)}
                          value={hotel}
                        />
                        )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"hotel"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <label>
                        {lang.board_type} <span>*</span>
                      </label>
                      <div className="form-group radio-outer">
                        <label className="tripradio">
                          {" "}
                          {lang.room_only}
                          <input
                            type="radio"
                            class="form-control"
                            name="board-type"
                            defaultChecked={true}
                            onChange={(e) => setBoardType("room only")}
                          ></input>
                          <span class="checkmark"></span>
                        </label>
                        <label className="tripradio">
                          {" "}
                          {lang.breakfast}
                          <input
                            type="radio"
                            class="form-control"
                            name="board-type"
                            onChange={(e) => setBoardType("breakfast")}
                          ></input>
                          <span class="checkmark"></span>
                        </label>
                        <label className="tripradio">
                          {" "}
                          {lang.half_board}
                          <input
                            type="radio"
                            class="form-control"
                            name="board-type"
                            onChange={(e) => setBoardType("half board")}
                          ></input>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group form_select_arrow">
                        <label>
                          {lang.select_tour_package_type}<span>*</span>
                        </label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register("tourType", {
                            required: {
                              value: true,
                              message: "Please select a Package Type",
                            },
                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="Leisure">{lang.leisure}</option>
                          <option value="Business">{lang.business}</option>
                          <option value="Sports">{lang.sports}</option>
                          <option value="Adventure">{lang.adventure}</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={"tourType"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group DisplayinRmv autocomplete">
                        <label>
                          {lang.preferred_airline}<span>*</span>
                        </label>
                        <Controller
                          name={"airline"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                        <Autocomplete
                          getItemValue={(item) => {
                            const value = (current_culture == "ar" ? item._source.airlinearabic : item._source.airline) +' ('+item._source.code+')'
                            return value
                          }}
                          items={airlineList}
                          renderItem={(item, isHighlighted) => (
                            <div
                              className={
                                isHighlighted
                                  ? " react-autocomplete-inner active "
                                  : "react-autocomplete-inner"
                              }
                            >
                              {(current_culture == "ar" ? item._source.airlinearabic : item._source.airline) +' ('+item._source.code+')'}
                            </div>
                          )}
                          {...register("airline", {
                            required: {
                              value: true,
                              message: lang.please_select_preferred_airline,
                            },
                          })}
                          onChange={(event) => airlineRequestHandler(event)}
                          onSelect={(label,item)=> selectAirlineHandler(label,item,onChange)}
                          value={prefferedAirline}
                        />
                        )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"airline"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <label>
                       {lang.ticket_class} <span>*</span>
                      </label>
                      <div className="form-group radio-outer">
                        <label className="tripradio">
                          {" "}
                          {lang.first}
                          <input
                            type="radio"
                            class="form-control"
                            name="ticket-class"
                            defaultChecked={true}
                            onChange={(e) => setTicketClass("First")}
                          ></input>
                          <span class="checkmark"></span>
                        </label>
                        <label className="tripradio">
                          {" "}
                          {lang.business_class}
                          <input
                            type="radio"
                            class="form-control"
                            name="ticket-class"
                            onChange={(e) => setTicketClass("Business")}
                          ></input>
                          <span class="checkmark"></span>
                        </label>
                        <label className="tripradio">
                          {" "}
                          {lang.economy_class}
                          <input
                            type="radio"
                            class="form-control"
                            name="ticket-class"
                            onChange={(e) => setTicketClass("Ecocnomy")}
                          ></input>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>{lang.special_requests}</label>
                        <textarea
                          class="form-control"
                          name=""
                          rows=""
                          cols=""
                          {...register("specialReq")}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group form_select_arrow">
                        <label>
                          {lang.additional_optional_services}<span>*</span>
                        </label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register("optionalServices")}
                        >
                          <option value="">{lang.select}</option>
                          <option value="Car Rental">{lang.car_rental}</option>
                          <option value="Airport Transfers">{lang.airport_transfers}</option>
                          <option value="Sightseeing & activities">{lang.sightseeing_activities}</option>
                          <option value="Theme Park Tickets">{lang.theme_park_tickets}</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <button type="submit" class="form_submit_btn">
                        {lang.submit}
                      </button>
                    </div>
                    {tokenData.data.isDefaultUser && <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <p className="form_note">
                       {lang.note_now_you_are_on_guest_user_please}{" "}
                        <a href="#" onClick={()=>setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}
                      </p>
                    </div>}
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default HolidayPackages;
