import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import noImage from "../assets/images/no_image.jpg"
import InnerBanner from "../components/common/inner_banner";
import { Link } from "react-router-dom";
import { serviceUrlList } from "../scripts/common";
import { useDispatch, useSelector } from "react-redux";

function ServiceList() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const services = useSelector((state) => state.serviceInfo);
  const current_culture = useSelector(state => state.culture.value);
  let lang = useSelector((state) => state.language.data);
  return (
    <React.Fragment>
      <InnerBanner
      title={lang.services}
      ></InnerBanner>

      <div class="container">
        <OwlCarousel
          items={1}
          className="owl-theme service_list"
          loop
          nav
          margin={0}
        >
          <div>
            <ul>

              {services.data.slice(0,6).map((service, key) => {
                ;
                const sectionStyle = {

                  background: `url(${service.thumbnail})`
                };
                return (<li>
                  <div className="service_pic01" style={{
                    'background': "url('" + service.thumbnail + "'),url('" + noImage + "')",
                    'background-size': 'cover',
					'background-position': 'center'
                  }} >
                 
                    <div className="service_ftr_hd">
                    {
                        current_culture=="en" &&
                      <h3>
                        {service.title_En}
                      </h3>
                      ||
                      <h3>
                        {service.title_Ar}
                      </h3>
                      }
                    </div>
                    <div class="service_overlay"></div>
                    <div class="service_view_button">
                      <Link
                        to={
                          process.env.REACT_APP_BASE_URL +
                          serviceUrlList[service.serviceID]
                        }
                      >
                        {lang.more_details}
                      </Link>
                    </div>
                  </div>
                </li>)
              })}

            </ul>
          </div>

          <div>
            <ul>
              {services.data.slice(6, 12).map((service, key) => {
                ;
                const sectionStyle = {

                  background: `url(${service.thumbnail})`
                };
                return (<li>
                  <div className="service_pic01"style={{
                    'background': "url('" + service.thumbnail + "'),url('" + noImage + "')",
                    'background-size': 'cover',
					'background-position': 'center'
                  }}  >
                    <div className="service_ftr_hd">
                      {
                        current_culture=="en" &&
                      <h3>
                        {service.title_En}
                      </h3>
                      ||
                      <h3>
                        {service.title_Ar}
                      </h3>
                      }
                    </div>
                    <div class="service_overlay"></div>
                    <div class="service_view_button">
                      <Link
                        to={
                          process.env.REACT_APP_BASE_URL +
                          serviceUrlList[service.serviceID]
                        }
                      >
                       {lang.more_details}
                      </Link>
                    </div>
                  </div>
                </li>)
              })}
            </ul>
          </div>
          {/* <div>
            <ul className="service_list_last">
            {services.data.slice(12).map((service, key) => {
                ;
                const sectionStyle = {

                  background: `url(${service.thumbnail})`
                };
                return (<li>
                  <div className="service_pic01" style={{
                    'background': "url('" + service.thumbnail + "'),url('" + noImage + "')",
                    'background-size': 'cover',
					'background-position': 'center'
                  }}  >
                    <div className="service_ftr_hd">
                    {
                        current_culture=="en" &&
                      <h3>
                        {service.title_En}
                      </h3>
                      ||
                      <h3>
                        {service.title_Ar}
                      </h3>
                      }
                    </div>
                    <div class="service_overlay"></div>
                    <div class="service_view_button">
                      <Link
                        to={
                          process.env.REACT_APP_BASE_URL +
                          serviceUrlList[service.serviceID]
                        }
                      >
                      {lang.more_details}
                      </Link>
                    </div>
                  </div>
                </li>)
              })}
            </ul>
          </div> */}
        </OwlCarousel>
      </div >
    </React.Fragment >
  );
}
export default ServiceList;
