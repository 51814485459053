import React, { useEffect, useState } from 'react';


import InnerBanner from '../../components/common/inner_banner';

import MessengerServicesDetail from "../../assets/images/messenger_services.jpg"

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ar from "date-fns/locale/ar"; // the locale you want

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { getAirportList, getTimeData, postServices, setPhoneCodeList } from '../../services/common'
import { getServiceDetailsById } from '../../scripts/common';
import ServiceBoxLeft from './ServiceBoxLeft';
import { useNavigate } from 'react-router-dom';
import {  Link} from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import FulloverLoader from '../../components/common/FulloverLoader';
import UserList from '../../components/common/UserList';
const serviceId = 12;

function MessengerServices({ setShowLoginModal }) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm();
  let myProfile = JSON.parse(localStorage.getItem("myProfile"));

  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState('');
  const [walletData, setWalletData] = useState(null)
  const [phoneCode, setPhoneCode] = useState([])
  const [mobileCode, setMobileCode] = useState(process.env.REACT_APP_MOBCODE);
  let lang = useSelector((state) => state.language.data);
  const current_culture = useSelector(state => state.culture.value);
  registerLocale("ar", ar);
  const dispatch = useDispatch()
  const tokenData = useSelector((state) => state.tokenInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState();
  const [serviceType, setServiceType] = useState("");
  let navigate = useNavigate();
  const [serviceDetails, setserviceDetails] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);

    const serviceDetailsData = getServiceDetailsById(serviceId);
    setserviceDetails(serviceDetailsData)
    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList()

      setPhoneCode(phoneCodeList)
    }
    fetchPhoneCode();
  }, []);
  const onSubmit = async (data) => {
    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true)
    } else {

      setIsLoading(true)

      let messengerServicesParams = {
        ServiceID: serviceId,
        /* OnBehalfId: onBehalfId, */
        Data: JSON.stringify({
          name: data.name,
          email: data.email,
          mobile_code: data.mobileCode,
          contact_number: data.contact_number,
          select_required_messenger_service: data.select_required_messenger_service,
          selected_other_specifically: data.selected_other_specifically,
          address: data.address,
          date_of_service: moment(data.date_of_service).format(
            "DD/MM/YYYY"
          ),
          time_of_service: data.time_of_service,
          // fieldOrder:'name,email,mobile_code,contact_number,select_required_messenger_service,selected_other_specifically,address,time_of_service'
        })
      }
      if(onBehalfId) {
        messengerServicesParams["OnBehalfId"] = onBehalfId
      }
      const response = await postServices(messengerServicesParams);
      if (response) {
        dispatch(alertActions.setPopUpType('success'))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: "" }))
        dispatch(alertActions.setPopUpData({ heading: lang.sucess, message: lang.form_submitted_sucessfully }))
        resetForm()
        setIsLoading(false)

      } else {
        dispatch(alertActions.setPopUpType('error'))
        dispatch(alertActions.setPopUpData({ heading: lang.failed, message: lang.something_went_wrong_please_try_again }))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))

        setIsLoading(false)
      }

    }
  }
  const resetForm = () => {
    reset()
    setMobileCode(process.env.REACT_APP_MOBCODE)
    setServiceType("")
    setOnBehalfId("")
    setValue('OnBehalfId',"")
    setUserText('')
    setWalletData(null)

  }
  return (

    <React.Fragment>
      {
        isLoading &&
        <FulloverLoader />
      }
      <InnerBanner  title={lang.messenger_service}></InnerBanner>

      <section className='content_service_main form_bg'>
        <div class='container'>
          <div className='form_breadcrumbs'>
            <ul>
               <li><Link to={process.env.REACT_APP_BASE_URL+'services'}>{lang.services} </Link></li>
              <li><a className='active' href=''>{lang.messenger_service}</a></li>
            </ul>
          </div>
          <div className='service_box'>
            {
              serviceDetails &&
              <ServiceBoxLeft
                serviceDetails={serviceDetails}
              />
            }
            <div className='service_box_col_rht'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='service_detail_formbox formstyle'>
                  <div className='service_detail_formhd'>
                    <h2>{lang.feel_like_contacting_us}</h2>
                    <p>{lang.submit_the_form_below_we_will_get_back_to_you_asap}</p>
                  </div>
                  <hr className='form_hd_hr'>
                  </hr>
                  {(myProfile && myProfile.isAgentLogin && ! tokenData.data.isDefaultUser) &&
                    <UserList
                      register={register}
                      onBehalfId={onBehalfId}
                      setOnBehalfId={setOnBehalfId}
                      errors={errors}
                      control={control}
                      userText={userText}
                      setUserText={setUserText}
                      walletData={walletData}
                      setWalletData={setWalletData}

                    />
                  }
                  <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.name}<span>*</span></label>
                        <input type="text" class="form-control"

                          {...register('name', {
                            required: lang.name_is_required,
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,

                            },
                            pattern: {
                              value: /[A-Za-z]/,
                              message: lang.please_enter_valid_name,
                            },
                          })}
                        >
                        </input>
                        <ErrorMessage
                          errors={errors}
                          name={'name'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.email}<span>*</span></label>
                        <input type="text" class="form-control"

                          {...register('email', {
                            required: lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: lang.invalid_email_address,
                            },


                          })}
                        >
                        </input>
                        <ErrorMessage
                          errors={errors}
                          name={'email'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.contact_number}<span>*</span></label>
                        <div class="form-group d-flex flex-wrap">
                          <div class="phonecode form_phone_select_arrow position-relative">
                            <select class="form-control"
                             {...register('mobileCode', {
                              required: {
                                value: true,
                                message:
                                  lang.phone_code_is_required,
                              },
                              onChange: (e) => {
                                setMobileCode(e.target.value)
                              }, value: mobileCode
                            })}
                            >
                             {phoneCode.map((item, key) => {
                                return <option selected={mobileCode == item ? 'selected' : ''} value={item}>{item}</option>
                              })}
                            </select>
                          </div>
                          <div class="phonenumber">
                            <input type="text" class="form-control" placeholder=" "

                              {...register('contact_number', {
                                required: {
                                  value: true,
                                  message:
                                    lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                  lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message:
                                    lang.please_enter_atleast_six_numbers,
                                },

                                onChange: e => {

                                },
                              })}
                            >
                            </input>
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={'contact_number'}
                            render={({ message }) => (
                              <span className='error-message'>{message}</span>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group form_select_arrow'>
                        <label>{lang.please_select_the_messenger_service}<span>*</span></label>
                        <select name="" id="" class="form-control"

                          {...register('select_required_messenger_service', {
                            required: lang.service_type_is_required,
                            onChange: (e) => {
                              setServiceType(e.target.value)
                            }
                          })}
                        >
                          
                          {/* <option value="Governmental Services ">{lang.governmental_services} </option>
                          <option value="Cash & Bank Deposits">{lang.cash_and_bank_deposits}</option>
                          <option value="Car Servicing & Delivery">{lang.car_servicing_and_delivery}</option>
                          <option value="Installments Payment ">{lang.installments_payment}</option>
                          <option value="Medical, Travel & Car Insurance">{lang.medical_travel_and_car_insurance}</option>
                          <option value="Grocery & Pharmacy">{lang.grocery_and_pharmacy}</option>
                          <option value="Travel Messenger (Visa Assistance) ">{lang.travel_messenger} </option>
                          <option value="Traffic Fine Payment-Messenger">{lang.traffic_fine_payment_messenger}</option> */}
                          <option value="">{lang.select}</option>
                          <option value="Documents Delivery">{lang.documents_delivery}</option>
                          <option value="Cash Delivery">{lang.cash_delivery}</option>
                          {/* <option value="Other">{lang.select_other}</option> */}

                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={'select_required_messenger_service'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    {/* {serviceType == "Other" &&

                      <div className='col-lg-12 col-md-12'>
                        <div className='form-group'>
                          <label>{lang.if_you_have_selected_other_services_please_specify}:<span>*</span></label>
                          <textarea class="form-control" name="" rows="" cols=""
                            {...register('selected_other_specifically', {
                              required: lang.service_description_is_required,
                            })}
                          ></textarea>
                          <ErrorMessage
                            errors={errors}
                            name={'selected_other_specifically'}
                            render={({ message }) => (
                              <span className='error-message'>{message}</span>
                            )}
                          />
                        </div>
                      </div>
                    } */}
                    <div className='col-lg-12 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.date_and_time_of_the_service}<span>*</span></label>
                        <div className='row'>
                          <div className='col-lg-6 col-md-6'>
                            <Controller
                              name={'date_of_service'}
                              control={control}
                              render={({
                                field: { onChange, value },
                              }) => (
                                <DatePicker
                                  minDate={new Date()}
                                  locale={current_culture}
                                  dateFormat={'dd/MM/yyyy'}
                                  className="form-control"
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown={true}
                                  showYearDropdown={true}
                                  selected={value}
                                  onChange={date => {
                                    
                                    onChange(date)
                                    setDate(new Date(date))
                                  }}
                                  value={date}
                                />
                              )}
                              {...register('date_of_service', {
                                required: lang.date_is_required,
                                value: date,
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={'date_of_service'}
                              render={({ message }) => (
                                <span className='error-message'>{message}</span>

                              )}
                            />
                          </div>
                          <div className='col-lg-6 col-md-6'>
                            <select name="" id="" class="form-control"
                              {...register('time_of_service')}
                            >
                              {getTimeData.map((item) => {
                                return (
                                  <option value={item}>{item}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.address}<span>*</span></label>
                        <textarea class="form-control" name="" rows="" cols=""
                          {...register('address', {
                            required: lang.full_address_is_required,
                          })}
                        ></textarea>
                        <ErrorMessage
                          errors={errors}
                          name={'address'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>

                          )}
                        />
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                      <button type="submit" class="form_submit_btn">{lang.submit}</button>
                    </div>
                    {
                      tokenData.data.isDefaultUser &&
                      <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                        <p className='form_note'>{lang.note_now_you_are_on_guest_user_please} <a href='#' onClick={() => setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}</p>
                      </div>
                    }
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>


    </React.Fragment>

  )
}
export default MessengerServices;