import React, { useState, useEffect, Fragment } from "react";

import InnerBanner from "../../components/common/inner_banner";

import RestaurantReservationServiceDetails from "../../assets/images/restaurant_reservation_servicedetail.jpg";
import Autocomplete from "react-autocomplete";

import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Link } from "react-router-dom";
import ar from "date-fns/locale/ar"; // the locale you want

import { getServiceDetailsById } from "../../scripts/common";

import ServiceBoxLeft from "./ServiceBoxLeft";
import { useNavigate } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  getCountryOrCity,
  getHotels,
  getTimeData,
  postServices,
  setPhoneCodeList,
} from "../../services/common";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import FulloverLoader from "../../components/common/FulloverLoader";
import UserList from "../../components/common/UserList";
import {
  getSports,
  getSportStadiums,
  getHotelList,
} from "../../services/common";

const serviceId = 24;

function LifeStyleServices({ setShowLoginModal }) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const current_culture = useSelector((state) => state.culture.value);
  registerLocale("ar", ar);

  const [
    BusinessBankingServiceDetailsParams,
    setBusinessBankingServiceDetailsParams,
  ] = useState({
    name: "",
    email: "",
    phoneCode: "",
    contact_number: "",
    service: "",
    additionalInformation: "",
  });
  let lang = useSelector((state) => state.language.data);

  let myProfile = JSON.parse(localStorage.getItem("myProfile"));

  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState("");
  const [walletData, setWalletData] = useState(null)
  const [date, setDate] = useState();
  const [phoneCode, setPhoneCode] = useState([]);
  const [mobileCode, setMobileCode] = useState(process.env.REACT_APP_MOBCODE);

  const [hotel, setHotel] = useState("");
  const [restaurant, setRestaurant] = useState("")
  const [CityRestaurant, setCityRestaurant] = useState("");
  const [cityListRestaurant, setCityListRestaurant] = useState([]);
  const [CityFestival, setCityFestival] = useState("");
  const [cityListFestival, setCityListFestival] = useState([]);
  const [CitySports, setCitySports] = useState("");
  const [cityListSports, setCityListSports] = useState([]);
  let navigate = useNavigate();
  const tokenData = useSelector((state) => state.tokenInfo);
  const [hotelList, setHotelList] = useState([]);
 
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [serviceDetails, setserviceDetails] = useState();
  const [selectedService, setSelectedService] = useState("restaurant")
  const [showRestaurant, setShowRestaurant] = useState(true);
  const [showSportsCulture, setShowSportsCulture] = useState(false);
  const [showFestivals, setShowFestivals] = useState(false);
  const [courseType, setCourseType] = useState("Breakfast");
  const [venue, setVenue] = useState("");
  const [venueList, setVenueList] = useState([]);
  const [fromDateSports, setFromDateSports] = useState(new Date());
  const [toDateSports, setToDateSports] = useState(new Date());
  const [fromDateFestival, setFromDateFestival] = useState(new Date());
  const [toDateFestival, setToDateFestival] = useState(new Date());
  const [restaurantDisName, setRestaurantDisName] = useState("")
  const [cityRestaurantDis, setCityRestaurantDis] = useState("")
  const [citySportsDis, setCitySportsDis] = useState("")
  const [cityFestivalDis, setCityFestivalDis] = useState("")
  useEffect(() => {
    window.scrollTo(0, 0);

    const serviceDetailsData = getServiceDetailsById(serviceId);
    setserviceDetails(serviceDetailsData);
    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList();

      setPhoneCode(phoneCodeList);
      let restaurantList = getHotelList(lang)
      setHotelList(restaurantList)
    };

    fetchPhoneCode();
  }, []);
  useEffect(() => {
    //  alert('in')
  }, [onBehalfId]);
  const cityRequestHandlerRestaurant = async (e) => {
    setCityRestaurant(e.target.value);
    /* setCityRestaurantDis(e.target.value) */

    const cityResponse = await getCountryOrCity(e.target.value);

    if (cityResponse) {
      setCityListRestaurant(cityResponse);
    }
  };

  const renderHotelList = (state, val) =>  {
   
    return (
        state.label.toLowerCase().indexOf(val.toLowerCase()) !== -1
    );
}

  const selectCityHandlerRestaurant = (value, onChange, item) => {
    
    setCityRestaurant(value);
    setCityRestaurantDis(item._source.displayname)
    onChange(value);
  };
  const cityRequestHandlerSports = async (e) => {
    setCitySports(e.target.value);

    const cityResponse = await getCountryOrCity(e.target.value);

    if (cityResponse) {
      setCityListSports(cityResponse);
    }
  };

  const selectCityHandlerSports = (value, onChange, item) => {
   
    setCitySports(value);
    setCitySportsDis(item._source.displayname)

    onChange(value);
  };
  const cityRequestHandlerFestival = async (e) => {
    setCityFestival(e.target.value);

    const cityResponse = await getCountryOrCity(e.target.value);

    if (cityResponse) {
      setCityListFestival(cityResponse);
    }
  };

  const selectCityHandlerFestival = (value, onChange,item) => {
    setCityFestival(value);
    setCityFestivalDis(item._source.displayname)
    onChange(value);
  };
  

  const venueRequestHandler = async (e) => {
    setVenue(e.target.value);

    const venueResponse = await getSportStadiums(e.target.value);

   

    if (venueResponse) {
      setVenueList(venueResponse);
    }
  };

  const selectVenueHandler = (value, onChange) => {
    setVenue(value);
    onChange(value);
  };
  const onSubmit = async (data) => {

    let restaurantData = {
      name: data.name,
      email: data.email,
      contact_number: data.contact_number,
      mobile_code: data.mobileCode,
      date_of_reservation: moment(data.date_of_reservation).format(
        "DD/MM/YYYY"
      ),
      time_of_reservation: data.time_of_reservation,
      name_of_restaurant: restaurant,
      restaurant_location: cityRestaurantDis,
      no_of_guests_restaurant: data.no_of_guests_restaurant,
      select_course: data.select_course,
      additional_remarks: data.additional_remarks,
      special_requests: data.special_requests,
    };

    let SportsData = {
      name: data.name,
      email: data.email,
      contact_number: data.contact_number,
      mobile_code: data.mobileCode,
      sports_venue: venue,
      sports_location: citySportsDis,
      no_of_guests_sports: data.no_of_guests_sports,
      from_date_sports: moment(fromDateSports).format("DD/MM/YYYY"),
      to_date_sports: moment(toDateSports).format("DD/MM/YYYY"),
      additional_remarks: data.additional_remarks,
      special_requests: data.special_requests,
    };

    let festivalData = {
      name: data.name,
      email: data.email,
      contact_number: data.contact_number,
      mobile_code: data.mobileCode,
      festival_location: cityFestivalDis,
      name_of_festival: data.festival_name,
      no_of_guests_festival: data.no_of_guests_festival,
      from_date_festival: moment(fromDateFestival).format("DD/MM/YYYY"),
      to_date_festival: moment(toDateFestival).format("DD/MM/YYYY"),
      additional_remarks: data.additional_remarks,
      special_requests: data.special_requests,
    };
    let lifeStyleParams = {};
    if (showRestaurant) {
      lifeStyleParams = { ...lifeStyleParams, ...restaurantData };
    }
    if (showSportsCulture) {
      lifeStyleParams = { ...lifeStyleParams, ...SportsData };
    }
    if (showFestivals) {
      lifeStyleParams = { ...lifeStyleParams, ...festivalData };
    }
   

    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true);
    } else {
      setIsLoading(true);

      let LifeStyleServicesParams = {
        ServiceID: serviceId,
        /* OnBehalfId: onBehalfId, */
        Data: JSON.stringify({
          ...lifeStyleParams,
          // fieldOrder:'name,email,mobile_code,contact_number,date_of_reservation,time_of_reservation,name_of_restaurant,restaurant_location,no_of_adults,no_of_children,select_course,additional_remarks,special_requests'
        }),
      };
      if (onBehalfId) {
        LifeStyleServicesParams["OnBehalfId"] = onBehalfId;
      }

      
      const response = await postServices(LifeStyleServicesParams);
      if (response) {
        dispatch(alertActions.setPopUpType("success"));
        dispatch(
          alertActions.setRedirect({ redirect: false, redirectUrl: "" })
        );
        dispatch(
          alertActions.setPopUpData({
            heading: lang.sucess,
            message: lang.form_submitted_sucessfully,
          })
        );
        resetForm();
        setIsLoading(false);
      } else {
        dispatch(alertActions.setPopUpType("error"));
        dispatch(
          alertActions.setPopUpData({
            heading: lang.failed,
            message: lang.something_went_wrong_please_try_again,
          })
        );
        dispatch(
          alertActions.setRedirect({
            redirect: false,
            redirectUrl: process.env.REACT_APP_BASE_URL,
          })
        );

        setIsLoading(false);
      }
    }
  };
  const resetForm = () => {
    reset();
    setMobileCode(process.env.REACT_APP_MOBCODE);
    setCityRestaurant("");
    setCitySports("");
    setCityFestival("");
    setHotel("");
    setOnBehalfId("");
    setValue("OnBehalfId", "");
    setUserText("");
    setRestaurant("")
    setShowRestaurant(true)
    setShowSportsCulture(false)
    setShowFestivals(false)
    setRestaurantDisName("")
    setCityRestaurantDis("")
    setWalletData(null)
    
  };


  return (
    <React.Fragment>
      {" "}
      {isLoading && <FulloverLoader />}
      <InnerBanner title={lang.life_style_services}></InnerBanner>
      <section className="content_service_main form_bg">
        <div class="container">
          <div className="form_breadcrumbs">
            <ul>
              <li>
                <Link to={process.env.REACT_APP_BASE_URL + "services"}>
                  {lang.services}
                </Link>
              </li>
              <li>
                <a className="active" href="">
                  {lang.life_style_services}
                </a>
              </li>
            </ul>
          </div>
          <div className="service_box">
            {" "}
            {serviceDetails && (
              <ServiceBoxLeft serviceDetails={serviceDetails} />
            )}
            <div className="service_box_col_rht">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="service_detail_formbox formstyle">
                  <div className="service_detail_formhd">
                    <h2>{lang.feel_like_contacting_us}</h2>
                    <p>
                      {lang.submit_the_form_below_we_will_get_back_to_you_asap}
                    </p>
                  </div>
                  <hr className="form_hd_hr"></hr>
                  {myProfile &&
                    myProfile.isAgentLogin &&
                    !tokenData.data.isDefaultUser && (
                      <UserList
                        register={register}
                        onBehalfId={onBehalfId}
                        setOnBehalfId={setOnBehalfId}
                        errors={errors}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        control={control}
                        userText={userText}
                        setUserText={setUserText}
                        walletData={walletData}
                      setWalletData={setWalletData}
                      />
                    )}
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <label>
                        {lang.please_tick_required_service}-<span></span>
                      </label>
                    </div>
                    <div className="col-lg-12 service_check mt-2 mb-3">
                      <div class="form-group">
                        <input
                          checked={selectedService == "restaurant"}
                          onClick={() => /* setShowRestaurant(!showRestaurant) */ setSelectedService("restaurant")}
                          type="checkbox"
                          id="html"
                        ></input>
                        <label for="html">{lang.restaurant_reservation}</label>
                      </div>
                      <div class="form-group">
                        <input
                        checked={selectedService == "sports"}
                          onClick={() =>
                            /* setShowSportsCulture(!showSportsCulture) */
                            setSelectedService("sports")
                          }
                          type="checkbox"
                          id="css"
                        ></input>
                        <label for="css">
                          {lang.sports_and_cultural_events}
                        </label>
                      </div>
                      {/* <div class="form-group">
                        <input
                          onClick={() => setShowFestivals(!showFestivals)}
                          type="checkbox"
                          id="javascript"
                        ></input>
                        <label for="javascript">{lang.festivals}</label>
                      </div> */}
                    </div>
                    {/*-------------- Restaurant form start-------------- */}
                    {selectedService == "restaurant" && (
                      <Fragment>
                        <div className="col-lg-12 col-md-12">
                          <label className="servicesubHd">
                            {lang.restaurant_reservation}
                            <span></span>
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="form-group form_select_arrow DisplayinRmv autocomplete">
                            <label>
                              {lang.country}
                              <span>*</span>
                            </label>

                            <Controller
                              name={"restaurant_location"}
                              control={control}
                              render={({
                                field: { onChange, value, name },
                              }) => (
                                <Autocomplete
                                  getItemValue={(item) =>
                                    current_culture === "ar"
                                      ? item._source.displaynamear
                                      : item._source.displayname
                                  }
                                  items={cityListRestaurant}
                                  renderItem={(item, isHighlighted) => (
                                    <div
                                      className={
                                        isHighlighted
                                          ? " react-autocomplete-inner active "
                                          : "react-autocomplete-inner"
                                      }
                                    >
                                      {current_culture === "ar"
                                        ? item._source.displaynamear
                                        : item._source.displayname}
                                    </div>
                                  )}
                                  {...register("restaurant_location", {
                                    required: {
                                      value: true,
                                      message:
                                        lang.please_select_restaurant_location,
                                    },
                                  })}
                                  onChange={(event) =>
                                    {cityRequestHandlerRestaurant(event)
                                      event.target.value == "" && onChange(event.target.value)}
                                  }
                                  onSelect={(label,item) =>
                                    selectCityHandlerRestaurant(label, onChange,item)
                                  }
                                  value={CityRestaurant}
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={"restaurant_location"}
                              render={({ message }) => (
                                <span className="error-message">{message}</span>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="form-group DisplayinRmv autocomplete">
                            <label>
                              {lang.name_of_restaurant}
                              <span>*</span>
                            </label>

                           {/*  <Controller
                              name={"name_of_restaurant"}
                              control={control}
                              render={({
                                field: { onChange, value, name },
                              }) => (
                                <Autocomplete
                                  getItemValue={(item) =>
                                    item._source.displayname
                                  }
                                  items={getHotelList}
                                  renderItem={(item, isHighlighted) => (
                                    <div
                                      className={
                                        isHighlighted
                                          ? " react-autocomplete-inner active "
                                          : "react-autocomplete-inner"
                                      }
                                    >
                                      {item._source.displayname}
                                    </div>
                                  )}
                                  {...register("name_of_restaurant", {
                                    required: {
                                      value: true,
                                      message: lang.please_select_a_restaurant,
                                    },
                                  })}
                                  onChange={(event) =>
                                    hotelRequestHandler(event)
                                  }
                                  onSelect={(label) =>
                                    selectHotelHandler(label, onChange)
                                  }
                                  value={hotel}
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={"name_of_restaurant"}
                              render={({ message }) => (
                                <span className="error-message">{message}</span>
                              )}
                            /> */}

                            <Controller
                              name={"name_of_restaurant"}
                              control={control}
                              render={({
                                field: { onChange, value, name },
                              }) => (
                                <Autocomplete
                                  getItemValue={(item) =>
                                    item.value
                                  }
                                  items={hotelList}
                                  shouldItemRender={renderHotelList}
                                  renderItem={(item, isHighlighted) => (
                                    <div
                                      className={
                                        isHighlighted
                                          ? " react-autocomplete-inner active "
                                          : "react-autocomplete-inner"
                                      }
                                    >
                                      {item.value}
                                    </div>
                                  )}
                                  {...register("name_of_restaurant", {
                                    required: {
                                      value: true,
                                      message: lang.please_select_a_restaurant,
                                    },
                                    value:value
                                  })}
                                  onChange={(event) =>
                                   {setRestaurantDisName(event.target.value)
                                   /*  setRestaurant(event.target.value) */
                                    event.target.value == "" && onChange(event.target.value)
                                  }

                                  }

                                  onSelect={(value,item) =>
                                    {
                                     /*  selectRestaurantHandler(label) */
                                      setRestaurant(item.label);
                                      setRestaurantDisName(value)
                                      onChange(item.label);}
                                   
                                  }
                                  value={restaurantDisName}
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={"name_of_restaurant"}
                              render={({ message }) => (
                                <span className="error-message">{message}</span>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <label>
                                {lang.date_time_of_reservation}
                                <span>*</span>
                              </label>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <Controller
                                  name={"date_of_reservation"}
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <DatePicker
                                      minDate={new Date()}
                                      locale={current_culture}
                                      dateFormat={"dd/MM/yyyy"}
                                      className="form-control"
                                      yearDropdownItemNumber={100}
                                      scrollableYearDropdown={true}
                                      showYearDropdown={true}
                                      selected={value}
                                      onChange={(date) => {
                                        onChange(date);
                                        setDate(new Date(date));
                                      }}
                                      value={date}
                                    />
                                  )}
                                  {...register("date_of_reservation", {
                                    required: lang.date_is_required,
                                    value: date,
                                  })}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name={"date_of_reservation"}
                                  render={({ message }) => (
                                    <span className="error-message">
                                      {message}
                                    </span>
                                  )}
                                />{" "}
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <select
                                  name=""
                                  id=""
                                  class="form-control"
                                  {...register("time_of_reservation")}
                                >
                                  {getTimeData.map((item) => {
                                    return <option value={item}>{item}</option>;
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div class="form-group form_select_arrow">
                            <label>
                              {lang.no_of_guests}
                              <span>*</span>
                            </label>
                            <select
                              {...register("no_of_guests_restaurant")}
                              name="no_of_guests_restaurant"
                              id=""
                              class="form-control"
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="3">4</option>
                              <option value="3">5</option>
                              <option value="3">6</option>
                              <option value="3">7</option>
                              <option value="3">8</option>
                              <option value="3">9</option>
                            </select>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                  <div className="row mt-2">
                    {selectedService == "restaurant" && (
                      <Fragment>
                        {" "}
                        <div className="col-lg-12 col-md-12">
                          <label>
                            {lang.select_course}
                            <span>*</span>
                          </label>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div class="form-group radio-outer">
                            <label class="tripradio">
                              {lang.breakfast}
                              <input
                                onClick={() => setCourseType("Breakfast")}
                                checked={
                                  courseType == "Breakfast" ? true : false
                                }
                                type="radio"
                                class="form-control"
                                name="select_course"
                                value="Breakfast"
                                defaultChecked
                                {...register("select_course")}
                              ></input>
                              <span class="checkmark"></span>
                            </label>
                            <label class="tripradio">
                              {lang.lunch}
                              <input
                                onClick={() => setCourseType("Lunch")}
                                checked={courseType == "Lunch" ? true : false}
                                type="radio"
                                class="form-control"
                                name="select_course"
                                value="Lunch"
                                {...register("select_course")}
                              ></input>
                              <span class="checkmark"></span>
                            </label>
                            <label class="tripradio">
                              {lang.dinner}
                              <input
                                onClick={() => setCourseType("Dinner")}
                                checked={courseType == "Dinner" ? true : false}
                                type="radio"
                                class="form-control"
                                name="select_course"
                                value="Dinner"
                                {...register("select_course")}
                              ></input>
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </Fragment>
                    )}

                    {/* ---------------Restaurant form end ---------------*/}
                    {/* ---------------Sports and culture form start ---------------*/}
                    {selectedService == "sports" && (
                      <Fragment>
                        <div className="col-lg-12 col-md-12">
                          <label className="servicesubHd">
                            {lang.sports_and_cultural_events}
                            <span></span>
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="form-group form_select_arrow DisplayinRmv autocomplete">
                            <label>
                              {lang.country}
                              <span>*</span>
                            </label>

                            <Controller
                              name={"sports_location"}
                              control={control}
                              render={({
                                field: { onChange, value, name },
                              }) => (
                                <Autocomplete
                                  getItemValue={(item) =>
                                    current_culture === "ar"
                                      ? item._source.displaynamear
                                      : item._source.displayname
                                  }
                                  items={cityListSports}
                                  renderItem={(item, isHighlighted) => (
                                    <div
                                      className={
                                        isHighlighted
                                          ? " react-autocomplete-inner active "
                                          : "react-autocomplete-inner"
                                      }
                                    >
                                      {current_culture === "ar"
                                        ? item._source.displaynamear
                                        : item._source.displayname}
                                    </div>
                                  )}
                                  {...register("sports_location", {
                                    required: {
                                      value: true,
                                      message:
                                        lang.please_select_restaurant_location,
                                    },
                                  })}
                                  onChange={(event) =>
                                    {cityRequestHandlerSports(event)
                                      event.target.value == "" && onChange(event.target.value)}
                                  }
                                  onSelect={(label,item) =>
                                    selectCityHandlerSports(label, onChange,item)
                                  }
                                  value={CitySports}
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={"sports_location"}
                              render={({ message }) => (
                                <span className="error-message">{message}</span>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group form_select_arrow DisplayinRmv autocomplete">
                            <label>
                              {lang.venue_stadium}
                              <span>*</span>
                            </label>
                            <Controller
                              name={"stadium"}
                              control={control}
                              render={({
                                field: { onChange, value, name },
                              }) => (
                                <Autocomplete
                                  getItemValue={(item) => item._source.caption}
                                  items={venueList}
                                  renderItem={(item, isHighlighted) => (
                                    <div
                                      className={
                                        isHighlighted
                                          ? " react-autocomplete-inner active "
                                          : "react-autocomplete-inner"
                                      }
                                    >
                                      {item._source.caption}
                                    </div>
                                  )}
                                  {...register("stadium", {
                                    required: {
                                      value: true,
                                      message: lang.pls_select_venue,
                                    },
                                  })}
                                  onChange={(event) =>
                                   { venueRequestHandler(event)
                                    event.target.value == "" && onChange(event.target.value)}
                                  }
                                  onSelect={(label) =>
                                    selectVenueHandler(label, onChange)
                                  }
                                  value={venue}
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={"stadium"}
                              render={({ message }) => (
                                <span className="error-message">{message}</span>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              {lang.from}
                              <span>*</span>
                            </label>
                            <DatePicker
                              minDate={new Date()}
                              locale={current_culture}
                              dateFormat={"dd/MM/yyyy"}
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown={true}
                              showYearDropdown={true}
                              selected={fromDateSports}
                              onChange={(value) => {
                                setFromDateSports(value);
                                setToDateSports(value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              {lang.to}
                              <span>*</span>
                            </label>
                            <DatePicker
                              locale={current_culture}
                              minDate={fromDateSports}
                              dateFormat={"dd/MM/yyyy"}
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown={true}
                              showYearDropdown={true}
                              selected={toDateSports}
                              onChange={(value) => {
                                setToDateSports(value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div class="form-group form_select_arrow">
                            <label>
                              {lang.no_of_attendees}
                              <span>*</span>
                            </label>
                            <select
                              {...register("no_of_guests_sports")}
                              name="no_of_guests_sports"
                              id=""
                              class="form-control"
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="3">4</option>
                              <option value="3">5</option>
                              <option value="3">6</option>
                              <option value="3">7</option>
                              <option value="3">8</option>
                              <option value="3">9</option>
                            </select>
                          </div>
                        </div>
                      </Fragment>
                    )}
                    {/* ---------------Sports and culture form end ---------------*/}
                    
                  </div>
                  {/* ---------------festival form end ---------------*/}
                 {/*  <div className="row mt-2">
                    {showFestivals && (
                      <Fragment>
                        <div className="col-lg-12 col-md-12">
                          <label className="servicesubHd">
                            {lang.festival_booking}
                            <span></span>
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="form-group form_select_arrow DisplayinRmv autocomplete">
                            <label>
                              {lang.country_city}
                              <span>*</span>
                            </label>

                            <Controller
                              name={"festival_location"}
                              control={control}
                              render={({
                                field: { onChange, value, name },
                              }) => (
                                <Autocomplete
                                  getItemValue={(item) =>
                                    current_culture === "ar"
                                      ? item._source.displaynamear
                                      : item._source.displayname
                                  }
                                  items={cityListFestival}
                                  renderItem={(item, isHighlighted) => (
                                    <div
                                      className={
                                        isHighlighted
                                          ? " react-autocomplete-inner active "
                                          : "react-autocomplete-inner"
                                      }
                                    >
                                      {current_culture === "ar"
                                        ? item._source.displaynamear
                                        : item._source.displayname}
                                    </div>
                                  )}
                                  {...register("festival_location", {
                                    required: {
                                      value: true,
                                      message:
                                        lang.please_select_restaurant_location,
                                    },
                                  })}
                                  onChange={(event) =>
                                    {cityRequestHandlerFestival(event)
                                      event.target.value == "" && onChange(event.target.value)}
                                  }
                                  onSelect={(label,item) =>
                                    selectCityHandlerFestival(label, onChange,item)
                                  }
                                  value={CityFestival}
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={"festival_location"}
                              render={({ message }) => (
                                <span className="error-message">{message}</span>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group form_select_arrow DisplayinRmv autocomplete">
                            <label>
                              {lang.name_of_festival}
                              <span></span>
                            </label>
                            <input
                              role="combobox"
                              aria-autocomplete="list"
                              aria-expanded="false"
                              autocomplete="off"
                              {...register("festival_name", {
                                required: {
                                  value: true,
                                  message:
                                    lang.please_select_festival_name,
                                },
                              })}
                             
                            ></input>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              {lang.from}
                              <span>*</span>
                            </label>
                            <DatePicker
                              minDate={new Date()}
                              locale={current_culture}
                              dateFormat={"dd/MM/yyyy"}
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown={true}
                              showYearDropdown={true}
                              selected={fromDateFestival}
                              onChange={(value) => {
                                setFromDateFestival(value);
                                setToDateFestival(value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              {lang.to}
                              <span>*</span>
                            </label>
                            <DatePicker
                              locale={current_culture}
                              minDate={fromDateFestival}
                              dateFormat={"dd/MM/yyyy"}
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown={true}
                              showYearDropdown={true}
                              selected={toDateFestival}
                              onChange={(value) => {
                                setToDateFestival(value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div class="form-group form_select_arrow">
                            <label>
                              {lang.no_of_pax}
                              <span></span>
                            </label>
                            <select
                              {...register("no_of_guests_festival")}
                              name="no_of_guests_festival"
                              id=""
                              class="form-control"
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="3">4</option>
                              <option value="3">5</option>
                              <option value="3">6</option>
                              <option value="3">7</option>
                              <option value="3">8</option>
                              <option value="3">9</option>
                            </select>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div> */}
                  <div className="row mt-2">
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>{lang.additional_remarks}</label>
                        <textarea
                          class="form-control"
                          name=""
                          rows=""
                          cols=""
                          {...register("additional_remarks", {})}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>{lang.special_requests}</label>
                        <textarea
                          class="form-control"
                          name=""
                          rows=""
                          cols=""
                          {...register("special_requests", {})}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>
                          {lang.name}
                          <span>*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          {...register("name", {
                            required: lang.name_is_required,
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,
                            },
                            pattern: {
                              value: /[A-Za-z]/,
                              message: lang.please_enter_valid_name,
                            },
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"name"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    {/*  <div className='col-lg-6 col-md-6'>
                  <div className='form-group   DisplayinRmv autocomplete'>
                        <label>First Name<span></span></label>
                        <input>
                        </input>
                      </div>
                </div>
                    <div className='col-lg-6 col-md-6'>
                  <div className='form-group   DisplayinRmv autocomplete'>
                        <label>Last Name<span></span></label>
                        <input>
                        </input>
                      </div>
                </div> */}
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>
                          {lang.email}
                          <span>*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          {...register("email", {
                            required: lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: lang.invalid_email_address,
                            },
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"email"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>
                          {lang.contact_number}
                          <span>*</span>
                        </label>
                        <div class="form-group d-flex flex-wrap">
                          <div class="phonecode form_phone_select_arrow position-relative">
                            <select
                              class="form-control"
                              {...register("mobileCode", {
                                required: {
                                  value: true,
                                  message: lang.phone_code_is_required,
                                },
                                onChange: (e) => {
                                  setMobileCode(e.target.value);
                                },
                                value: mobileCode,
                              })}
                            >
                              {phoneCode.map((item, key) => {
                                return (
                                  <option
                                    selected={
                                      mobileCode == item ? "selected" : ""
                                    }
                                    value={item}
                                  >
                                    {item}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div class="phonenumber">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=" "
                              {...register("contact_number", {
                                required: {
                                  value: true,
                                  message: lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message:
                                    lang.please_enter_atleast_six_numbers,
                                },

                                onChange: (e) => {},
                              })}
                            ></input>
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={"contact_number"}
                            render={({ message }) => (
                              <span className="error-message">{message}</span>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    {(showRestaurant || showSportsCulture || showFestivals) && (
                      <Fragment>
                        <div class="col-lg-12 col-md-12 d-flex justify-content-end">
                          <button type="submit" class="form_submit_btn">
                            {lang.submit}
                          </button>
                        </div>

                        {/* <div class="col-lg-12 col-md-12 d-flex justify-content-end">
                          <p class="form_note">
                            {lang.note_now_you_are_on_guest_user_please}{" "}
                            <a href="#">{lang.login}</a>{" "}
                            {lang.to_submit_your_enquiry}
                          </p>
                        </div> */}
                        {tokenData.data.isDefaultUser && <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <p className="form_note">
                        {lang.note_now_you_are_on_guest_user_please}{" "}
                        <a href="#" onClick={() => setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}
                      </p>
                    </div>}
                      </Fragment>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default LifeStyleServices;
