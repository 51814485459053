import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import qs from 'qs'
//  import { getTokenApiRequest } from '../services/getTokenApiRequest';

const getServices = createAsyncThunk('api/GetServices', (token) => {
    // return JSON.parse(localStorage.getItem('services'))
    return axios.get(process.env.REACT_APP_API_URL + 'ConciergeService/GetServices', {
        headers: {
            "Authorization": `Bearer ` + token,
        }
    }
    ).then((response) => {
        return response.data.data;
    });
});

const serviceInfoSlice = createSlice({
    name: 'data',
    initialState: {
        data: {

        },
        error: '',
        loading: true
    },
    extraReducers: {
        [getServices.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [getServices.pending]: (state) => {
            state.loading = true;
        },
        [getServices.rejected]: (state) => {
            state.loading = false;
            state.error = "Service api error"
        },

    }

});

export { getServices };

export default serviceInfoSlice.reducer;