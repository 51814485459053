import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Link, Routes, Route } from "react-router-dom";
import "./Paths.css";

import Header from "./components/common/header";
import Footer from "./components/common/footer";
import HomePage from "./pages/home_page";
import ServiceList from "./pages/service_list";
import BusinessBankingPage from "./pages/service_detail/busines_banking";
import { getTokenDataInfo } from "./redux/getBearortoken";
import { useSelector, useDispatch } from "react-redux";
import { getServices } from "./redux/getServices";

import FlightTicketAcquisition from "./pages/service_detail/FlightTicketAcquisition";
import GlobalAirportServices from "./pages/service_detail/GlobalAirportServices";
import GlobalConciergeService from "./pages/service_detail/GlobalConciergeService";
import HotelBookings from "./pages/service_detail/HotelBookings";
import HouseMaidServices from "./pages/service_detail/HouseMaidServices";
import LimousineCarRental from "./pages/service_detail/LimousineCarRental";
import LuggagePickupService from "./pages/service_detail/LuggagePickupService";
import CarRenewal from "./pages/service_detail/car_renewal";
import CprPassportDrivingLicenseRenewal from "./pages/service_detail/cpr_passport_driving_license";
import MeetNAssist from "./pages/service_detail/MeetNAssist";
import MessengerServices from "./pages/service_detail/MessengerServices";
import MICEInternationalWeddingPlanning from "./pages/service_detail/MICEInternationalWeddingPlanning";
import SportsNCulturalEvents from "./pages/service_detail/SportsNCulturalEvents";
import TourPackages from "./pages/service_detail/TourPackages";
import VVIPServices from "./pages/service_detail/VVIPServices";

import PersonalAssistance from "./pages/service_detail/PersonalAssistance";
import RestaurantReservation from "./pages/service_detail/RestaurantReservation";
import RoadsideAssistance from "./pages/service_detail/RoadsideAssistance";

import SoftSkillTraining from "./pages/service_detail/SoftSkillTraining";
import GovernmentalServices from "./pages/service_detail/GovermentalServices";

import MyProfile from "./pages/MyProfile";
import MyEnquiries from "./pages/my_enquiries/MyEnquiries";
import AlertBoxSuccess from "./components/common/AlertBoxSuccess";
import AlertBoxError from "./components/common/AlertBoxError";
import FulloverLoader from "./components/common/FulloverLoader";
import PreLoader from "./components/common/PreLoader";
import AboutUs from "./pages/cms/About_Us";
import TermsConditions from "./pages/cms/Terms_Conditions";
import PrivacyPolicy from "./pages/cms/Privacy_Policy";
import { getlanguageinfo } from "./redux/language";
import ContactUs from "./pages/cms/Contact_Us";
import SiteMap from "./pages/cms/Site_Map";
import CHangePassword from "./components/common/ChangePassword";
import MailLinkRedirect from "./components/common/MailLinkRedirect";
import LifeStyleServices from "./pages/service_detail/LifeStyleServices";
import VisaServices from "./pages/service_detail/VisaServices";
import HolidayPackages from "./pages/service_detail/HolidayPackages";
import AllocationAndPets from "./pages/service_detail/AllocationAndPets";
import PersonalService from "./pages/service_detail/PersonalService";
import Voucher from "./pages/my_enquiries/Voucher";
import WalletDetails from "./pages/Wallet_details";
import { useSearchParams } from "react-router-dom";
import { changeCulture } from "./redux/CultureSlice";

function Paths() {

  
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [voucherLangLoader, setVoucherLangLoader] = useState(false)

  const current_culture = useSelector((state) => state.culture.value);
  const url_segment = process.env.REACT_APP_BASE_URL.replace("/", "");
  let current_url = "";
  if (url_segment != "")
    current_url = window.location.href.replace(url_segment, "");
  else current_url = window.location.href;

  let url_culture = current_url.split("/")[3];

  

  let download = searchParams.get("download");
  let appView = searchParams.get("isMobileApp")



  const dispatch = useDispatch();
  const tokenStatus = useSelector((state) => state.tokenInfo);
  const languageStatus = useSelector((state) => state.language);

  console.log("tokenStatus", tokenStatus);
  const serviceStatus = useSelector((state) => state.serviceInfo);
  const popUpType = useSelector((state) => state.alert.popUpType);


  if ((url_culture == "en" || url_culture == "ar") &&  current_url.split("/")[4].split("?")[0] == "Voucher" ) {

    if(current_culture != url_culture){
        dispatch(changeCulture(url_culture));
    localStorage.setItem("culture", url_culture);

    window.location.reload();
    }
    
  }

  useEffect(() => {
    if (
      tokenStatus.loading == false &&
      tokenStatus.error == "" &&
      tokenStatus.data.token != "0"
    ) {
      dispatch(getlanguageinfo(tokenStatus.data.token));
      dispatch(getServices(tokenStatus.data.token));
    }
  }, [tokenStatus.loading]);
  useEffect(() => {
    dispatch(getTokenDataInfo());
    const timer = setInterval(() => {
      dispatch(getTokenDataInfo());
    }, 600000);
    return () => clearInterval(timer);
  }, []);

  if (tokenStatus.loading == false && tokenStatus.error != "") {
    return <>{tokenStatus.error}</>;
  }
  if (serviceStatus.loading == false && serviceStatus.error != "") {
    return <>{serviceStatus.error}</>;
  }
  if (languageStatus.loading == false && languageStatus.error != "") {
    return <>{languageStatus.error}</>;
  }
  if (
    tokenStatus.loading == true ||
    serviceStatus.loading == true ||
    languageStatus.loading == true ||
    voucherLangLoader == true
  ) {
    return <PreLoader />;
  }

  console.log(popUpType);

  return (
    <div
      className={
        popUpType == "success" || popUpType == "error" ? "over_hide" : ""
      }
    >
      {popUpType == "success" && <AlertBoxSuccess />}
      {popUpType == "error" && <AlertBoxError />}
      <Routes>
      

        <Route
          path="*"
          element={
            (!download && appView != "true") ? (
              <Header
                showLoginModal={showLoginModal}
                setShowLoginModal={setShowLoginModal}
                showRegisterModal={showRegisterModal}
                setShowRegisterModal={setShowRegisterModal}
                setVoucherLangLoader={setVoucherLangLoader}
              />
            ) : (
              <></>
            )
          }
        />
      </Routes>
      <Routes>
        <Route path={process.env.REACT_APP_BASE_URL} element={<HomePage />} />
        <Route
          path={process.env.REACT_APP_BASE_URL + "services"}
          element={<ServiceList />}
        />
        <Route
          path={
            process.env.REACT_APP_BASE_URL +
            "Business-Banking-Outsourcing-Contact-Center"
          }
          element={
            <BusinessBankingPage setShowLoginModal={setShowLoginModal} />
          }
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Car-Renewal"}
          element={<CarRenewal setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "CPR-Passport-Licence-Renewal"}
          element={
            <CprPassportDrivingLicenseRenewal
              setShowLoginModal={setShowLoginModal}
            />
          }
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Flight-Ticket-Acquisition"}
          element={
            <FlightTicketAcquisition setShowLoginModal={setShowLoginModal} />
          }
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Global-Airport-Services"}
          element={
            <GlobalAirportServices setShowLoginModal={setShowLoginModal} />
          }
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Global-Concierge-Service"}
          element={
            <GlobalConciergeService setShowLoginModal={setShowLoginModal} />
          }
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Hotel-Bookings"}
          element={<HotelBookings setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "House-Maid-Services"}
          element={<HouseMaidServices setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={
            process.env.REACT_APP_BASE_URL + "Limousine-Car-Rental-Services"
          }
          element={<LimousineCarRental setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Luggage-Pickup-Service"}
          element={
            <LuggagePickupService setShowLoginModal={setShowLoginModal} />
          }
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Meet-And-Assist"}
          element={<MeetNAssist setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Messenger-Services"}
          element={<MessengerServices setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={
            process.env.REACT_APP_BASE_URL +
            "MICE-International-Wedding-Planning"
          }
          element={
            <MICEInternationalWeddingPlanning
              setShowLoginModal={setShowLoginModal}
            />
          }
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Personal-Assistance"}
          element={<PersonalAssistance setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Restaurant-Reservation"}
          element={
            <RestaurantReservation setShowLoginModal={setShowLoginModal} />
          }
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Roadside-Assistance"}
          element={<RoadsideAssistance setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Soft-Skill-Training"}
          element={<SoftSkillTraining setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Sports-And-Cultural-Events"}
          element={
            <SportsNCulturalEvents setShowLoginModal={setShowLoginModal} />
          }
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Tour-Packages"}
          element={<TourPackages setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "VVIP-Services"}
          element={<VVIPServices setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Governmental-Services"}
          element={
            <GovernmentalServices setShowLoginModal={setShowLoginModal} />
          }
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Life-Style-Services"}
          element={<LifeStyleServices setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "VISA-Services"}
          element={<VisaServices setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Holiday-Packages"}
          element={<HolidayPackages setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Allocations-And-Pets"}
          element={<AllocationAndPets setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Personal-Services"}
          element={<PersonalService setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "My-Profile"}
          element={<MyProfile setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "My-Enquiries"}
          element={<MyEnquiries setShowLoginModal={setShowLoginModal} />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Wallet"}
          element={
            <WalletDetails
              setShowLoginModal={setShowLoginModal}
              showRegisterModal={showRegisterModal}
            />
          }
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "About-Us"}
          element={<AboutUs />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Terms-And-Conditions"}
          element={<TermsConditions />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Privacy-Policy"}
          element={<PrivacyPolicy />}
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + "Contact-Us"}
          element={<ContactUs />}
        />
        {/* <Route path={process.env.REACT_APP_BASE_URL + "Site-Map"} element={<SiteMap setShowLoginModal={setShowLoginModal} setShowRegisterModal={setShowRegisterModal} />} /> */}
        <Route
          path={process.env.REACT_APP_BASE_URL + ":culture/Mail/ResetPassword"}
          element={
            <>
              <MailLinkRedirect />
            </>
          }
        />
        <Route
          path={
            process.env.REACT_APP_BASE_URL + ":culture/changePassword"
          }
          element={
            <>
              <HomePage />
              <CHangePassword />
            </>
          }
        />
        <Route
          path={process.env.REACT_APP_BASE_URL + ":culture/Voucher"}
          element={<Voucher />}
        />
      </Routes>
      <Routes>
        <Route
          path={process.env.REACT_APP_BASE_URL + ":culture/Mail/:params"}
          element={<></>}
        />
        <Route path="*" element={(!download && appView != "true") ? <Footer /> : <></>} />
      </Routes>
    </div>
  );
}

export default Paths;
