import { createSlice } from '@reduxjs/toolkit'
if (!localStorage.getItem('culture')) {
    localStorage.setItem('culture', process.env.REACT_APP_CULTURE);
}
export const CultureSlice = createSlice({
    name: 'culture',
    initialState: {
        value: localStorage.getItem('culture')
    },
    reducers: {
        changeCulture: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { changeCulture } = CultureSlice.actions
export default CultureSlice.reducer