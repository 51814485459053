import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "@hookform/error-message";
import { getTimeData } from "../../services/common";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import ar from "date-fns/locale/ar"; // the locale you want
import {  Link} from 'react-router-dom';

import InnerBanner from "../../components/common/inner_banner";
import { getServiceDetailsById } from "../../scripts/common";
import ServiceBoxLeft from "./ServiceBoxLeft";
import { postServices } from "../../services/common";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import UserList from '../../components/common/UserList';
import { setPhoneCodeList } from "../../services/common";

import CprPassport_servicedetailServiceDetails from "../../assets/images/cpr_passport_servicedetail.jpg";
import FulloverLoader from "../../components/common/FulloverLoader";

function CprPassportDrivingLicenseRenewal({setShowLoginModal}) {

  const tokenData =useSelector((state) => state.tokenInfo);
  let lang = useSelector((state) => state.language.data);
  const current_culture = useSelector(state => state.culture.value);
  registerLocale("ar", ar);
  let myProfile = JSON.parse(localStorage.getItem("myProfile"));

  const [serviceDate, setServiceDate] = useState(new Date());
  const [selectedService, setSelectedService] = useState("");
  const [walletData, setWalletData] = useState(null)
  const [serviceDetails, setServiceDetails] = useState();
  const [serviceId, setServiceId] = useState(3);
  const [isLoading, setIsLoading] = useState(false)
  const [phoneCode, setPhoneCode] = useState([])
  const [mobCode, setMobCode] = useState(process.env.REACT_APP_MOBCODE)
  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState('');


  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0,0)
    const serviceDetailsData = getServiceDetailsById(serviceId);
    setServiceDetails(serviceDetailsData);

    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList()

      setPhoneCode(phoneCodeList)
    } 

    fetchPhoneCode()

  }, []);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm();

  const resetForm=()=>{
    reset()
    setMobCode(process.env.REACT_APP_MOBCODE)
    setServiceDate(new Date())
    setSelectedService("")
    setOnBehalfId("")
    setValue('OnBehalfId',"")
    setValue('service', "")
    setUserText('')
    setWalletData(null)
  }

  const onSubmit = async (data) => {

    

    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true)
    } else {
    setIsLoading(true)

    let serviceRenewalPrams = {
      ServiceID: serviceId,
      /* OnBehalfId: onBehalfId, */
      Data: JSON.stringify({
        name: data.Name,
        email: data.email,
        mobile_code: mobCode,
        contact_number: data.mobileno,
        civil_id_number: data.civilId,
        please_select_the_service_type:
          data.service != "Other" ? data.service : data.others,
        date_and_time_of_service: `${Moment(serviceDate).format(
          "DD/MM/YYYY"
        )} ${data.serviceTime}`,
        additional_information: data.additionalInfo,
      }),
    };

    if(onBehalfId) {
      serviceRenewalPrams["OnBehalfId"] = onBehalfId
    }
    const response = await postServices(serviceRenewalPrams);
    
      
      if (response) {
        dispatch(alertActions.setPopUpType('success'))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
        dispatch(alertActions.setPopUpData({ heading: lang.sucess, message: lang.form_submitted_sucessfully }))

        setIsLoading(false)
        resetForm()
        /* alert('form submited')
        navigate(process.env.REACT_APP_BASE_URL, { replace: true }); */

      } else {
        dispatch(alertActions.setPopUpType('error'))
        dispatch(alertActions.setPopUpData({ heading: lang.failed, message: lang.something_went_wrong_please_try_again }))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
        /* alert('something went wrong ,please try again') */
        setIsLoading(false)
      }
      
  }
  };

  return (
    <React.Fragment>
      {isLoading && <FulloverLoader/>}

      <InnerBanner
        title={lang.cpr_passport_and_diving_license_renewal}
      ></InnerBanner>

      <section className="content_service_main form_bg">
        <div class="container">
          <div className="form_breadcrumbs">
            <ul>
              <li>
              <Link to={process.env.REACT_APP_BASE_URL+'services'}>{lang.services} </Link>
              </li>
              <li>
                <a className="active" href="">
                {lang.cpr_passport_and_diving_license_renewal}
                </a>
              </li>
            </ul>
          </div>
          <div className="service_box">
          {serviceDetails && (
              <ServiceBoxLeft serviceDetails={serviceDetails} />
            )}
            <div className="service_box_col_rht">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="service_detail_formbox formstyle">
                  <div className="service_detail_formhd">
                    <h2>{lang.feel_like_contacting_us}</h2>
                    <p>
                      {lang.submit_the_form_below_we_will_get_back_to_you_asap}
                    </p>
                  </div>
                  <hr className="form_hd_hr"></hr>
                  {(myProfile && myProfile.isAgentLogin && ! tokenData.data.isDefaultUser) &&
                    <UserList
                      register={register}
                      onBehalfId={onBehalfId}
                      setOnBehalfId={setOnBehalfId}
                      errors={errors}
                      control={control}
                      userText={userText}
                      setUserText={setUserText}
                      walletData={walletData}
                      setWalletData={setWalletData}
                    />
                  }
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="form-group">
                        <label>
                          {lang.name}<span>*</span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          {...register("Name", {
                            required: lang.name_is_required,
                            pattern: {
                              value: /[A-Za-z]/,
                              message: lang.please_enter_valid_name,
                            },
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,
                            },

                            value: "",
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"Name"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="form-group">
                        <label>
                          {lang.email}<span>*</span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          {...register("email", {
                            required: lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: lang.invalid_email_address,
                            },

                            value: "",
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"email"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="form-group">
                        <label>
                          {lang.contact_number}<span>*</span>
                        </label>
                        <div class="form-group d-flex flex-wrap">
                          <div class="phonecode form_phone_select_arrow position-relative">
                            <select
                              class="form-control"
                              value={mobCode}
                              {...register("phoneCode", {
                                required: {
                                  value: true,
                                  message: lang.phone_code_is_required,
                                },
                                onChange:(e)=>setMobCode(e.target.value),
                                value:mobCode
                              })}
                            >
                              
                              {phoneCode.map((item)=> {
                                return <option value={item}>{item}</option>
                              })}
                            </select>
                            <ErrorMessage
                                errors={errors}
                                name={"phoneCode"}
                                render={({ message }) => (
                                  <span className="error-message">
                                    {message}
                                  </span>
                                )}
                              />
                          </div>
                          <div class="phonenumber">
                            <input
                              type="name"
                              class="form-control"
                              placeholder=" "
                              {...register("mobileno", {
                                required: {
                                  value: true,
                                  message: lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message: lang.please_enter_atleast_six_numbers,
                                },

                                value: "",
                              })}
                            ></input>

                          </div>
						      <ErrorMessage
                              errors={errors}
                              name={"mobileno"}
                              render={({ message }) => (
                                <span className="error-message">{message}</span>
                              )}
                            />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="form-group">
                        <label>
                          {lang.civil_id_number}<span>*</span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          {...register("civilId")}
                        ></input>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="form-group form_select_arrow">
                        <label>
                          {lang.please_select_the_service}<span>*</span>
                        </label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          value={selectedService}
                          {...register("service", {
                            required: {
                              value: true,
                              message: lang.please_select_the_service,
                            },
                            onChange: (event) =>
                              setSelectedService(event.target.value),
                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="CPR Renewal">{lang.cpr_renewal}</option>
                          <option value="Passport Renewal">{lang.passport_renewal}</option>
                          <option value="Driving License Renewal">{lang.driving_license_renewal}</option>
                          <option value="Other">{lang.select_other}</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={"service"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>

                    {selectedService === "Other" && (
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="form-group">
                          <label>
                           {lang.if_you_have_selected_other_please_specify}
                          </label>
                          <textarea
                            class="form-control"
                            name=""
                            rows=""
                            cols=""
                            {...register("others", {
                              required: {
                                value: true,
                                message: lang.please_specify_the_service,
                              },
                            })}
                          ></textarea>
                          <ErrorMessage
                            errors={errors}
                            name={"others"}
                            render={({ message }) => (
                              <span className="error-message">{message}</span>
                            )}
                          />
                        </div>
                      </div>
                    )}

                    <div className="col-lg-4 col-md-8 col-12">
                      <div className="form-group">
                        <label>
                        {lang.date_and_time_of_the_service}<span></span>
                        </label>
                        {/* <input
                          type="name"
                          class="form-control"
                          placeholder="DD/MM/YYYY"
                        ></input> */}
                        <DatePicker
                          minDate={new Date()}
                          locale={current_culture}
                          dateFormat={"dd/MM/yyyy"}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown={true}
                          selected={serviceDate}
                          onChange={(value) => {
                            setServiceDate(new Date(value));
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-12">
                      <div className="form-group">
                        <label> </label>

                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register("serviceTime")}
                        >
                          {getTimeData.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>
                    {lang.additional_information}<span></span>
                        </label>
                        <textarea
                          class="form-control"
                          name=""
                          rows=""
                          cols=""
                          {...register("additionalInfo")}
                        ></textarea>
                        <ErrorMessage
                          errors={errors}
                          name={"additionalInfo"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <button type="submit" class="form_submit_btn">
                       {lang.submit}
                      </button>
                    </div>
                    {tokenData.data.isDefaultUser && <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <p className="form_note">
                       {lang.note_now_you_are_on_guest_user_please}{" "}
                        <a href="#" onClick={()=>setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}
                      </p>
                    </div>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default CprPassportDrivingLicenseRenewal;
