import React, { useEffect } from 'react';
import { changeCulture } from "../../redux/CultureSlice";

import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FulloverLoader from './FulloverLoader';

const MailLinkRedirect = () => {
    let params = useParams();
    
    useEffect(() => {

    
        localStorage.setItem('culture', params.culture)

        let url = window.location.href;
        url = url.replace('Mail/ResetPassword?', 'changePassword?')
        window.location.replace(url)
        // window.location.replace(url)



    }, []);
    return (
        <FulloverLoader />
    );
}

export default MailLinkRedirect;
