import axios from 'axios';
import qs from 'qs'
import reduxStore from '../redux/store'


reduxStore.subscribe(getTokenApiRequest)


export function getTokenApiRequest() {
    let store = reduxStore.getState()
    let api = axios.create({
        baseURL: process.env.REACT_APP_API_URL ,
        headers: {
            "Authorization": `Bearer ` + store.tokenInfo.data.token,
        }
    })
    return api;
}