import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Link, Routes, Route } from 'react-router-dom';

import './index.css';
import './assets/css/base.css';


import { Provider } from 'react-redux'
import store from './redux/store'
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import Paths from './Paths';

const EnTheme = React.lazy(() => import('./enCss'));
const ArTheme = React.lazy(() => import('./arCss'));

const CHOSEN_THEME = localStorage.getItem('culture');



ReactDOM.render(

  <Suspense fallback={<></>}>
    {(CHOSEN_THEME === "en" || CHOSEN_THEME === null) && <EnTheme />}
    {(CHOSEN_THEME === "ar") && <ArTheme />}
    <Provider store={store}>
    <BrowserRouter>
      <Paths />
      </BrowserRouter>
    </Provider>
  </Suspense>
  ,
  document.getElementById('root')
);
