import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { ErrorMessage } from '@hookform/error-message'
import FulloverLoader from "./FulloverLoader";

export default function Forgot({register,errors,isLoading}) {

  let lang = useSelector((state) => state.language.data);

  return (
    <>
    {isLoading && <FulloverLoader/>}
      <div className="log-field">
        <label>{lang.email}</label>
        <input
          type="text"
          placeholder={lang.email}
          {...register("email", {
            required: {
              value: true,
              message: lang.email_is_required,
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: lang.invalid_email_address,
            },
          })}
        />
        <ErrorMessage
          errors={errors}
          name={"email"}
          render={({ message }) => (
            <span className="error-message">{message}</span>
          )}
        />
      </div>
    </>
  );
}
