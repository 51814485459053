import React, { useEffect, useState } from 'react';

import InnerBanner from '../../components/common/inner_banner';

import BusinessBankingServiceDetails from "../../assets/images/business_banking_servicedetail.jpg"

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { getServiceDetailsById } from '../../scripts/common';
import ServiceBoxLeft from './ServiceBoxLeft';
import { postServices, setPhoneCodeList } from '../../services/common'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import FulloverLoader from '../../components/common/FulloverLoader';
import produce from "immer"
import UserList from '../../components/common/UserList';
const serviceId = 1;

function BusinessBankingPage({ setShowLoginModal }) {
  const tokenData = useSelector((state) => state.tokenInfo);
  
  let myProfile = JSON.parse(localStorage.getItem("myProfile"));
  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState('');
  const [walletData, setWalletData] = useState(null)

  let lang = useSelector((state) => state.language.data);
  const [phoneCode, setPhoneCode] = useState([])
  const [mobileCode, setMobileCode] = useState(process.env.REACT_APP_MOBCODE);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },setValue
  } = useForm();
  const [serviceType, setServiceType] = useState("");
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const [serviceDetails, setserviceDetails] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    const serviceDetailsData = getServiceDetailsById(serviceId);
    setserviceDetails(serviceDetailsData)
    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList()
      setPhoneCode(phoneCodeList)
    }

    fetchPhoneCode()
  }, []);
  const onSubmit = async (data) => {
    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true)
    } else {

      setIsLoading(true)

      let bussinessBankingParams = {
        ServiceID: serviceId,
        /* OnBehalfId: onBehalfId, */
        Data: JSON.stringify({
          name: data.name,
          email: data.email,
          mobile_code: data.mobileCode,
          contact_number: data.contact_number,
          please_select_the_service_type: data.please_select_the_service != "Other" ? data.please_select_the_service : data.serviceDescription,
          additional_information: data.additional_information,
          // fieldOrder:'name,email,mobile_code,contact_number,please_select_the_service_type,additional_information'
        })
      }
      if(onBehalfId) {
        bussinessBankingParams["OnBehalfId"] = onBehalfId
      }
      const response = await postServices(bussinessBankingParams);
      if (response) {
        dispatch(alertActions.setPopUpType("success"))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: '' }))
        dispatch(alertActions.setPopUpData({ heading: lang.alert_sucess, message: lang.alert_form_submitted_successfully }))
        resetForm();
        setIsLoading(false)

      } else {
        dispatch(alertActions.setPopUpType("error"))
        dispatch(alertActions.setPopUpData({ heading: lang.alert_failed, message: lang.alert_something_went_wrong_try_again }))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))

        setIsLoading(false)
      }
    }

  }
  const resetForm = () => {
    reset();
    setServiceType("")
    setMobileCode(process.env.REACT_APP_MOBCODE)
    setOnBehalfId("")
    setValue('OnBehalfId',"")
    setValue('please_select_the_service',"")
    setUserText('')
    setWalletData(null)
  }
  return (

    <React.Fragment>
      {
        isLoading &&
        <FulloverLoader />
      }
      <InnerBanner title={lang.business_banking_outsourcing_contact_center}></InnerBanner>

      <section className='content_service_main form_bg'>
        <div class='container'>
          <div className='form_breadcrumbs'>
            <ul>
              <li><Link to={process.env.REACT_APP_BASE_URL + 'services'}>{lang.services} </Link></li>
              <li><a className='active' href=''>{lang.business_banking_outsourcing_contact_center}</a></li>
            </ul>
          </div>
          <div className='service_box'>
            {
              serviceDetails &&
              <ServiceBoxLeft
                serviceDetails={serviceDetails}
              />
            }
            <div className='service_box_col_rht'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='service_detail_formbox formstyle'>
                  <div className='service_detail_formhd'>
                    <h2>{lang.feel_like_contacting_us}</h2>
                    <p>{lang.submit_the_form_below_we_will_get_back_to_you_asap}</p>
                  </div>
                  <hr className='form_hd_hr'>
                  </hr>




                  {(myProfile && myProfile.isAgentLogin && ! tokenData.data.isDefaultUser) &&
                    <UserList
                      register={register}
                      onBehalfId={onBehalfId}
                      setOnBehalfId={setOnBehalfId}
                      errors={errors}
                      control={control}
                      userText={userText}
                      setUserText={setUserText}
                      walletData={walletData}
                      setWalletData={setWalletData}

                    />
                  }




                  <div className='row'>


                    <div className='col-lg-6 col-md-12 col-12'>
                      <div className='form-group'>
                        <label>{lang.name}<span>*</span></label>
                        <input type="text"
                          {...register('name', {
                            required: lang.name_is_required,
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,

                            },
                            pattern: {
                              value: /[A-Za-z]/,
                              message: lang.please_enter_valid_name,
                            },
                          })}
                          class="form-control"
                        >
                        </input>
                        <ErrorMessage
                          errors={errors}
                          name={'name'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>

                    </div>
                    <div className='col-lg-6 col-md-12 col-12'>
                      <div className='form-group'>
                        <label>{lang.email}<span>*</span></label>
                        <input type="text"
                          {...register('email', {
                            required: lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: lang.invalid_email_address,
                            },


                          })} class="form-control">
                        </input>
                        <ErrorMessage
                          errors={errors}
                          name={'email'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>

                    </div>
                    <div className='col-lg-6 col-md-12 col-12'>
                      <div className='form-group'>
                        <label>{lang.contact_number}<span>*</span></label>
                        <div class="form-group d-flex flex-wrap">
                          <div class="phonecode form_phone_select_arrow position-relative">
                            <select class="form-control"
                              {...register('mobileCode', {
                                required: {
                                  value: true,
                                  message:
                                    lang.phone_code_is_required,
                                },
                                onChange: (e) => {
                                  setMobileCode(e.target.value)
                                }, value: mobileCode
                              })}
                            >
                              {phoneCode.map((item, key) => {
                                return <option selected={mobileCode == item ? 'selected' : ''} value={item}>{item}</option>
                              })}
                            </select>

                          </div>
                          <div class="phonenumber">
                            <input type="name"
                              class="form-control"
                              {...register('contact_number', {
                                required: {
                                  value: true,
                                  message:
                                    lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message:
                                    lang.please_enter_atleast_six_numbers,
                                },

                                onChange: e => {

                                },
                              })}

                              placeholder=" ">
                            </input>
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={'contact_number'}
                            render={({ message }) => (
                              <span className='error-message'>{message}</span>
                            )}
                          />
                        </div>
                      </div>

                    </div>
                    <div className='col-lg-6 col-md-12 col-12'>
                      <div className='form-group form_select_arrow'>
                        <label>{lang.please_select_the_service}<span>*</span></label>
                        <select name="" id="" class="form-control"
                          {...register('please_select_the_service', {
                            required: lang.service_type_is_required,
                            onChange: (e) => {
                              setServiceType(e.target.value)
                            }
                          })}
                          value={serviceType}
                        >
                          <option value="">{lang.select}</option>
                          <option value="Coordination with brokerage and wealth management firms">{lang.coordination_with_brokerage_wealth_management_firms}</option>
                          <option value="Complete asset transfer paperwork">{lang.complete_asset_transfer_paperwork}</option>
                          <option value="Management, automation and verification of recurring transactions">{lang.management_automation_verification_of_reccurring_transactions}</option>
                          <option value="Trust and private foundation administration">{lang.trust_private_foundation_administration}</option>
                          <option value="Household employee coordination and payroll">{lang.household_employee_coordination_payroll}</option>
                          <option value="Charitable donor due diligence and monitoring">{lang.charitable_donor_due_diligence_monitoring}</option>
                          <option value="International currency planning">{lang.international_currency_planning}</option>
                          <option value="Emergency coordination">{lang.emergency_coordination}</option>
                          <option value="Document management">{lang.document_management}</option>
                          <option value="Accounting, bookkeeping and budget oversight">{lang.accounting_bookkeeping_budget_oversight}</option>
                          <option value="Establishment of internal controls and expense approval">{lang.establishment_of_internal_controls_expense_approval}</option>
                          <option value="Detailed financial reporting and budgeting">{lang.detailed_financial_reporting_budgeting}</option>
                          <option value="Analysis of financial documents and coordination with bank and vendor relationships">{lang.analysis_of_financial_documents_coordination_with_bank_vendor_relationships}s</option>
                          <option value="Private bank account monitoring, billing, cash flow management, and payment facilitation">{lang.private_bank_account_monitoring_billing_cashflow}</option>
                          <option value="Other">{lang.select_other}</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={'please_select_the_service'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>

                    </div>
                    {serviceType == "Other" &&
                      <div className='col-lg-12 col-md-12'>
                        <div className='form-group'>
                          <label>{lang.if_you_have_selected_other_please_specify}</label>
                          <textarea class="form-control" name="" rows="" cols=""
                            {...register('serviceDescription', {
                              required: lang.service_description_is_required,

                            })}
                          ></textarea>
                          <ErrorMessage
                            errors={errors}
                            name={'serviceDescription'}
                            render={({ message }) => (
                              <span className='error-message'>{message}</span>
                            )}
                          />
                        </div>

                      </div>
                    }

                    <div className='col-lg-12 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.additional_information}</label>
                        <textarea class="form-control" name="additional_information" rows="" cols=""
                          {...register('additional_information', {

                          })}
                        ></textarea>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                      <button type="submit" class="form_submit_btn">{lang.submit}</button>
                    </div>
                    {
                      tokenData.data.isDefaultUser &&
                      <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                        <p className='form_note'>{lang.note_now_you_are_on_guest_user_please} <a href='#' onClick={() => setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}</p>
                      </div>
                    }
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section >


    </React.Fragment >

  )
}
export default BusinessBankingPage;