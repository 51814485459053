import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import { Link } from 'react-router-dom';

import InnerBanner from "../../components/common/inner_banner";
import { getServiceDetailsById } from "../../scripts/common";
import ServiceBoxLeft from "./ServiceBoxLeft";
import { postServices } from "../../services/common";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import { setPhoneCodeList, getCountryOrCity } from "../../services/common";
import ar from "date-fns/locale/ar"; // the locale you want


import VVIPServicesDetail from "../../assets/images/vvip_service.jpg";
import FulloverLoader from "../../components/common/FulloverLoader";
import UserList from '../../components/common/UserList';

function VVIPServices({ setShowLoginModal }) {

  const tokenData = useSelector((state) => state.tokenInfo);
  let lang = useSelector((state) => state.language.data);
  const current_culture = useSelector(state => state.culture.value);
  registerLocale("ar", ar);
  let myProfile = JSON.parse(localStorage.getItem("myProfile"));

  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState('');
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectedService, setSelectedService] = useState("");
  const [serviceDetails, setServiceDetails] = useState();
  const [serviceId, setServiceId] = useState(20);
  const [phoneCode, setPhoneCode] = useState([])
  const [mobCode, setMobCode] = useState(process.env.REACT_APP_MOBCODE)
  const [City, setCity] = useState("")
  const [cityList, setCityList] = useState([])
  const [cityLabel, setCityLabel] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {

    window.scrollTo(0, 0)
    const serviceDetailsData = getServiceDetailsById(serviceId);
    setServiceDetails(serviceDetailsData);

    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList()

      setPhoneCode(phoneCodeList)
    }

    fetchPhoneCode()
  }, []);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm();

  const cityRequestHandler = async (e) => {
    setCity(e.target.value);

    const cityResponse = await getCountryOrCity(e.target.value)

    if (cityResponse) {

      setCityList(cityResponse)
    }
  };

  const selectCityHandler = (value, item, onChange) => {
    setCity(value)
    setCityLabel(item._source.displayname)
    onChange(value)

  }

  const resetForm = () => {
    reset()
    setMobCode(process.env.REACT_APP_MOBCODE)
    setSelectedService("")
    setFromDate(new Date())
    setToDate(new Date())
    setCity("")
    setCityLabel("")
    setCityList([])
    setOnBehalfId("")
    setValue('OnBehalfId', "")
    setUserText('')

  }

  const onSubmit = async (data) => {

    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true)
    } else {

      setIsLoading(true)
      console.log(data);

      let tourPackageParams = {
        ServiceID: serviceId,
        /* OnBehalfId: onBehalfId, */
        Data: JSON.stringify({
          name: data.Name,
          email: data.email,
          mobile_code: mobCode,
          contact_number: data.mobileno,
          select_service: data.service,
          select_other_specifically: data.serviceOther,
          country_city: cityLabel,
          number_of_adults: data.adultCount,
          number_of_childs: data.childCount,
          from: Moment(fromDate).format("DD/MM/YYYY"),
          to: Moment(toDate).format("DD/MM/YYYY"),
          additional_information: data.addiitionalInfo,

        }),
      };
      if(onBehalfId) {
        tourPackageParams["OnBehalfId"] = onBehalfId
      }
      const response = await postServices(tourPackageParams);


      if (response) {
        dispatch(alertActions.setPopUpType('success'))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
        dispatch(alertActions.setPopUpData({ heading: lang.sucess, message: lang.form_submitted_sucessfully }))

        resetForm()
        /* alert('form submited')
        navigate(process.env.REACT_APP_BASE_URL, { replace: true }); */
        setIsLoading(false)
      } else {
        dispatch(alertActions.setPopUpType('error'))
        dispatch(alertActions.setPopUpData({ heading: lang.failed, message: lang.something_went_wrong_please_try_again }))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
        /* alert('something went wrong ,please try again') */
        setIsLoading(false)
      }

    }
  };

  return (
    <React.Fragment>
      {isLoading && <FulloverLoader />}

      <InnerBanner title={lang.vvip_services}></InnerBanner>

      <section className="content_service_main form_bg">
        <div class="container">
          <div className="form_breadcrumbs">
            <ul>
              <li>
                <Link to={process.env.REACT_APP_BASE_URL + 'services'}>{lang.services} </Link>
              </li>
              <li>
                <a className="active" href="">
                  {lang.vvip_services}
                </a>
              </li>
            </ul>
          </div>
          <div className="service_box">
            {serviceDetails && (
              <ServiceBoxLeft serviceDetails={serviceDetails} />
            )}
            <div className="service_box_col_rht">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="service_detail_formbox formstyle">
                  <div className="service_detail_formhd">
                    <h2>{lang.feel_like_contacting_us}</h2>
                    {/* <p>
                    {lang.submit_the_form_below_we_will_get_back_to_you_asap}
                    </p> */}
                  </div>
                  <hr className="form_hd_hr"></hr>
                  {/* {(myProfile && myProfile.isAgentLogin && !tokenData.data.isDefaultUser) &&
                    <UserList
                      register={register}
                      onBehalfId={onBehalfId}
                      setOnBehalfId={setOnBehalfId}
                      errors={errors}
                      control={control}
                      userText={userText}
                      setUserText={setUserText}
                    />
                  } */}
                  <h3><span>{lang.phone}</span> : <span>{lang.contact_us_phone}</span></h3>
                  {/* <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.name}<span>*</span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          {...register("Name", {
                            required: lang.name_is_required,
                            pattern: {
                              value: /[A-Za-z]/,
                              message: lang.please_enter_valid_name,
                            },
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,
                            },

                            value: "",
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"Name"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.email}<span>*</span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          {...register("email", {
                            required: lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: lang.invalid_email_address,
                            },

                            value: "",
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"email"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.contact_number}<span>*</span>
                        </label>
                        <div class="form-group d-flex flex-wrap">
                          <div class="phonecode form_phone_select_arrow position-relative">
                          <select
                              class="form-control"
                              value={mobCode}
                              {...register("phoneCode", {
                                required: {
                                  value: true,
                                  message: lang.phone_code_is_required,
                                },
                                onChange:(e)=>setMobCode(e.target.value),
                                value: mobCode
                              })}
                            >
                              {phoneCode.map((item)=> {
                                return <option value={item}>{item}</option>
                              })}
                              </select>
                              <ErrorMessage
                                errors={errors}
                                name={"phoneCode"}
                                render={({ message }) => (
                                  <span className="error-message">
                                    {message}
                                  </span>
                                )}
                              />
                          </div>
                          <div class="phonenumber">
                            <input
                              type="name"
                              class="form-control"
                              placeholder=" "
                              {...register("mobileno", {
                                required: {
                                  value: true,
                                  message: lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message: lang.please_enter_atleast_six_numbers,
                                },

                                value: "",
                              })}
                            ></input>
                            
                          </div>
						  <ErrorMessage
                              errors={errors}
                              name={"mobileno"}
                              render={({ message }) => (
                                <span className="error-message">{message}</span>
                              )}
                            />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group form_select_arrow">
                        <label>
                          {lang.please_select_the_service}<span>*</span>
                        </label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register("service", {
                            required: {
                              value: true,
                              message: lang.please_select_the_service,
                            },
                            onChange: (e) => {
                              setSelectedService(e.target.value);
                            },
                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="Private Aircraft">{lang.private_aircraft}</option>
                          <option value="High Class Villas">{lang.high_class_villas}</option>
                          <option value="Castle Palces ">{lang.castle_palaces}</option>
                          <option value="Private Island">{lang.private_island}</option>
                          <option value="Luxury Motors & Sailing Yachts">{lang.luxury_motors_sailing_yachts}</option>
                          <option value="Dining & Celebration Arrangements">{lang.dining_celebration_arrangements}</option>
                          <option value="Helicopter Service">{lang.helicopter_service}</option>
                          <option value="Private Guides, Guards and Personal Assistance">
                            {lang.private_guides_guards_personal_assistance}
                          </option>
                          <option value="High-end Stores shopping after working hours">
                          {lang.high_end_stores_shopping_after_working_hours}
                          </option>
                          <option value="Designers & Tailors Appointment">{lang.designers_tailors_appointment}</option>
                          <option value="In-House & In-Room Private Shopping">{lang.in_house_in_room_private_shopping}</option>
                          <option value="Special Jewellery and Leather Factory Visit">
                           {lang.special_jewellery_and_leather_factory_visit}
                          </option>
                          <option value="Package for International Fashion Show">
                            {lang.package_international_fashion_show}
                          </option>
                          <option value="Other">{lang.select_other}</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={"service"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    {selectedService === "Other" && (
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>
                            {lang.if_you_have_selected_other_please_specify}
                          </label>
                          <textarea
                            class="form-control"
                            name=""
                            rows=""
                            cols=""
                            {...register("serviceOther", {
                              required: {
                                value: true,
                                message: lang.please_specify_the_service,
                              },
                            })}
                          ></textarea>
                          <ErrorMessage
                            errors={errors}
                            name={"serviceOther"}
                            render={({ message }) => (
                              <span className="error-message">{message}</span>
                            )}
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group form_select_arrow">
                        <label>
                          {lang.from}<span>*</span>
                        </label>
                        <DatePicker
                          minDate={new Date()}
                          locale={current_culture}
                          dateFormat={"dd/MM/yyyy"}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown={true}
                          selected={fromDate}
                          onChange={(value) => {
                            setFromDate(value);
                            setToDate(value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group form_select_arrow">
                        <label>
                         {lang.to}<span>*</span>
                        </label>
                        <DatePicker
                          minDate={fromDate}
                          locale={current_culture}
                          dateFormat={"dd/MM/yyyy"}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown={true}
                          selected={toDate}
                          onChange={(value) => {
                            setToDate(value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group form_select_arrow DisplayinRmv autocomplete">
                        <label>
                          {lang.country_city}<span>*</span>
                        </label>
                        <Controller
                          name={"city"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                        <Autocomplete
                          getItemValue={(item) =>current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                          items={cityList}
                          renderItem={(item, isHighlighted) => (
                            <div
                              className={
                                isHighlighted
                                  ? " react-autocomplete-inner active "
                                  : "react-autocomplete-inner"
                              }
                            >
                              {current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                            </div>
                          )}
                          {...register("city", {
                            required: {
                              value: true,
                              message: lang.please_select_a_country_city,
                            },
                          })}
                          onChange={(event) => cityRequestHandler(event)}
                          onSelect={(label,item)=>selectCityHandler(label,item,onChange)}
                          value={City}
                        />
                        )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"city"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group form_select_arrow">
                            <label>
                             {lang.no_of_adults}<span>*</span>
                            </label>
                            <select
                              name=""
                              id=""
                              class="form-control"
                              {...register("adultCount", {
                                required: {
                                  value: true,
                                  message: lang.please_select_adult_count,
                                },
                                
                              })}
                            >
                              <option value="">{lang.select}</option>
                              <option >1</option>
                              <option >2</option>
                              <option >3</option>
                              <option >4</option>
                              <option >5</option>
                            </select>
                            <ErrorMessage
                            errors={errors}
                            name={"adultCount"}
                            render={({ message }) => (
                              <span className="error-message">{message}</span>
                            )}
                          />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group form_select_arrow">
                            <label>
                              {lang.no_of_children}<span>*</span>
                            </label>
                            <select
                              name=""
                              id=""
                              class="form-control"
                              {...register("childCount", {
                                required: {
                                  value: true,
                                  message: lang.please_select_children_count,
                                },
                              })}
                            >
                              <option value="">{lang.select}</option>
                              <option >1</option>
                              <option >2</option>
                              <option >3</option>
                              <option >4</option>
                              <option >5</option>
                            </select>
                            <ErrorMessage
                            errors={errors}
                            name={"childCount"}
                            render={({ message }) => (
                              <span className="error-message">{message}</span>
                            )}
                          />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>{lang.additional_information}</label>
                        <textarea
                          class="form-control"
                          name=""
                          rows=""
                          cols=""
                          {...register("additionalInfo")}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <button type="submit" class="form_submit_btn">
                       {lang.submit}
                      </button>
                    </div>
                    {tokenData.data.isDefaultUser && <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <p className="form_note">
                       {lang.note_now_you_are_on_guest_user_please}{" "}
                        <a href="#" onClick={()=>setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}
                      </p>
                    </div>}
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default VVIPServices;
