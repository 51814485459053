import React, { useState, useEffect } from 'react';
import qs from 'qs';
import InnerBanner from '../../components/common/inner_banner';
import { useNavigate } from "react-router-dom";
import PersonalAssistancServiceDetails from "../../assets/images/personalassistance_servicedetail.jpg"
import Moment from "moment";
import { getTimeData } from "../../services/common";

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ServiceBoxLeft from './ServiceBoxLeft';
import { getServiceDetailsById } from '../../scripts/common';
import { postServices, setPhoneCodeList } from '../../services/common';

import { alertActions } from "../../redux/alertSlice";
import FulloverLoader from '../../components/common/FulloverLoader';
import UserList from '../../components/common/UserList';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import DatePicker, { registerLocale } from "react-datepicker";

const serviceId = 26;

function PersonalService({ setShowLoginModal }) {
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
        setValue
    } = useForm();
    const [phoneCode, setPhoneCode] = useState([])
    const [mobileCode, setMobileCode] = useState(process.env.REACT_APP_MOBCODE);
    let lang = useSelector((state) => state.language.data);
    let myProfile = JSON.parse(localStorage.getItem("myProfile"));
    const current_culture = useSelector(state => state.culture.value);


    const [onBehalfId, setOnBehalfId] = useState();
    const [userText, setUserText] = useState('');
    const [walletData, setWalletData] = useState(null)
    const [BusinessBankingServiceDetailsParams, setBusinessBankingServiceDetailsParams] = useState({
        name: '',
        email: '',
        phoneCode: '',
        contact_number: '',
        service: '',
        additionalInformation: ''

    })
    let navigate = useNavigate();
    const tokenData = useSelector((state) => state.tokenInfo);
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false);
    const [serviceDetails, setserviceDetails] = useState();
    const [serviceType, setServiceType] = useState("");
    const [serviceDate, setServiceDate] = useState(new Date());

    useEffect(() => {
        window.scrollTo(0, 0);

        const serviceDetailsData = getServiceDetailsById(serviceId);
        setserviceDetails(serviceDetailsData)
        const fetchPhoneCode = async () => {
            const phoneCodeList = await setPhoneCodeList()

            setPhoneCode(phoneCodeList)
        }

        fetchPhoneCode()
    }, []);
    const onSubmit = async (data) => {
        if (tokenData.data.isDefaultUser) {
            setShowLoginModal(true)
        } else {
            setIsLoading(true)

            let personalAssistance = {
                ServiceID: serviceId,
                OnBehalfId: onBehalfId,
                Data: JSON.stringify({
                    name: data.name,
                    email: data.email,
                    contact_number: data.contact_number,
                    mobile_code: data.mobileCode,
                    select_service: data.select_service,
                    date_and_time_of_service: `${Moment(serviceDate).format(
                        "DD/MM/YYYY"
                    )} ${data.serviceTime}`,
                    select_other_specifically: data.select_other_specifically,
                    additional_information: data.additional_information,
                    // fieldOrder:'name,email,mobile_code,contact_number,select_service,select_other_specifically,additional_information,additional_information'
                })
            }
            const response = await postServices(personalAssistance);
            if (response) {
                dispatch(alertActions.setPopUpType('success'))
                dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: '' }))
                dispatch(alertActions.setPopUpData({ heading: lang.sucess, message: lang.form_submitted_sucessfully }))
                resetForm()
                setIsLoading(false)

            } else {
                dispatch(alertActions.setPopUpType('error'))
                dispatch(alertActions.setPopUpData({ heading: lang.failed, message: lang.something_went_wrong_please_try_again }))
                dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))

                setIsLoading(false)
            }
        }

    }
    const resetForm = () => {
        reset()
        setMobileCode(process.env.REACT_APP_MOBCODE)
        setServiceType("")
        setOnBehalfId("")
        setValue('OnBehalfId', "")
        setUserText('')
        setWalletData(null)

    }
    return (

        <React.Fragment>
            {
                isLoading &&
                <FulloverLoader />
            }
            <InnerBanner
                title={lang.personal_services}
            ></InnerBanner>

            <section className='content_service_main form_bg'>
                <div class='container'>
                    <div className='form_breadcrumbs'>
                        <ul>
                            <li><Link to={process.env.REACT_APP_BASE_URL + 'services'}>{lang.services} </Link></li>
                            <li><a className='active' href=''>{lang.personal_services}</a></li>
                        </ul>
                    </div>
                    <div className='service_box'>
                        {
                            serviceDetails &&
                            <ServiceBoxLeft
                                serviceDetails={serviceDetails}
                            />
                        }
                        <div className='service_box_col_rht'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='service_detail_formbox formstyle'>
                                    <div className='service_detail_formhd'>
                                        <h2>{lang.feel_like_contacting_us}</h2>
                                        <p>{lang.submit_the_form_below_we_will_get_back_to_you_asap}</p>
                                    </div>
                                    <hr className='form_hd_hr'>
                                    </hr>
                                    {(myProfile && myProfile.isAgentLogin && !tokenData.data.isDefaultUser) &&
                                        <UserList
                                            register={register}
                                            onBehalfId={onBehalfId}
                                            setOnBehalfId={setOnBehalfId}
                                            errors={errors}
                                            control={control}
                                            userText={userText}
                                            setUserText={setUserText}
                                            walletData={walletData}
                                            setWalletData={setWalletData}

                                        />
                                    }
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className='form-group'>
                                                <label>{lang.name}<span></span></label>
                                                <input type="text" class="form-control"

                                                    {...register('name', {
                                                        required: lang.name_is_required,
                                                        minLength: {
                                                            value: 2,
                                                            message: lang.please_enter_minimum_two_characters,

                                                        },
                                                        pattern: {
                                                            value: /[A-Za-z]/,
                                                            message: lang.please_enter_valid_name,
                                                        },
                                                    })}
                                                >
                                                </input>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={'name'}
                                                    render={({ message }) => (
                                                        <span className='error-message'>{message}</span>
                                                    )}
                                                />
                                            </div>

                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className='form-group'>
                                                <label>{lang.email}<span></span></label>
                                                <input type="text" class="form-control"

                                                    {...register('email', {
                                                        required: lang.email_is_required,
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: lang.invalid_email_address,
                                                        },


                                                    })}
                                                >
                                                </input>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={'email'}
                                                    render={({ message }) => (
                                                        <span className='error-message'>{message}</span>
                                                    )}
                                                />
                                            </div>

                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className='form-group'>
                                                <label>{lang.contact_number}<span></span></label>
                                                <div class="form-group d-flex flex-wrap">
                                                    <div class="phonecode form_phone_select_arrow position-relative">
                                                        <select class="form-control"
                                                            {...register('mobileCode', {
                                                                required: {
                                                                    value: true,
                                                                    message:
                                                                        lang.phone_code_is_required,
                                                                },
                                                                onChange: (e) => {
                                                                    setMobileCode(e.target.value)
                                                                }, value: mobileCode
                                                            })}
                                                        >
                                                            {phoneCode.map((item, key) => {
                                                                return <option selected={mobileCode == item ? 'selected' : ''} value={item}>{item}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div class="phonenumber">
                                                        <input type="text" class="form-control" placeholder=" "

                                                            {...register('contact_number', {
                                                                required: {
                                                                    value: true,
                                                                    message:
                                                                        lang.phone_number_is_required,
                                                                },
                                                                pattern: {
                                                                    value: /^[0-9]+$/,
                                                                    message: lang.invalid_phone_number,
                                                                },
                                                                maxLength: {
                                                                    value: 15,
                                                                    message:
                                                                        lang.please_enter_no_more_than_fifteen_numbers,
                                                                },
                                                                minLength: {
                                                                    value: 6,
                                                                    message:
                                                                        lang.please_enter_atleast_six_numbers,
                                                                },

                                                                onChange: e => {

                                                                },
                                                            })}
                                                        >
                                                        </input>
                                                    </div>
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={'contact_number'}
                                                        render={({ message }) => (
                                                            <span className='error-message'>{message}</span>
                                                        )}
                                                    />
                                                </div>

                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-12'>
                                            <div className='form-group form_select_arrow'>
                                                <label>{lang.please_select_the_service}<span></span></label>
                                                <select name="" id="" class="form-control"

                                                    {...register('select_service', {
                                                        required: lang.service_type_is_required,
                                                        onChange: (e) => {
                                                            setServiceType(e.target.value)
                                                        }
                                                    })}
                                                >
                                                    <option value="">{lang.select}</option>
                                                    <option value="Towing services">{lang.towing_services}</option>
                                                    <option value="Mechanical repair service">{lang.mechanical_repair_service}</option>
                                                    <option value="Battery boosting service">{lang.battery_boosting_service}</option>
                                                    <option value="Flat tyre service">{lang.flat_tyre_service}</option>
                                                    <option value="Emergency fuel service">{lang.emergency_fuel_service}</option>
                                                    <option value="Lockout Service">{lang.lock_out_service}</option>
                                                    <option value="Off road recovery service">{lang.off_road_recovery_service}</option>
                                                    <option value="Routine Maintenance Services at Dealership">{lang.routie_maintenance_service_at_dealership}</option>
                                                    <option value="Car wash and cleaning services">{lang.car_wash_and_cleaning_services}</option>
                                                    {/* <option value="Scheduling appointments">{lang.select_scheduling_appointments}</option> */}
                                                    {/* <option value="Referrals to vendors">{lang.select_referrals_to_vendors}</option>
                                                    <option value="Meal planning">{lang.select_meal_planning}</option>
                                                    <option value="Personal/Grocery shopping">{lang.select_personal_grocery_shopping}</option>
                                                    <option value="Travel arrangements">{lang.select_travel_arrangements}</option>
                                                    <option value="Holiday planning and coordination">{lang.select_holiday_planning_coordination}</option> */}
                                                    <option value="Other">{lang.select_other}</option>

                                                </select>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={'select_service'}
                                                    render={({ message }) => (
                                                        <span className='error-message'>{message}</span>
                                                    )}
                                                />


                                            </div>
                                        </div>


                                        {serviceType == "Other" &&
                                            <div className='col-lg-12 col-md-12'>
                                                <div className='form-group'>
                                                    <label>{lang.if_you_have_selected_other_please_specify}:<span>*</span></label>
                                                    <textarea class="form-control" name="" rows="" cols=""
                                                        {...register('select_other_specifically', {
                                                            required: lang.service_description_is_required,

                                                        })}
                                                    ></textarea>
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={'select_other_specifically'}
                                                        render={({ message }) => (
                                                            <span className='error-message'>{message}</span>
                                                        )}
                                                    />
                                                </div>


                                            </div>
                                        }
                                        <div className="col-lg-4 col-md-8 col-12">
                                            <div className="form-group">
                                                <label>
                                                    {lang.date_and_time_of_the_service}<span>*</span>
                                                </label>
                                                <DatePicker
                                                    minDate={new Date()}
                                                    locale={current_culture}
                                                    dateFormat={"dd/MM/yyyy"}
                                                    yearDropdownItemNumber={100}
                                                    scrollableYearDropdown={true}
                                                    showYearDropdown={true}
                                                    selected={serviceDate}
                                                    onChange={(value) => {
                                                        setServiceDate(new Date(value));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-12">
                                            <div className="form-group">
                                                <label> </label>

                                                <select
                                                    name=""
                                                    id=""
                                                    class="form-control"
                                                    {...register("serviceTime")}
                                                >
                                                    {getTimeData.map((item) => {
                                                        return <option value={item}>{item}</option>;
                                                    })}
                                                </select>

                                            </div>
                                        </div>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className='form-group'>
                                                <label>{lang.additional_information}</label>
                                                <textarea class="form-control" name="" rows="" cols=""
                                                    {...register('additional_information', {

                                                    })}
                                                ></textarea>
                                            </div>
                                        </div>

                                        <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                                            <button type="submit" class="form_submit_btn">{lang.submit}</button>
                                        </div>
                                        {
                                            tokenData.data.isDefaultUser &&
                                            <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                                                <p className='form_note'>{lang.note_now_you_are_on_guest_user_please} <a href='#' onClick={() => setShowLoginModal(true)}>{lang.login}</a>{lang.to_submit_your_enquiry}</p>
                                            </div>
                                        }



                                    </div>




                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section >


        </React.Fragment >

    )
}
export default PersonalService;