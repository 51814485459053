import React, { useState, useEffect } from "react";

import InnerBanner from "../../components/common/inner_banner";

import RestaurantReservationServiceDetails from "../../assets/images/restaurant_reservation_servicedetail.jpg";

import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { getServiceDetailsById } from "../../scripts/common";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import ServiceBoxLeft from "./ServiceBoxLeft";
import { useNavigate } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Autocomplete from "react-autocomplete";
import { getTimeData, postServices } from "../../services/common";
import FulloverLoader from "../../components/common/FulloverLoader";
import { setPhoneCodeList, getCountryOrCity } from "../../services/common";
import UserList from '../../components/common/UserList';
import ar from "date-fns/locale/ar"; // the locale you want
import {  Link} from 'react-router-dom';



const serviceId = 17;
function SoftSkillTraining({setShowLoginModal}) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm();
  const [
    BusinessBankingServiceDetailsParams,
    setBusinessBankingServiceDetailsParams,
  ] = useState({
    name: "",
    email: "",
    phoneCode: "",
    contact_number: "",
    service: "",
    additionalInformation: "",
  });

  const tokenData = useSelector((state) => state.tokenInfo);
  const current_culture = useSelector(state => state.culture.value);
  registerLocale("ar", ar);
  let lang = useSelector((state) => state.language.data);
  let myProfile = JSON.parse(localStorage.getItem("myProfile"));
  
  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState('');
  const [walletData, setWalletData] = useState(null)
  const [date, setDate] = useState(new Date());
  const [skillTopic, setSkillTopic] = useState("");
  const [phoneCode, setPhoneCode] = useState([])
  const [mobCode, setMobCode] = useState(process.env.REACT_APP_MOBCODE)
  const [isLoading, setIsLoading] = useState(false)
  const [location, setLocation] = useState("")
  const [locationLabel, setLocationLabel] = useState("")
  const [locationList, setLocationList] = useState([])
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [serviceDetails, setserviceDetails] = useState();
  useEffect(() => {
    window.scrollTo(0,0)
    const serviceDetailsData = getServiceDetailsById(serviceId);
    setserviceDetails(serviceDetailsData);

    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList()

      setPhoneCode(phoneCodeList)
    } 

    fetchPhoneCode()
  }, []);

  const resetForm=()=>{
    reset()
    setMobCode(process.env.REACT_APP_MOBCODE)
    setDate(new Date())
    setLocation("")
    setLocationLabel("")
    setLocationList([])
    setSkillTopic("")
    setOnBehalfId("")
    setValue('OnBehalfId',"")
    setUserText('')
    setWalletData(null)

  }

  const locationRequestHandler = async (e) => {
    setLocation(e.target.value);
    

    const locationResponse = await getCountryOrCity(e.target.value)

    if (locationResponse) {
      console.log("deaprtCityList",locationResponse)

    setLocationList(locationResponse)
    }

    
  };

  const selectLocationHandler = (value,item,onChange) => {
    setLocation(value)
    setLocationLabel(item._source.displayname)
    onChange(value)

  }


  const onSubmit = async (data) => {

    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true)
    } else {
    
    setIsLoading(true)

    let softSkillTraining = {
      ServiceID: serviceId,
      /* OnBehalfId: onBehalfId, */
      Data: JSON.stringify({
        name: data.name,
        email: data.email,
        mobile_code: mobCode,
        contact_number: data.contact_number,
        time_of_requested_training: data.timeRequested,
        soft_skills_topic: data.skill,
        select_other_specifically: data.skillOther,
        date_of_training: moment(date).format(
          "DD/MM/YYYY"
        ),
        number_of_students: data.studentsNo,
        location_of_training: locationLabel,
      }),
    };

    if(onBehalfId) {
      softSkillTraining["OnBehalfId"] = onBehalfId
    }

    const response = await postServices(softSkillTraining);

      
      if (response) {
        dispatch(alertActions.setPopUpType('success'))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
        dispatch(alertActions.setPopUpData({ heading: lang.sucess, message: lang.form_submitted_sucessfully }))

        resetForm()
        /* alert('form submited')
        navigate(process.env.REACT_APP_BASE_URL, { replace: true }); */
        setIsLoading(false)
      } else {
        dispatch(alertActions.setPopUpType('error'))
        dispatch(alertActions.setPopUpData({ heading: lang.failed, message: lang.something_went_wrong_please_try_again }))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
        /* alert('something went wrong ,please try again') */
        setIsLoading(false)
      }
   
  }
  };

  return (
    <React.Fragment>
      {isLoading && <FulloverLoader/>}

      <InnerBanner title={lang.soft_skill_training_programs}></InnerBanner>
      <section className="content_service_main form_bg">
        <div class="container">
          <div className="form_breadcrumbs">
            <ul>
              <li>
              <Link to={process.env.REACT_APP_BASE_URL+'services'}>{lang.services} </Link>
              </li>
              <li>
                <a className="active" href="">
                 {lang.soft_skill_training_programs}
                </a>
              </li>
            </ul>
          </div>
          <div className="service_box">
            {" "}
            {serviceDetails && (
              <ServiceBoxLeft serviceDetails={serviceDetails} />
            )}
            <div className="service_box_col_rht">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="service_detail_formbox formstyle">
                  <div className="service_detail_formhd">
                    <h2>{lang.feel_like_contacting_us}</h2>
                    <p>
                      {lang.submit_the_form_below_we_will_get_back_to_you_asap}
                    </p>
                  </div>
                  <hr className="form_hd_hr"></hr>
                  {(myProfile && myProfile.isAgentLogin && ! tokenData.data.isDefaultUser) &&
                    <UserList
                      register={register}
                      onBehalfId={onBehalfId}
                      setOnBehalfId={setOnBehalfId}
                      errors={errors}
                      control={control}
                      userText={userText}
                      setUserText={setUserText}
                      walletData={walletData}
                      setWalletData={setWalletData}

                    />
                  }
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.name}<span></span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          {...register("name", {
                            required: lang.name_is_required,
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,
                            },
                            pattern: {
                              value: /[A-Za-z]/,
                              message: lang.please_enter_valid_name,
                            },
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"name"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.email}<span></span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          {...register("email", {
                            required: lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: lang.invalid_email_address,
                            },
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"email"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.contact_number}<span></span>
                        </label>
                        <div class="form-group d-flex flex-wrap">
                          <div class="phonecode form_phone_select_arrow position-relative">
                          <select
                              class="form-control"
                              value={mobCode}
                              {...register("phoneCode", {
                                required: {
                                  value: true,
                                  message: lang.phone_code_is_required,
                                },
                                onChange:(e)=>setMobCode(e.target.value),
                                value: mobCode
                              })}
                            >
                              {phoneCode.map((item)=> {
                                return <option value={item}>{item}</option>
                              })}
                              </select>
                              <ErrorMessage
                                errors={errors}
                                name={"phoneCode"}
                                render={({ message }) => (
                                  <span className="error-message">
                                    {message}
                                  </span>
                                )}
                              />
                          </div>
                          <div class="phonenumber">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=" "
                              {...register("contact_number", {
                                required: {
                                  value: true,
                                  message: lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message: lang.please_enter_atleast_six_numbers,
                                },

                                onChange: (e) => {},
                              })}
                            ></input>
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={"contact_number"}
                            render={({ message }) => (
                              <span className="error-message">{message}</span>
                            )}
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group form_select_arrow">
                        <label>
                         {lang.time_of_requested_training}<span></span>
                        </label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register("timeRequested", {
                            required: {
                              value: true,
                              message: lang.please_select_time,
                            },
                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="1 hours">1 {lang.hours}</option>
                          <option value="2 hours">2 {lang.hours}</option>
                          <option value="3 hours">3 {lang.hours}</option>
                          <option value="4 hours">4 {lang.hours}</option>
                          <option value="5 hours">5 {lang.hours}</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={"timeRequested"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group form_select_arrow">
                        <label>
                        {lang.soft_skills_topic}<span></span>
                        </label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register("skill", {
                            required: {
                              value: true,
                              message: lang.please_select_skills_topic,
                            },
                            onChange: (e) => {
                              setSkillTopic(e.target.value);
                            },
                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="Delivering a Winning Presentation">{lang.delivering_presentation}</option>
                          <option value="Introduction to Hospitality Management">{lang.introduction_hospitality}</option>
                          <option value="Time Management Course">{lang.time_management}</option>
                          <option value="Handling Stress Course">{lang.handling_stress}</option>
                          <option value="Communication Skills Course">{lang.communication_skills}</option>
                          <option value="Other">{lang.select_other}</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={"skill"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    {skillTopic === "Other" && (
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>
                           {lang.if_you_have_selected_other_please_specify}
                          </label>
                          <textarea
                            class="form-control"
                            name=""
                            rows=""
                            cols=""
                            {...register("skillOther", {
                              required: {
                                value: true,
                                message: lang.please_specify_the_skills,
                              },
                            })}
                          ></textarea>
                          <ErrorMessage
                            errors={errors}
                            name={"skillOther"}
                            render={({ message }) => (
                              <span className="error-message">{message}</span>
                            )}
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>{lang.date_of_training}</label>
                        <DatePicker
                          minDate={new Date()}
                          locale={current_culture}
                          dateFormat={"dd/MM/yyyy"}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown={true}
                          selected={date}
                          onChange={(value) => {
                            setDate(value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group form_select_arrow">
                        <label>
                         {lang.no_of_student}<span></span>
                        </label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register("studentsNo", {
                            required: {
                              value: true,
                              message: lang.please_select_no_of_student,
                            },
                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                        <ErrorMessage
                            errors={errors}
                            name={"studentsNo"}
                            render={({ message }) => (
                              <span className="error-message">{message}</span>
                            )}
                          />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group form_select_arrow DisplayinRmv autocomplete">
                        <label>
                         {lang.location_of_training}<span></span>
                        </label>
                        <Controller
                          name={"location"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                        <Autocomplete
                          getItemValue={(item) =>  current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                          items={locationList}
                          renderItem={(item, isHighlighted) => (
                            <div
                              className={
                                isHighlighted
                                  ? " react-autocomplete-inner active "
                                  : "react-autocomplete-inner"
                              }
                            >
                              { current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                            </div>
                          )}
                          {...register("location", {
                            required: {
                              value: true,
                              message: lang.please_select_a_location,
                            },
                          })}
                          onChange={(event) => locationRequestHandler(event)}
                          onSelect={(label,item)=>selectLocationHandler(label,item,onChange)}
                          value={location}
                        />
                        )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"location"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <button type="submit" class="form_submit_btn">
                       {lang.submit}
                      </button>
                    </div>
                    {tokenData.data.isDefaultUser && <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <p className="form_note">
                       {lang.note_now_you_are_on_guest_user_please}{" "}
                        <a href="#" onClick={()=>setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}
                      </p>
                    </div>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default SoftSkillTraining;
