import React, { useState,useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "@hookform/error-message";
import Moment from "moment";
import Autocomplete from "react-autocomplete";
import { useNavigate } from "react-router-dom";
import ar from "date-fns/locale/ar"; // the locale you want
import {  Link} from 'react-router-dom';


import InnerBanner from "../../components/common/inner_banner";
import { getTimeData } from "../../services/common";
import { getServiceDetailsById } from "../../scripts/common";
import ServiceBoxLeft from "./ServiceBoxLeft";
import { postServices } from "../../services/common";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import UserList from '../../components/common/UserList';
import { setPhoneCodeList, getAirportList, getCountryOrCity } from "../../services/common";

import GlobalConciergeServiceDetail from "../../assets/images/global_concierge_service.jpg";
import FulloverLoader from "../../components/common/FulloverLoader";

function GlobalConciergeService({setShowLoginModal}) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm();

  const current_culture = useSelector(state => state.culture.value);
  registerLocale("ar", ar);

  const tokenData = useSelector((state) => state.tokenInfo);
  let lang = useSelector((state) => state.language.data);
  let myProfile = JSON.parse(localStorage.getItem("myProfile"));
  
  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState('');
  const [walletData, setWalletData] = useState(null)
  const [airportList, setAirportList] = useState([]);
  const [airport, setAirport] = useState("");
  const [airportLabel, setAirportLabel] = useState("")
  const [dropoffLocation, setDropoffLocation] = useState("")
  const [dropoffLocationList, setDropoffLocationList] = useState([])
  const [dropoffLocationLabel, setDropoffLocationLabel] = useState("")
  const [serviceDate, setServiceDate] = useState(new Date());
  const [selectedService, setSelectedService] = useState("")
  const [pickupDate, setPickupDate] = useState(new Date())
  const [serviceDetails, setServiceDetails] = useState();
  const [serviceId, setServiceId] = useState(6);
  const [phoneCode, setPhoneCode] = useState([])
  const [mobCode, setMobCode] = useState(process.env.REACT_APP_MOBCODE)
  const [isLoading, setIsLoading] = useState(false)
  const [City, setCity] = useState("")
  const [cityList, setCityList] = useState([])
  const [cityLabel, setCityLabel] = useState("")
  const navigate= useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {

    window.scrollTo(0,0)
    const serviceDetailsData = getServiceDetailsById(serviceId);
    setServiceDetails(serviceDetailsData);

    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList()

      setPhoneCode(phoneCodeList)
    } 

    fetchPhoneCode()

  }, []);

  const resetForm=()=>{
    reset()
    setMobCode(process.env.REACT_APP_MOBCODE)
    setServiceDate(new Date())
    setCity("")
    setCityLabel("")
    setCityList([])
    setSelectedService("")
    setAirport("")
    setAirportLabel("")
    setAirportList([])
    setDropoffLocation("")
    setDropoffLocationLabel("")
    setDropoffLocationList([])
    setPickupDate(new Date())
    setOnBehalfId("")
    setValue('OnBehalfId',"")
    setValue("service","")
    setUserText('')
    setWalletData(null)
  }


  const cityRequestHandler = async (e) => {
    setCity(e.target.value);

    const cityResponse = await getCountryOrCity(e.target.value)

    if (cityResponse) {

    setCityList(cityResponse)
    }
  };

  const selectCityHandler = (value,item,onChange) => {
    setCity(value)
    setCityLabel(item._source.displayname)
    onChange(value)

  }

  const airportRequestHandler = async (e) => {
    setAirport(e.target.value);
    let airportList = await getAirportList(e.target.value);
    

    if (airportList) {
      let airPortList = [];
      airportList.forEach((item) => {
        let { city, name, countryname, code, isallairport } = item._source;

        var label = "";
        if (current_culture == "ar") {
          label = item._source.arabiccity + "-" + item._source.arabicname + "-" + item._source.arabiccountryname + "(" + code + ")";
        } else {
          label = city + "-" + name + "-" + countryname + "(" + code + ")";
        }
        airPortList.push({
          code: code,
          label: label,
          city: city,
          isallairport: isallairport,
          name: name,
          countryname:countryname
        });
      });
      setAirportList(airPortList);
    }
  };

  const selectAirportHandler = (label,item,onChange) => {

    setAirport(label)
    let { city, name, countryname, code, isallairport } = item;
    setAirportLabel(city + "-" + name + "-" + countryname + "(" + code + ")")
    onChange(label)
    
  }

  const dropoffLocationRequestHandler = async (e) => {
    setDropoffLocation(e.target.value);


    const locationResponse = await getCountryOrCity(e.target.value)

    if (locationResponse) {


      setDropoffLocationList(locationResponse)
    }


  };

  const selectDropoffLocationHandler = (value,item, onChange) => {
    setDropoffLocation(value)
    setDropoffLocationLabel(item._source.displayname)
    onChange(value)

  }

  const onSubmit = async (data) => {

    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true)
    } else {

    setIsLoading(true)
    console.log("data", data);

    let globalConciergeParams= {
      ServiceID: serviceId,
      /* OnBehalfId: onBehalfId, */
      Data: JSON.stringify({
        name: data.Name,
        email: data.email,
        mobile_code: mobCode,
        contact_number: data.mobileno,
        country_or_city: cityLabel,
        please_select_the_service: data.service,
        airport: airportLabel,
        date: Moment(serviceDate).format("DD/MM/YYYY"),
        flight_no_with_airline_code:data.FlightNo,
        time: data.arrDepartTime,
        no_of_passengers:data.passengerCount,
        drop_of_location_or_address: dropoffLocationLabel,
        return_pickup_date_time:data.service == "Airport Transfers" ? (`${Moment(pickupDate).format(
          "DD/MM/YYYY"
        )} ${data.pickUpTime}`) : "",
        special_requests:data.specialRequest,
        additional_information: data.additionalInfo,
        //fieldOrder:'name,email,mobile_code,contact_number,country_or_city,please_select_the_service,airport,date,flight_no_with_airline_code,time,additional_information'
      })
    }
    if(onBehalfId) {
      globalConciergeParams["OnBehalfId"] = onBehalfId
    }
    console.log("globalConciergeParams",globalConciergeParams);
    const response = await postServices(globalConciergeParams);
    
      
      if (response) {
        dispatch(alertActions.setPopUpType('success'))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
        dispatch(alertActions.setPopUpData({ heading: lang.sucess, message: lang.form_submitted_sucessfully }))

        /* alert('form submited')
        navigate(process.env.REACT_APP_BASE_URL, { replace: true }); */
        setIsLoading(false)
        resetForm()
      } else {
        dispatch(alertActions.setPopUpType('error'))
        dispatch(alertActions.setPopUpData({ heading: lang.failed, message: lang.something_went_wrong_please_try_again }))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
        /* alert('something went wrong ,please try again') */
        setIsLoading(false)
      }
      
  }
  };

  return (
    <React.Fragment>
      {isLoading && <FulloverLoader/>}

      <InnerBanner title={lang.global_concierge_service}></InnerBanner>

      <section className="content_service_main form_bg">
        <div class="container">
          <div className="form_breadcrumbs">
            <ul>
              <li>
              <Link to={process.env.REACT_APP_BASE_URL+'services'}>{lang.services} </Link>
              </li>
              <li>
                <a className="active" href="">
                  {lang.global_concierge_service}
                </a>
              </li>
            </ul>
          </div>
          <div className="service_box">
          {serviceDetails && (
              <ServiceBoxLeft serviceDetails={serviceDetails} />
            )}
            <div className="service_box_col_rht">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="service_detail_formbox formstyle">
                  <div className="service_detail_formhd">
                    <h2>{lang.feel_like_contacting_us}</h2>
                    <p>
                      {lang.submit_the_form_below_we_will_get_back_to_you_asap}
                    </p>
                  </div>
                  <hr className="form_hd_hr"></hr>

                  {(myProfile && myProfile.isAgentLogin && ! tokenData.data.isDefaultUser) &&
                    <UserList
                      register={register}
                      onBehalfId={onBehalfId}
                      setOnBehalfId={setOnBehalfId}
                      errors={errors}
                      control={control}
                      userText={userText}
                      setUserText={setUserText}
                      walletData={walletData}
                      setWalletData={setWalletData}
                    />
                  }
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>
                          {lang.name}<span>*</span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          {...register("Name", {
                            required: lang.name_is_required,
                            pattern: {
                              value: /[A-Za-z]/,
                              message: lang.please_enter_valid_name,
                            },
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,

                            },

                            value: "",
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"Name"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>
                          {lang.email}<span>*</span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          {...register("email", {
                            required: lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: lang.invalid_email_address,
                            },

                            value: "",
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"email"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>
                          {lang.contact_number}<span>*</span>
                        </label>
                        <div class="form-group d-flex flex-wrap">
                          <div class="phonecode form_phone_select_arrow position-relative">
                            <select
                              class="form-control"
                              value={mobCode}
                              {...register("phoneCode", {
                                required: {
                                  value: true,
                                  message: lang.phone_code_is_required,
                                },
                                onChange:(e)=>setMobCode(e.target.value),
                                value: mobCode

                              })}
                            >
                              
                              {phoneCode.map((item)=> {
                                return <option value={item}>{item}</option>
                              })}
                            </select>
                            <ErrorMessage
                                errors={errors}
                                name={"phoneCode"}
                                render={({ message }) => (
                                  <span className="error-message">
                                    {message}
                                  </span>
                                )}
                              />
                          </div>
                          <div class="phonenumber">
                            <input
                              type="name"
                              class="form-control"
                              placeholder=" "
                              {...register("mobileno", {
                                required: {
                                  value: true,
                                  message: lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message: lang.please_enter_atleast_six_numbers,
                                },

                                value: "",
                              })}
                            ></input>

                          </div>
                          <ErrorMessage
                              errors={errors}
                              name={"mobileno"}
                              render={({ message }) => (
                                <span className="error-message">{message}</span>
                              )}
                            />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group form_select_arrow DisplayinRmv autocomplete">
                        <label>
                          {lang.country_or_city}<span>*</span>
                        </label>
                        <Controller
                          name={"city"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                        <Autocomplete
                          getItemValue={(item) => current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                          items={cityList}
                          renderItem={(item, isHighlighted) => (
                            <div
                              className={
                                isHighlighted
                                  ? " react-autocomplete-inner active "
                                  : "react-autocomplete-inner"
                              }
                            >
                              {current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                            </div>
                          )}
                          {...register("city", {
                            required: {
                              value: true,
                              message: lang.please_select_a_country_city,
                            },
                          })}
                          onChange={(event) => cityRequestHandler(event)}
                          onSelect={(label,item)=>selectCityHandler(label,item,onChange)}
                          value={City}
                        />
                        )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"city"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group form_select_arrow ">
                        <label>
                          {lang.please_select_the_service}<span>*</span>
                        </label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          value={selectedService}
                          {...register("service", {
                            required: {
                              value: true,
                              message: lang.please_select_the_service,
                            },
                            onChange: (event)=> setSelectedService(event.target.value)
                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="Meet and Assist Service">{lang.meet_and_assist_service}</option>
                          <option value="Airport Transfers">{lang.airport_transfers}</option>

                          {/* <option >VIP Assistance Service</option>
                          <option >VIP Terminal Service</option>
                          <option >Porter Service</option>
                          <option >Inter-Terminal Car Transfer Service</option>
                          <option >Lounge Facilitation Service</option>
                          <option >Fast Track Service</option>
                          <option >Senior Citizen Assist Service</option>
                          <option >Translator Service</option>
                          <option >Students and Unaccopanied Minor Service</option>
                          <option >Other Services</option> */}
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={"service"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group form_select_arrow DisplayinRmv autocomplete">
                        <label>
                        {lang.select_airport}<span>*</span>
                        </label>
                        <Controller name={"airport"} control={control} render={({field: {onChange, value, name}})=> (
                          <Autocomplete
                          getItemValue={(item) => item.label}
                          items={airportList}
                          renderItem={(item, isHighlighted) => (
                            <div
                              className={
                                isHighlighted
                                  ? " react-autocomplete-inner active "
                                  : "react-autocomplete-inner"
                              }
                            >
                              {item.label}
                            </div>
                          )}
                          {...register("airport", {
                            required: {
                              value: true,
                              message: lang.please_select_an_airport,
                            },
                          })}
                          onChange={airportRequestHandler}
                          value={airport}
                          onSelect={(label,item) => selectAirportHandler(label,item,onChange)}
                        />
                        )}/>
                         <ErrorMessage
                          errors={errors}
                          name={"airport"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                       
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <div className="form-group">
                        <label>
                          {lang.date_of_service}<span>*</span>
                        </label>
                        <DatePicker
                          minDate={new Date()}
                          locale={current_culture}
                          selected={serviceDate}
                          dateFormat={"dd/MM/yyyy"}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown={true}
                          onChange={(value) => {
                            setServiceDate(new Date(value));
                            setPickupDate(new Date(value))
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-7">
                      <div className="form-group">
                        <label>
                          {lang.flight_no_with_airline_code}<span>*</span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          placeholder="FLY55235"
                          {...register("FlightNo", {
                            required: {
                              value: true,
                              message: lang.flight_no_required,
                            },
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"FlightNo"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>{lang.estimated_time_for_arrival_departure}</label>
                        
                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register("arrDepartTime")}
                        >
                          {getTimeData.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                        
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group form_select_arrow">
                        <label>
                          {lang.number_of_passengers}<span>*</span>
                        </label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register("passengerCount", {
                            required: {
                              value: true,
                              message:lang.please_select_passenger_count,
                            },
                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={"passengerCount"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    {selectedService === "Airport Transfers" && <>
                      <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label className="mt-4">
                          {lang.please_provide_below_information_if_airport}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group DisplayinRmv autocomplete">
                        <label>
                          {lang.drop_off_location_address}<span>*</span>
                        </label>
                       {/*  <input
                          type="name"
                          class="form-control"
                          placeholder=""
                          {...register("dropOff", {
                            required: {
                              value: true,
                              message: lang.drop_off_location_required,
                            },
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"dropOff"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        /> */}
                        <Controller
                          name={"dropOff"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <Autocomplete
                              getItemValue={(item) => current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                              items={dropoffLocationList}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  className={
                                    isHighlighted
                                      ? " react-autocomplete-inner active "
                                      : "react-autocomplete-inner"
                                  }
                                >
                                  {current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                                </div>
                              )}
                              {...register("dropOff", {
                                required: {
                                  value: true,
                                  message: lang.please_select_a_location,
                                },
                              })}
                              onChange={(event) => dropoffLocationRequestHandler(event)}
                              onSelect={(label,item) => selectDropoffLocationHandler(label,item, onChange)}
                              value={dropoffLocation}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"dropOff"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.return_pick_up_date_time} <span>*</span>
                        </label>
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                          <DatePicker
                          minDate={serviceDate}
                          locale={current_culture}
                          dateFormat={"dd/MM/yyyy"}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown={true}
                          selected={pickupDate}
                          onChange={(value) => {
                            setPickupDate(new Date(value));
                          }}
                        />
                          </div>
                          <div className="col-lg-6 col-md-6">
                          <select name="" id="" class="form-control"
                           {...register("pickUpTime")}
                        >
                          {getTimeData.map((item) => {
                            return (
                              <option value={item}>{item}</option>
                            )
                          })}
                        </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    </>}
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>{lang.additional_information}</label>
                        <textarea
                          class="form-control"
                          name=""
                          rows=""
                          cols=""
                          {...register("additionalInfo")}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>{lang.special_request}</label>
                        <textarea
                          class="form-control"
                          name=""
                          rows=""
                          cols=""
                          {...register("specialRequest")}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <button type="submit" class="form_submit_btn">
                        {lang.submit}
                      </button>
                    </div>
                    {tokenData.data.isDefaultUser && <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <p className="form_note">
                       {lang.note_now_you_are_on_guest_user_please}{" "}
                        <a href="#" onClick={()=>setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}
                      </p>
                    </div>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default GlobalConciergeService;
