import React, { useEffect } from "react";
import Thawte from '../../assets/images/thawte.png';
import GuarnteeImg from '../../assets/images/guarantee.png';
import SecuredImg from '../../assets/images/secured-img.png';
import Iata from '../../assets/images/iata-img.png';
import DGCA from '../../assets/images/dgca-img.png';
import Fb from '../../assets/images/fb-icon.png';
import Twit from '../../assets/images/twitter-icon.png';
import Insta from '../../assets/images/insta-icon.png';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


export default function Footer(){

    let lang = useSelector((state) => state.language.data);
    
    return(
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="footer-secure">
                            <h4>{lang.personal_details_are_secure}</h4>
                            <div className="secure-img">
                                <img src={Thawte} />
                                <img src={SecuredImg} />
                                <img src={GuarnteeImg} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        {/* <div className="footer-licence">
                            <img src={Iata} />
                            <img src={DGCA} />
                        </div> */}
                    </div>
                    <div className="col-md-4">
                        <div className="footer-contact">
                            <div className="contact-inner-box">
                                <h4>{lang.phone}</h4>
                                <p><span>+965</span> <span>22087425</span></p>
                                <p><span>+965</span> <span>1888125</span></p>
                            </div>
                            <div className="contact-inner-box">
                                <h4>{lang.email}</h4>
                                <p>{lang.contact_us_email}</p>
                            </div>
                            <div className="contact-inner-box">
                                <h4>{lang.our_address}</h4>
                                <p>{lang.contact_us_address}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <ul className="footer-links">
                            <li><Link to={process.env.REACT_APP_BASE_URL + "About-Us"}>{lang.about_us}</Link></li>
                            <li><Link to={process.env.REACT_APP_BASE_URL + "Contact-Us"}>{lang.contact_us}</Link></li>
                            <li><Link to={process.env.REACT_APP_BASE_URL + "Privacy-Policy"}>{lang.privacy_policy}</Link></li>
                            <li><Link to={process.env.REACT_APP_BASE_URL + "Terms-And-Conditions"}>{lang.terms_conditions}</Link></li>
                            {/* <li><Link to={process.env.REACT_APP_BASE_URL + "Site-Map"}>{lang.site_map}</Link></li> */}
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="footer-social">
                            <li>
                                <a href={lang.facebook_link} target="_blank">
                                    <img src={Fb} />
                                </a>
                            </li>
                            <li>
                                <a href={lang.twitter_link} target="_blank">
                                    <img src={Twit} />
                                </a>
                            </li>
                            <li>
                                <a href={lang.instagram_link} target="_blank">
                                    <img src={Insta} />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p> {lang.footer_rights}</p>
            </div>
        </footer>
    )
}