import moment from "moment";
import axios from "axios";


import { getTokenApiRequest } from "./getTokenApiRequest";


export const getTimeData = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

export function postServices(params) {
  const apiRequestObj = getTokenApiRequest();
  return apiRequestObj({
    url: "ConciergeService/PostServices",
    method: "post",
    data: params,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      console.log(response);

      if (response.data.data == "Enquiry_Submitted") {
        return true;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
      return false;
    });
}

export function getMyBookings() {
  const apiRequestObj = getTokenApiRequest();
  return apiRequestObj({
    url: "MyBooking/GetBookings?culture=" + localStorage.getItem("culture"),
    method: "get",
  })
    .then((response) => {
      if (response.data.error == null) {
        return response.data.data.bookings;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
      return false;
    });
}

export function getEnquiryDetails(enqId) {



  const apiRequestObj = getTokenApiRequest();
  return apiRequestObj({
    url: "ConciergeService/GetEnquiryDetailsMyBookingById?EnquiryId=" + enqId,
    method: "get",
  })
    .then((response) => {
  
      if (response.data.error == null) {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
      return false;
    });
}

export function getWalletData(enqId) {



  const apiRequestObj = getTokenApiRequest();
  return apiRequestObj({
    url: "AccountService/GetWalletHistory",
    method: "get",
  })
    .then((response) => {
  
      if (response.data) {
        return response.data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
      return false;
    });
}

export function getCityList(part, culture) {
  var c = 0; //English
  if (culture == "ar") {
    c = 1;
  }
  return fetch(
    process.env.REACT_APP_ELASTIC_URL +
      "Data/GetCity?value=" +
      part +
      "&culture=" +
      c +
      "&size=15"
  )
    .then((res) => res.json())
    .then(
      (jsonResponse) => {
        if (jsonResponse.hits.hits.length > 0) {
          return jsonResponse;
        } else {
          return false;
        }
      },
      (error) => {}
    );
}
export function getAirportList(searchKey) {
  return fetch(
    process.env.REACT_APP_ELASTIC_URL +
      "Data/GetAirports?value=" +
      searchKey +
      "&culture=1&size=10"
  )
    .then((response) => response.json())
    .then(
      (result) => {
        if (result.hits.hits.length > 0) {
          return result.hits.hits;
        } else {
          return false;
        }
      },
      (error) => {
        return false;
      }
    );
}

export function loginRequest(params) {
  return axios
    .post(process.env.REACT_APP_API_URL + "token", params)
    .then((response) => {
      if (response.data) {
        if (response.data.error == null) {
          const now = new Date();
          localStorage.setItem(
            "token",
            JSON.stringify({
              ...response.data.data,
              expiryTime: response.data.data.expiry,
            })
          );
        } else {
          return "invalid";
        }
      }
      return response.data;
    })
    .catch((response) => {
      if (response.response.data.error == "invalid_grant") return "invalid";
      else return "error";
    });
}
export function registerRequest(params) {
  let apiRequestObj = getTokenApiRequest();

  return apiRequestObj({
    url: "AccountService/Register",
    method: "post",
    data: params,
  })
    .then((response) => {
      if (response.data.error == "User_Already_Exists") {
        return "user_exists";
      } else if (response.data.data == "Register_Success") {
        return "success";
      } else if(response.data.error == "incorrect_code"){
        return "incorrect_code"
      } else if(response.data.error == "already_used"){
        return "already_used"
      } else if(response.data.error == "KFHSpecialCount_Exeeced"){
        return "special_count_exceed"
      }
       else {
        return "error";
      }
    })
    .catch((response) => {
      return "error";
    });
  // return axios.post(process.env.REACT_APP_API_URL + '', params
  // ).then((response) => {
  //
  //     // if (response.data) {
  //     //     const now = new Date()
  //     //     localStorage.setItem('token', JSON.stringify({
  //     //         ...response.data,
  //     //         expiryTime: response.data.expiry,
  //     //     }));
  //     // }
  //     return response.data;
  // }).catch((response) => {

  // });
}

export function getCountryOrCity(searchKey) {
  return fetch(
    process.env.REACT_APP_ELASTIC_URL +
      "Data/GetHotelCity?value=" +
      searchKey +
      "&size=10"
  )
    .then((response) => response.json())
    .then(
      (result) => {
        if (result.hits.hits.length > 0) {
          return result.hits.hits;
        } else {
          return false;
        }
      },
      (error) => {
        return false;
      }
    );
}

export function getCountryOrCityorAirport(searchKey) {
  return fetch(
    process.env.REACT_APP_ELASTIC_URL +
      "Data/GetMultiDestinationData?value=" +
      searchKey +
      "&size=10"
  )
    .then((response) => response.json())
    .then(
      (result) => {
        console.log(result)
        if (result.hits.hits.length > 0) {
          return result.hits.hits;
        } else {
          return false;
        }
      },
      (error) => {
        return false;
      }
    );
}

export function getSports(searchKey, current_culture) {
  return fetch(
    process.env.REACT_APP_ELASTIC_URL +
      "Data/GetSportsEntity?value=" +
      searchKey +
      "&size=25&culture=" +
      current_culture
  )
    .then((response) => response.json())
    .then(
      (result) => {
        if (result.hits.hits.length > 0) {
          return result.hits.hits;
        } else {
          return false;
        }
      },
      (error) => {
        return false;
      }
    );
}

export function getAirline(searchKey) {
  return fetch(
    process.env.REACT_APP_ELASTIC_URL +
      "Data/GetAirline?value=" +
      searchKey +
      "&culture=1&size=10"
  )
    .then((response) => response.json())
    .then(
      (result) => {
        if (result.hits.hits.length > 0) {
          return result.hits.hits;
        } else {
          return false;
        }
      },
      (error) => {
        return false;
      }
    );
}

export function getPhoneCode(searchKey) {
  return fetch(
    process.env.REACT_APP_ELASTIC_URL +
      "Data/GetMobileCountry?value=&culture=1&size=300"
  )
    .then((response) => response.json())
    .then(
      (result) => {
        if (result.hits.hits.length > 0) {
          return result.hits.hits;
        } else {
          return false;
        }
      },
      (error) => {
        return false;
      }
    );
}
export function getCustomerTypes() {
  let apiRequestObj = getTokenApiRequest();
  return apiRequestObj({
    url: "CommonService/GetCustomerTypes",
    method: "GET",
  })
    .then((response) => {
      if (response.status == 200 ) {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export function checkSpecialUser() {
  let apiRequestObj = getTokenApiRequest();
  return apiRequestObj({
    url: "AccountService/checkSpecialUser?Type=4",
    method: "GET",
  })
    .then((response) => {
      if (response.status == 200 ) {
        debugger
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });

  }
export function getCardTypes() {
  let apiRequestObj = getTokenApiRequest();
  return apiRequestObj({
    url: "CommonService/GetCardTypes",
    method: "GET",
  })
    .then((response) => {
      if (response.status == 200 ) {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}
export function getUserList() {
  let apiRequestObj = getTokenApiRequest();
  return apiRequestObj({
    url: "AccountService/GetRegisteredUsers",
    method: "GET",
  })
    .then((response) => {
      if (response.status == 200 ) {
        if (response.data == null) return false;
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export const setPhoneCodeList = async () => {
  const fetchPhoneCodeList = await getPhoneCode();

  let tempPhoneCodeArray = [];
  let phoneCodeArray = [];

  for (let i = 0; i < fetchPhoneCodeList.length; i++) {
    tempPhoneCodeArray.push(fetchPhoneCodeList[i]._source.code.slice(1));
  }

  for (let i = 0; i < fetchPhoneCodeList.length; i++) {
    phoneCodeArray.push("+" + tempPhoneCodeArray[i]);
  }

  return phoneCodeArray.sort();
};

export function getHotels(searchKey) {
  return fetch(
    process.env.REACT_APP_ELASTIC_URL +
      "Data/GetHotels?value=" +
      searchKey +
      "&size=10"
  )
    .then((response) => response.json())
    .then(
      (result) => {
        if (result.hits.hits.length > 0) {
          return result.hits.hits;
        } else {
          return false;
        }
      },
      (error) => {
        return false;
      }
    );
}

export function getMyProfile() {
  let apiRequestObj = getTokenApiRequest();

  return apiRequestObj({
    url: "AccountService/GetProfile",
    method: "GET",
  })
    .then((response) => {
      if (response.status == 200 ) {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export function changePassword(userInfo) {
  let apiRequestObj = getTokenApiRequest();

  return apiRequestObj({
    url: "AccountService/ChangePassword",
    method: "post",
    data: userInfo,
  })
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export function forgotPassword(data) {
  let apiRequestObj = getTokenApiRequest();

  return apiRequestObj({
    url: "AccountService/ForgotPassword",
    method: "post",
    data: data,
  })
    .then((response) => {
      if (response.status == 200 ) {
        return response.data;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export function updateProfile(data) {
  let apiRequestObj = getTokenApiRequest();

  return apiRequestObj({
    url: "AccountService/UpdateProfile",
    method: "post",
    data: data,
  })
    .then((response) => {
      if (response.status == 200 ) {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export function getSportStadiums(searchKey) {
  return fetch(
    process.env.REACT_APP_ELASTIC_URL +
      "Data/GetSportsStadium?value=" +
      searchKey +
      "&culture=1&size=10"
  )
    .then((response) => response.json())
    .then(
      (result) => {
        if (result.hits.hits.length > 0) {
          return result.hits.hits;
        } else {
          return false;
        }
      },
      (error) => {
        return false;
      }
    );
}

export function getCmsData(page, culture) {
  let apiRequestObj = getTokenApiRequest();

  
  return apiRequestObj({
    url: "CMS/GetData?page=" + page + "&culture=" + culture,
    method: "GET",
  })
    .then((response) => {
     
      if (response.status == 200 ) {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((error) => {
      
      console.log(error);
      return false;
    });
}

/* export const getHotelList = [
  "Al HAMRA",
  "AL KOUT BEACH",
  "Asha's Restaurant",
  "Babel Restaurant",
  "CONTINENTAL",
  "CONTINENTAL INN",
  "COPTHORNE HOTEL",
  "CROWN PLAZA",
  "Dar Hamad",
  "FOUR SEASONS",
  "Fraij Suelah",
  "Garden Café Restaurant",
  "GRAND DAHLIA",
  "HILTON RESORT",
  "HYATT REGENCY",
  "LE JAZZ HOTEL",
  "LUMIERE DES ETOILE",
  "Mais Al Ghanim",
  "MILLENNIUM HOTEL",
  "MOVENPICK",
  "Olio Trattoria",
  "Pepper Steakhouse",
  "ZODIAC HOTEL",
]; */

/* export const getHotelList = [
  {label:"Al HAMRA", value:lang},
  {label:"AL KOUT BEACH", value:"AL KOUT BEACH"},
  {label:"Asha's Restaurant", value:"Al HAMRA"},
  {label:"Babel Restaurant", value:"AL KOUT BEACH"},
  {label:"CONTINENTAL", value:"Al HAMRA"},
  {label:"CONTINENTAL INN", value:"AL KOUT BEACH"},
  {label:"COPTHORNE HOTEL", value:"Al HAMRA"},
  {label:"CROWN PLAZA", value:"AL KOUT BEACH"},
  {label:"Dar Hamad", value:"AL KOUT BEACH"},
  {label:"FOUR SEASONS", value:"AL KOUT BEACH"},
  {label:"Fraij Suelah", value:"AL KOUT BEACH"},
  {label:"Garden Café Restaurant", value:"AL KOUT BEACH"},
  {label:"GRAND DAHLIA", value:"AL KOUT BEACH"},
  {label:"HILTON RESORT", value:"AL KOUT BEACH"},
  {label:"HYATT REGENCY", value:"AL KOUT BEACH"},
  {label:"LE JAZZ HOTEL", value:"AL KOUT BEACH"},
  {label:"LUMIERE DES ETOILE", value:"AL KOUT BEACH"},
  {label:"Mais Al Ghanim", value:"AL KOUT BEACH"},
  {label:"MILLENNIUM HOTEL", value:"AL KOUT BEACH"},
  {label:"MOVENPICK", value:"AL KOUT BEACH"},
  {label:"Olio Trattoria", value:"AL KOUT BEACH"},
  {label:"Pepper Steakhouse", value:"AL KOUT BEACH"},
  {label:"ZODIAC HOTEL", value:"AL KOUT BEACH"},
] */

export const getHotelList = (lang) => {
  

 const HotelList = [
    {label:"Al HAMRA", value:lang.al_hamra},
    {label:"AL KOUT BEACH", value:lang.al_kout_beach},
    {label:"Asha's Restaurant", value:lang.ashas_restaurant},
    {label:"Babel Restaurant", value:lang.babel_restaurant},
    {label:"CONTINENTAL", value:lang.continental},
    {label:"CONTINENTAL INN", value:lang.continental_inn},
    {label:"COPTHORNE HOTEL", value:lang.copthorne_hotel},
    {label:"CROWN PLAZA", value:lang.crown_plaza},
    {label:"Dar Hamad", value:lang.dar_hamad},
    {label:"FOUR SEASONS", value:lang.four_seasons},
    {label:"Fraij Suelah", value:lang.fraij_suelah},
    {label:"Garden Café Restaurant", value:lang.garden_cafe_restaurant},
    {label:"GRAND DAHLIA", value:lang.grand_dahlia},
    {label:"HILTON RESORT", value:lang.hilton_resort},
    {label:"HYATT REGENCY", value:lang.hyatt_regency},
    {label:"LE JAZZ HOTEL", value:lang.le_jazz_hotel},
    {label:"LUMIERE DES ETOILE", value:lang.lumiere_des_etoile},
    {label:"Mais Al Ghanim", value:lang.mais_al_ghanim},
    {label:"MILLENNIUM HOTEL", value:lang.millennium_hotel},
    {label:"MOVENPICK", value:lang.movenpick},
    {label:"Olio Trattoria", value:lang.olio_trattoria},
    {label:"Pepper Steakhouse", value:lang.pepper_steakhouse},
    {label:"ZODIAC HOTEL", value:lang.zodiac_hotel},
  ]

  return HotelList

}
