import React, { useEffect, useState } from 'react';

import InnerBanner from '../../components/common/inner_banner';

import HotebookingServiceDetails from "../../assets/images/hotelbookings_servicedetail.jpg"

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Autocomplete from 'react-autocomplete';
import {  Link} from 'react-router-dom';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { data } from 'jquery';
import { getServiceDetailsById } from '../../scripts/common';
import ServiceBoxLeft from './ServiceBoxLeft';
import { postServices, setPhoneCodeList, getCountryOrCity ,getHotels} from '../../services/common'
import { useNavigate } from 'react-router-dom';
import ar from "date-fns/locale/ar"; // the locale you want

import { alertActions } from "../../redux/alertSlice";
import FulloverLoader from '../../components/common/FulloverLoader';
import UserList from '../../components/common/UserList';
import { useDispatch, useSelector } from "react-redux";
const serviceId = 7;

function BusinessBankingPage({ setShowLoginModal }) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm();

  const current_culture = useSelector(state => state.culture.value);
  registerLocale("ar", ar);
  let myProfile = JSON.parse(localStorage.getItem("myProfile"));
  
  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState('');
  const [walletData, setWalletData] = useState(null)
  const tokenData = useSelector((state) => state.tokenInfo);
  const [checkInDate, setCheckInDate] = useState();
  const [hotel, setHotel] = useState("")
  const [hotelList, setHotelList] = useState([])
  const [City, setCity] = useState("")
  const [cityList, setCityList] = useState([])
  const dispatch = useDispatch()
  const [phoneCode, setPhoneCode] = useState([])
  const [mobileCode, setMobileCode] = useState(process.env.REACT_APP_MOBCODE);
  let lang = useSelector((state) => state.language.data);

  const [isLoading, setIsLoading] = useState(false);
  const [checkOutDate, setCheckOutDate] = useState();
  let navigate = useNavigate();
  const [serviceDetails, setserviceDetails] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);

    const serviceDetailsData = getServiceDetailsById(serviceId);
    setserviceDetails(serviceDetailsData)

    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList()

      setPhoneCode(phoneCodeList)
    }
    fetchPhoneCode();
  }, []);
  const cityRequestHandler = async (e) => {
    setCity(e.target.value);

    const cityResponse = await getCountryOrCity(e.target.value)

    if (cityResponse) {

      setCityList(cityResponse)
    }
  };

  const selectCityHandler = (value, onChange) => {
    setCity(value)
    onChange(value)

  }
  const hotelRequestHandler = async (e) => {
    setHotel(e.target.value);

    const hotelResponse = await getHotels(e.target.value)

    if (hotelResponse) {

      setHotelList(hotelResponse)
    }
  };

  const selectHotelHandler = (value, onChange) => {
    setHotel(value)
    onChange(value)

  }
  const onSubmit = async (data) => {
    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true)
    } else {
      setIsLoading(true)
      let globalAirportServicesParams = {
        ServiceID: serviceId,
        /* OnBehalfId: onBehalfId, */
        Data: JSON.stringify({
          name: data.name,
          email: data.email,
          mobile_code: data.mobileCode,
          contact_number: data.contact_number,
          check_in_date: moment(data.check_in_date).format(
            "DD/MM/YYYY"
          ),
          check_out_date: moment(data.check_out_date).format(
            "DD/MM/YYYY"
          ),
          no_of_rooms: data.no_of_rooms,
          name_of_hotel: data.name_of_hotel,
          no_of_adults: data.no_of_adults,
          no_of_children: data.no_of_children,
          additional_information: data.additional_information,
          special_requests: data.special_requests,
          // fieldOrder:'name,email,mobile_code,contact_number,check_in_date,check_out_date,no_of_rooms,name_of_hotel,no_of_adults,no_of_children,additional_information,special_requests'
        })
      }
      if(onBehalfId) {
        globalAirportServicesParams["OnBehalfId"] = onBehalfId
      }
      const response = await postServices(globalAirportServicesParams);
      if (response) {
        dispatch(alertActions.setPopUpType('success'))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: "" }))
        dispatch(alertActions.setPopUpData({ heading: 'Success', message: 'Form Submitted Succesfully' }))
        resetForm()
        setIsLoading(false)

      } else {
        dispatch(alertActions.setPopUpType('error'))
        dispatch(alertActions.setPopUpData({ heading: 'Failed', message: 'Something went wrong, Please Try again' }))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))

        setIsLoading(false)
      }
    }
  }
  const resetForm=()=>{
    reset()
    setMobileCode(process.env.REACT_APP_MOBCODE)
    setCity('')
    setHotel('')
    setOnBehalfId("")
    setValue('OnBehalfId',"")
    setUserText('')
    setWalletData(null)

  }
  return (

    <React.Fragment>
      {
        isLoading &&
        <FulloverLoader />
      }
      <InnerBanner title={lang.hotel_booking}></InnerBanner>

      <section className='content_service_main form_bg'>
        <div className='container'>
          <div className='form_breadcrumbs'>
            <ul>
               <li><Link to={process.env.REACT_APP_BASE_URL+'services'}>{lang.services} </Link></li>
              <li><a className='active' href=''>{lang.hotel_booking}</a></li>
            </ul>
          </div>
          <div className='service_box'>
            {
              serviceDetails &&
              <ServiceBoxLeft
                serviceDetails={serviceDetails}
              />
            }
            <div className='service_box_col_rht'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='service_detail_formbox formstyle'>
                  <div className='service_detail_formhd'>
                    <h2>{lang.feel_like_contacting_us}</h2>
                    <p>{lang.submit_the_form_below_we_will_get_back_to_you_asap}</p>
                  </div>
                  <hr className='form_hd_hr'>
                  </hr>
                  {(myProfile && myProfile.isAgentLogin && ! tokenData.data.isDefaultUser) &&
                    <UserList
                      register={register}
                      onBehalfId={onBehalfId}
                      setOnBehalfId={setOnBehalfId}
                      errors={errors}
                      control={control}
                      userText={userText}
                      setUserText={setUserText}
                      walletData={walletData}
                      setWalletData={setWalletData}
                    />
                  }
                  <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.name}<span>*</span></label>
                        <input type="text"
                          {...register('name', {
                            required: lang.name_is_required,
                            minLength: {
                              value: 2,
                              message:lang.please_enter_minimum_two_characters,

                            },
                            pattern: {
                              value: /[A-Za-z]/,
                              message:lang.please_enter_valid_name,
                            },

                            onChange: e => {

                            },
                          })}
                          className="form-control"
                        >
                        </input>
                        <ErrorMessage
                          errors={errors}
                          name={'name'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>

                          )}
                        />
                      </div>

                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.email}<span>*</span></label>
                        <input type="text"
                          {...register('email', {
                            required: lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message:lang.invalid_email_address,
                            },

                            onChange: e => {
                            },
                          })} className="form-control">
                        </input>
                        <ErrorMessage
                          errors={errors}
                          name={'email'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>

                          )}
                        />
                      </div>

                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.contact_number}<span>*</span></label>
                        <div className="form-group d-flex flex-wrap">
                          <div className="phonecode form_phone_select_arrow position-relative">
                            <select className="form-control"
                               {...register('mobileCode', {
                                required: {
                                  value: true,
                                  message:
                                  lang.phone_code_is_required,
                                },
                                onChange: (e) => {
                                  setMobileCode(e.target.value)
                                }, value: mobileCode
                              })}
                            >
                             {phoneCode.map((item, key) => {
                                return <option selected={mobileCode == item ? 'selected' : ''} value={item}>{item}</option>
                              })}
                            </select>
                          </div>
                          <div className="phonenumber">
                            <input type="name"
                              className="form-control"
                              {...register('contact_number', {
                                required: {
                                  value: true,
                                  message:
                                    lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message:
                                  lang.please_enter_atleast_six_numbers,
                                },

                                onChange: e => {

                                },
                              })}

                              placeholder=" ">
                            </input>
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={'contact_number'}
                            render={({ message }) => (
                              <span className='error-message'>{message}</span>
                            )}
                          />
                        </div>

                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                      <div className='form-group DisplayinRmv autocomplete'>
                        <label>{lang.select_country_city}<span></span></label>


                        <Controller
                          name={"country_or_city"}
                          control={control}
                          value={City}
                          render={({ field: { onChange, value, name } }) => (
                            <Autocomplete
                              getItemValue={(item) => current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                              items={cityList}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  className={
                                    isHighlighted
                                      ? " react-autocomplete-inner active "
                                      : "react-autocomplete-inner"
                                  }
                                >
                                  {current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                                </div>
                              )}
                              {...register("country_or_city", {
                                required: {
                                  value: true,
                                  message: lang.please_select_restaurant_location,
                                },
                              })}
                              onChange={(event) => cityRequestHandler(event)}
                              onSelect={(label) => selectCityHandler(label, onChange)}
                              value={City}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"country_or_city"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />

                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group"><label>{lang.check_in_date}<span></span></label>
                        <Controller
                          name={'check_in_date'}
                          control={control}
                          render={({
                            field: { onChange, value },
                          }) => (
                            <DatePicker
                              minDate={new Date()}
                              locale={current_culture}
                              dateFormat={'dd/MM/yyyy'}
                              className="form-control"
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown={true}
                              showYearDropdown={true}
                              selected={value}
                              onChange={date => {
                                ;
                                onChange(date)
                                setCheckInDate(new Date(date))
                              }}
                              value={checkInDate}
                            />
                          )}
                          {...register('check_in_date', {
                            required:lang.check_in_date_is_required,
                            value: checkInDate,
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={'check_in_date'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>

                          )}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group"><label>{lang.check_out_date}<span></span></label>
                        <Controller
                          name={'check_out_date'}
                          control={control}
                          render={({
                            field: { onChange, value },
                          }) => (
                            <DatePicker
                              minDate={new Date((checkInDate != null) ? checkInDate : new Date())}
                              locale={current_culture}
                              dateFormat={'dd/MM/yyyy'}
                              className="form-control"
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown={true}
                              showYearDropdown={true}
                              selected={value}
                              onChange={date => {
                                ;
                                onChange(date)
                                setCheckOutDate(new Date(date))
                              }}
                              value={checkOutDate}
                            />
                          )}
                          {...register('check_out_date', {
                            required: lang.checkout_date_is_required,
                            validate: (checkOutDate) => (checkOutDate >= checkInDate)
                              || lang.checkout_date_should_be_greater,
                            value: checkOutDate,
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={'check_out_date'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>

                          )}
                        />
                      </div>
                    </div>

                    <div className='col-lg-4 col-md-6'>
                      <div className='form-group form_select_arrow'>
                        <label>{lang.no_of_rooms}<span></span></label>
                        <select name="" id="" className="form-control"
                          {...register('no_of_rooms')}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="3">4</option>
                          <option value="3">5</option>
                        </select>
                      </div>
                    </div>

                    <div className='col-lg-4 col-md-6'>
                      <div className='form-group form_select_arrow'>
                        <label>{lang.no_of_adults}<span></span></label>
                        <select name="" id="" className="form-control"
                          {...register('no_of_adults')}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="3">4</option>
                          <option value="3">5</option>
                          <option value="3">6</option>
                          <option value="3">7</option>
                          <option value="3">8</option>
                          <option value="3">9</option>
                        </select>
                      </div>
                    </div>

                    <div className='col-lg-4 col-md-6'>
                      <div className='form-group form_select_arrow'>
                        <label>{lang.no_of_children}<span></span></label>
                        <select name="" id="" className="form-control"
                          {...register('no_of_children')}
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="3">4</option>
                          <option value="3">5</option>
                          <option value="3">6</option>
                          <option value="3">7</option>
                          <option value="3">8</option>
                          <option value="3">9</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-8 col-md-12">
                      <div className="form-group DisplayinRmv autocomplete"><label>{lang.nam_of_hotel}<span></span></label>



                        <Controller
                          name={"name_of_hotel"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <Autocomplete
                              getItemValue={(item) => item._source.displayname}
                              items={hotelList}
                              className="form-control"
                              renderItem={(item, isHighlighted) => (
                                <div
                                  className={
                                    isHighlighted
                                      ? " react-autocomplete-inner active "
                                      : "react-autocomplete-inner"
                                  }
                                >
                                  {item._source.displayname}
                                </div>
                              )}
                              {...register("name_of_hotel", {
                                required: {
                                  value: true,
                                  message:lang.hotel_name_is_required,
                                },
                              })}
                              onChange={(event) => hotelRequestHandler(event)}
                              onSelect={(label) => selectHotelHandler(label, onChange)}
                              value={hotel}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"name_of_hotel"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />



                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.special_requests}</label>
                        <textarea className="form-control" name="" rows="" cols=""
                          {...register('special_requests', {

                          })}
                        ></textarea>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.additional_information}</label>
                        <textarea className="form-control" name="" rows="" cols=""
                          {...register('additional_information', {

                          })}
                        ></textarea>
                      </div>
                    </div>




                    <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                      <button type="submit" className="form_submit_btn">{lang.submit}</button>
                    </div>
                    {
                      tokenData.data.isDefaultUser &&
                      <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                        <p className='form_note'>{lang.note_now_you_are_on_guest_user_please} <a href='#' onClick={() => setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}</p>
                      </div>
                    }
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section >


    </React.Fragment >

  )
}
export default BusinessBankingPage;