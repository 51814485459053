import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

import InnerBanner from "../components/common/inner_banner";
import { getCardTypes, getCustomerTypes, getMyProfile, setPhoneCodeList, updateProfile } from "../services/common";

import "react-accessible-accordion/dist/fancy-example.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { changePassword } from "../services/common";
import { alertActions } from "../redux/alertSlice";
import FulloverLoader from "../components/common/FulloverLoader";

function MyProfile() {
  let myProfile = JSON.parse(localStorage.getItem("myProfile"));
  let lang = useSelector((state) => state.language.data);
  const dispatch = useDispatch();
  const naviagte = useNavigate()
  const [firstname, setFirstname] = useState(myProfile.firstName);
  const [lastName, setLastName] = useState(myProfile.lastName);
  const [isLoading, setIsLoading] = useState(true);
  const [genderCode, setGenderCode] = useState("Male");
  const [phoneCode, setPhoneCode] = useState([]);
  const [customerTypes, setCustomerTypes] = useState()
  const [cardTypes, setCardTypes] = useState([])
  const [mobCode, setMobCode] = useState(process.env.REACT_APP_MOBCODE);
  const [phoneNumber, setphoneNumber] = useState(myProfile.phone);
  const [dob, setDob] = useState(new Date());
  const tokenData = useSelector((state) => state.tokenInfo);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({ criteriaMode: "all" });
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = useForm({ criteriaMode: "all" });
  const current_culture = useSelector(state => state.culture.value)

  useEffect(() => {

    const initialize = async () => {
      const phoneCodeList = await setPhoneCodeList()
      setPhoneCode(phoneCodeList)
      const CustomerTypeList = await getCustomerTypes()
      CustomerTypeList.map((customer) => {
        if (myProfile.customerType == customer.customerTypeId) {
          
          setCustomerTypes(customer.customerType)

        }

      })
      const cardTypeList = await getCardTypes()

      if (CustomerTypeList && cardTypeList)
        setIsLoading(false)
      cardTypeList.map((card) => {
        
        if (myProfile.cardType == card.cardTypeId)
          setCardTypes(card.cardNumber)
      })

    }

    initialize()
    if (tokenData.data.isDefaultUser) {
      naviagte(process.env.REACT_APP_BASE_URL)
    } else { initialize(); }

    initialize();
  }, []);

  const onProfileSubmit = async (data) => {

    setIsLoading(true)
    console.log(data);

    const profileInfo = {
      title: data.title,
      firstName: data.firstName,
      lastName: data.lastName,
      countryCode: mobCode,
      phone: data.mobileno,
      email: data.email,
      cardType: myProfile.customerType,
      cardNumber: cardTypes,
      customerType: myProfile.customerType
    };

    console.log("profileInfo", profileInfo);

    const profileResponse = await updateProfile(profileInfo)

    console.log("profileresponse", profileResponse);

    if (profileResponse == "Profile_Updated") {
      dispatch(alertActions.setPopUpType("success"));
      dispatch(
        alertActions.setRedirect({
          redirect: false,
          redirectUrl: process.env.REACT_APP_BASE_URL,
        })
      );
      dispatch(
        alertActions.setPopUpData({
          heading: "Success",
          message: "Profile Updated Succesfully",
        })
      );

      const updatedProfile = await getMyProfile()
      console.log("myprofile", updatedProfile);

      localStorage.setItem("myProfile", JSON.stringify(updatedProfile))

    } else {
      dispatch(alertActions.setPopUpType("error"));
      dispatch(
        alertActions.setPopUpData({
          heading: "Failed",
          message: "Something Went Wrong",
        })
      );
      dispatch(
        alertActions.setRedirect({
          redirect: false,
          redirectUrl: process.env.REACT_APP_BASE_URL,
        })
      );
    }
    window.location.reload()
    setIsLoading(false)
  };



  const onPasswordSubmit = async (data) => {
    setIsLoading(true);

    const userInfo = {
      userID: myProfile.id,
      email: myProfile.username,
      password: data.newPassword,
      culture: current_culture,
    };
    const passwordRes = await changePassword(userInfo);

    if (passwordRes) {
      dispatch(alertActions.setPopUpType("success"));
      dispatch(
        alertActions.setRedirect({
          redirect: false,
          redirectUrl: process.env.REACT_APP_BASE_URL,
        })
      );
      dispatch(
        alertActions.setPopUpData({
          heading: lang.sucess,
          message: lang.password_updated,
        })
      );
    } else {
      dispatch(alertActions.setPopUpType("error"));
      dispatch(
        alertActions.setPopUpData({
          heading: lang.failed,
          message:lang.something_went_wrong_please_try_again,
        })
      );
      dispatch(
        alertActions.setRedirect({
          redirect: false,
          redirectUrl: process.env.REACT_APP_BASE_URL,
        })
      );
    }

    setIsLoading(false);
    console.log("passwordRes", passwordRes);
  };

  return (
    <React.Fragment>
      {isLoading && <FulloverLoader />}
      <InnerBanner title={lang.personal_profile}></InnerBanner>
      <section className="myprofile_main accordion_style formstyle">
        <div class="container">
          <div className="myprofile_box">
            <Accordion preExpanded={["a"]}>
              <AccordionItem uuid="a">
                <AccordionItemHeading>
                  <AccordionItemButton> {lang.personal_profile} </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="row">
                    {/* <div className="col-lg-2 col-md-2">
                      <div className="form-group form_select_arrow">
                        <label>
                          Title<span></span>
                        </label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register2("title", {
                            required: {
                              value: true,
                              message: "Please Select a Title",
                            },
                          })}
                        >
                          <option value="">Select</option>
                          <option value="Mr.">Mr.</option>
                          <option value="Ms.">Ms.</option>
                          <option value="Miss">Miss</option>
                          <option value="Mstr.">Mstr.</option>
                        </select>
                        <ErrorMessage
                          errors={errors2}
                          name={"title"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div> */}
                    <div class="col-lg-3 col-md-5">
                      <div class="form-group">
                        <label>
                          {lang.first_name}<span></span>
                        </label>
                        <input
                          type="text"
                          name="Name"
                          value={firstname}
                          class="form-control"
                          {...register2("firstName", {
                            required: { value: true, message: lang.first_name_is_required },
                            pattern: {
                              value: /[A-Za-z]/,
                              message: lang.please_enter_valid_name,
                            },
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,
                            },
                            onChange: (e) => {
                              setFirstname(e.target.value)
                            }


                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors2}
                          name={"firstName"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-5">
                      <div class="form-group">
                        <label>
                          {lang.last_name}<span></span>
                        </label>
                        <input
                          type="text"
                          name="Name"
                          value={lastName}

                          class="form-control"
                          {...register2("lastName", {
                            required: lang.last_name_is_required,
                            pattern: {
                              value: /[A-Za-z]/,
                              message: lang.please_enter_valid_name,
                            },
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,
                            },
                            onChange: (e) => {
                              setLastName(e.target.value)
                            }


                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors2}
                          name={"lastName"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    {/* <div class="col-lg-3 col-md-4">
                      <div class="form-group">
                        <label>
                          Date of Birth<span></span>
                        </label>
                        
                        <DatePicker
                          dateFormat={"dd/MM/yyyy"}
                          maxDate={new Date()}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown={true}
                          selected={dob}
                          onChange={(value) => {
                            setDob(new Date(value));
                          }}
                        />
                      </div>
                    </div> */}
                    <div class="col-lg-4 col-md-8">
                      <div class="form-group">
                        <label>
                          {lang.phone_number}<span></span>
                        </label>
                        <div class="form-group d-flex flex-wrap">
                          <div class="phonecode form_phone_select_arrow position-relative">
                            <select
                              class="form-control"
                              name="mobileCode"
                              value={mobCode}
                              {...register2("phoneCode", {
                                required: {
                                  value: true,
                                  message: lang.phone_code_is_required,
                                },
                                onChange: (e) => setMobCode(e.target.value),
                                value: mobCode,
                              })}
                            >
                              {phoneCode.map((item) => {
                                return <option value={item}>{item}</option>;
                              })}
                            </select>
                            <ErrorMessage
                              errors={errors2}
                              name={"phoneCode"}
                              render={({ message }) => (
                                <span className="error-message">{message}</span>
                              )}
                            />
                          </div>
                          <div class="phonenumber">
                            <input
                              type="name"
                              class="form-control"
                              name="contact_number"
                              placeholder=" "
                              {...register2("mobileno", {
                                required: {
                                  value: true,
                                  message: lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message: lang.please_enter_atleast_six_numbers,
                                },
                                onChange: (e) => {
                                  setphoneNumber(e.target.value)
                                },
                              })}
                              value={phoneNumber}

                            ></input>

                          </div>
                          <ErrorMessage
                            errors={errors2}
                            name={"mobileno"}
                            render={({ message }) => (
                              <span className="error-message">{message}</span>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="form-group">
                        <label>
                          {lang.email}<span></span>
                        </label>
                        <input
                          readOnly={true}
                          value={myProfile && myProfile.username}
                        ></input>
                      </div>
                    </div>
                    {
                      customerTypes &&
                      <div class="col-lg-4 col-md-6">
                        <div class="form-group">
                          <label>
                            {lang.customer_type}<span></span>
                          </label>
                          <input
                            readOnly={true}
                            value={myProfile && customerTypes}
                          ></input>
                        </div>
                      </div>
                    }
                    {
                      customerTypes && customerTypes != "Normal" &&
                      <div class="col-lg-4 col-md-6">
                        <div class="form-group">
                          <label>
                            {lang.card_bin}<span></span>
                          </label>
                          <input
                            readOnly={true}
                            value={myProfile && cardTypes}
                          ></input>
                        </div>
                      </div>
                    }

                    {/* <div class="col-lg-4 col-md-6">
                      <div class="form-group">
                        <label>
                          Gender Code<span></span>
                        </label>
                        <div class="form-group radio-outer">
                          <label class="tripradio">
                            Male
                            <input
                              type="radio"
                              class="form-control"
                              name="flight-trip"
                              onClick={() => setGenderCode("Male")}
                              checked={genderCode == "Male"}
                            ></input>
                            <span class="checkmark"></span>
                          </label>
                          <label class="tripradio">
                            Female
                            <input
                              type="radio"
                              class="form-control"
                              name="flight-trip"
                              onClick={() => setGenderCode("Female")}
                              checked={genderCode == "Female"}
                            ></input>
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div> */}
                    {
                      customerTypes &&
                      <div class="col-lg-12 col-md-12 d-flex justify-content-start">
                        <button
                          type="submit"
                          onClick={handleSubmit2(onProfileSubmit)}
                          class="form_submit_btn"
                        >
                          {lang.save}
                        </button>
                      </div>
                    }
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton> {lang.change_password} </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="row">
                    <div class="col-lg-4 col-md-4">
                      <div class="form-group">
                        <label>
                          {lang.old_password}<span></span>
                        </label>
                        <input
                          type="password"
                          name="Name"
                          class="form-control"
                          {...register("oldPassword", {
                            required: {
                              value: true,
                              message: lang.enter_old_password,
                            },
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"oldPassword"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                      <div class="form-group">
                        <label>
                          {lang.new_password}<span></span>
                        </label>
                        <input
                          type="password"
                          name="newPassword"
                          class="form-control"
                          {...register("newPassword", {
                            required: {
                              value: true,
                              message: lang.enter_new_password,
                            },
                            minLength: {
                              value: 8,
                              message:
                                lang.password_required_pattern,
                            },
                            pattern: {
                              value:
                                /^[a-zA-Z0-9!@#$%^&*()_=\[\]{};':"\\|\\~\\`,.<>\/?+-]+$/ &&
                                /[a-zA-Z]/ &&
                                /^[0-9]+$/ &&
                                /[!@#$%^&*()_=\[\]{};':"\\|\\~\\`,.<>\/?+-]/,
                              message:
                                lang.password_required_pattern,
                            },
                            validate: (value) =>
                              !(value === getValues().oldPassword) ||
                              lang.new_pswd_cant_same_as_old_pswd
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"newPassword"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                      <div class="form-group">
                        <label>
                          {lang.confirm_password}<span></span>
                        </label>
                        <input
                          type="password"
                          name="confirm_pwd"
                          class="form-control"
                          {...register("confirm_pwd", {
                            required: {
                              value: true,
                              message: lang.confirm_password_required,
                            },
                            validate: (value) =>
                              value === getValues().newPassword ||
                              lang.password_mismatch,
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"confirm_pwd"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 d-flex justify-content-start">
                      <button
                        type="submit"
                        class="form_submit_btn"
                        onClick={handleSubmit(onPasswordSubmit)}
                      >
                        {lang.update}
                      </button>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default MyProfile;
