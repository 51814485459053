import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { getEnquiryDetails } from "../../services/common";
import VoucherWeb from "./VoucherWeb";
import VoucherMob from "./VoucherMob";
import PreLoader from "../../components/common/PreLoader";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import { font } from "../../services/font";

function Voucher() {
  const [isLoading, setIsLoading] = useState(true);
  const [downloadLoader, setDownloadLoader] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const [enquiryDetails, setEnquiryDetails] = useState({});
  const [enqData, setEnqData] = useState([]);
  const [isMobView, setIsMobView] = useState(
    window.innerWidth < 768 ? true : false
  );
  const current_culture = useSelector((state) => state.culture.value);

  let enqId = searchParams.get("enquiryId");
  let download = searchParams.get("download")
  let appView = searchParams.get("isMobileApp")
  const ticketRef = useRef();

  window.addEventListener("resize", (event) => {
    let width = window.innerWidth;

    if (width < 768) {
      setIsMobView(true);
    } else {
      setIsMobView(false);
    }
  });

  const ticketDownloadHandler = () => {


    let voucherUrl = window.location.href

    setDownloadLoader(true)

fetch(process.env.REACT_APP_NODE_URL+ "test", {
  method: 'POST',
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify({enqId : enqId, culture: current_culture, url: voucherUrl})
})
   .then((response) => {


    response.blob().then(blob => download(blob,"voucher"))

    function download(blob, filename) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
    setDownloadLoader(false)
  

   })
   
   .catch((err) => {
      console.log(err.message);
   })

   


  };

  useEffect(() => {
    const fetchEnquiryDetails = async () => {
      const enquiryDetailsRes = await getEnquiryDetails(enqId);

      setEnquiryDetails(enquiryDetailsRes);

      let data = Object.entries(enquiryDetailsRes.data);

      setEnqData(data);

      setIsLoading(false);
    };

    fetchEnquiryDetails();
  }, []);

  console.log("test", enquiryDetails, enqData);
  return isLoading ? (
    <PreLoader />
  ) : (
    <>
    {downloadLoader && <PreLoader/>}
      <VoucherWeb
        isMobView={isMobView}
        ticketRef={ticketRef}
        ticketDownloadHandler={ticketDownloadHandler}
        enquiryDetails={enquiryDetails}
        enqData={enqData}
        download={download}
        appView={appView}
      />
      {isMobView && (
        <VoucherMob
          ticketDownloadHandler={ticketDownloadHandler}
          enquiryDetails={enquiryDetails}
          enqData={enqData}
          appView={appView}
        />
      )}
    </>
  );
}

export default Voucher;
