import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message'
import { getMyProfile, loginRequest } from '../../services/common';
import { setTokenData } from '../../redux/getBearortoken';
import { useSelector, useDispatch } from 'react-redux'
import { alertActions } from "../../redux/alertSlice";
import FulloverLoader from './FulloverLoader';


export default function Login({ showLoginModal,
    setShowLoginModal, setShowRegisterModal, setShowForgotPasswordModal }) {
    const [isLoading, setIsLoading] = useState(false);
    let lang = useSelector((state) => state.language.data);

    const dispatch = useDispatch();
    const { register: register, handleSubmit: handleSubmit, formState: { errors: errors }, reset } = useForm({ criteriaMode: 'all' });
    const onSubmit = async (data) => {
        setIsLoading(true)
        const response = await loginRequest({
            username: data.email,
            password: data.password,
            grandtype: 'password'
        })
        if (response) {
            setIsLoading(false)

            if (response == 'invalid') {
                dispatch(alertActions.setPopUpType('error'))
                dispatch(alertActions.setPopUpData({ heading: lang.login_failed, message: lang.invalid_user_pass }))
                dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
            } else {

                dispatch(setTokenData(response.data));
                setShowLoginModal(false)
                dispatch(alertActions.setPopUpType('success'))
                dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
                dispatch(alertActions.setPopUpData({ heading: lang.sucess, message: lang.Login_Successful }))

                const myProfile = await getMyProfile()
                console.log(myProfile)

                localStorage.setItem("myProfile", JSON.stringify(myProfile))

            }
        }
    }
    return (
        <>
            {
                isLoading &&
                <FulloverLoader />
            }
            {
                showLoginModal &&
                <Modal show={showLoginModal} onHide={() => setShowLoginModal(false)} className="log_reg_popup">
                    <Modal.Header closeButton>
                        <Modal.Title>{lang.signin}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='log-field'>
                                <label>{lang.email}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={lang.email}
                                    id="email"
                                    autoComplete="off"
                                    {...register(
                                        "email",
                                        {
                                            required: {
                                                value: true,
                                                message: lang.email_is_required
                                            },
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Please provide a valid Email Address"
                                            },

                                        }
                                    )}
                                />
                                <ErrorMessage errors={errors} name={'email'}
                                    render={({ message }) => <span className="error-message">{message}</span>}
                                />
                            </div>
                            <div className='log-field'>
                                <label>{lang.password}</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder={lang.password}
                                    id="loginPassword"
                                    {...register(
                                        "password",
                                        {
                                            required: {
                                                value: true,
                                                message: lang.password_req
                                            },
                                        }
                                    )}
                                />
                                <ErrorMessage errors={errors} name={'password'}
                                    render={({ message }) => <span className="error-message">{message}</span>}
                                />
                            </div>

                            <div className='forgot-txt'>
                                <a href onClick={() => { setShowForgotPasswordModal(true); setShowLoginModal(false) }}>{lang.forgot_password}</a>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="login" onClick={handleSubmit(onSubmit)}>
                            {lang.login}
                        </Button>
                        <div className="log_sign">
                            <p>{lang.dnt_have_account}</p>
                            <a
                                onClick={() => {
                                    setShowLoginModal(false)
                                    setShowRegisterModal(true);
                                }}
                            >{lang.signup}</a>
                        </div>
                    </Modal.Footer>
                </Modal>
            }

        </>


    )
}