import React, { useState, useEffect } from 'react';

import InnerBanner from '../../components/common/inner_banner';

import Autocomplete from "react-autocomplete";

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Link } from 'react-router-dom';

import { getServiceDetailsById } from '../../scripts/common';
import ServiceBoxLeft from './ServiceBoxLeft';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getCountryOrCity, postServices, setPhoneCodeList } from '../../services/common'
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import FulloverLoader from '../../components/common/FulloverLoader';
import UserList from '../../components/common/UserList';

const serviceId = 27;
function VisaServices({ setShowLoginModal }) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    clearErrors
  } = useForm();

  const current_culture = useSelector(state => state.culture.value);
  let lang = useSelector((state) => state.language.data);

  let myProfile = JSON.parse(localStorage.getItem("myProfile"));

  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState('');
  const [walletData, setWalletData] = useState(null)


  const [date, setDate] = useState(new Date());
  const [phoneCode, setPhoneCode] = useState([])
  const [mobileCode, setMobileCode] = useState(process.env.REACT_APP_MOBCODE);


  const tokenData = useSelector((state) => state.tokenInfo);
  const [location, setLocation] = useState("")
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [serviceDetails, setserviceDetails] = useState();
  const [locationList, setLocationList] = useState([])
  const [purposeType, setPurposeType] = useState("tourism");
  const [noOfEntries, setNoOfEntries] = useState("one")
  const [countryDispName, setCountryDispName] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);

    const serviceDetailsData = getServiceDetailsById(serviceId);
    setserviceDetails(serviceDetailsData)
    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList()

      setPhoneCode(phoneCodeList)
    }
    fetchPhoneCode()
  }, []);
  useEffect(() => {
    //  alert('in')
  }, [onBehalfId]);


  const selectLocationHandler = (value, item, onChange) => {
    setLocation(value)
    setCountryDispName(item._source.displayname)
    onChange(value)

  }
  const locationRequestHandler = async (e) => {
    setLocation(e.target.value);
    const locationResponse = await getCountryOrCity(e.target.value)

    if (locationResponse) {
      console.log("deaprtCityList", locationResponse)

      setLocationList(locationResponse)
    }
  };


  const onSubmit = async (data) => {
    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true)
    } else {
      setIsLoading(true)

      let VisaServices = {
        ServiceID: serviceId,
        /* OnBehalfId: onBehalfId, */
        Data: JSON.stringify({
          name: data.name,
          email: data.email,
          mobile_code: data.mobileCode,
          contact_number: data.contact_number,
          date_and_time_of_service: moment(date).format(
            "DD/MM/YYYY"
          ),
          visiting_country:countryDispName,
          no_of_applicants: data.no_of_applicants,
          purpose_of_visit: purposeType,
          no_of_entries: noOfEntries,
          additional_remarks: data.additional_remarks,
          special_requests: data.special_requests,
          // fieldOrder:'name,email,mobile_code,contact_number,date_of_reservation,time_of_reservation,name_of_restaurant,restaurant_location,no_of_adults,no_of_children,select_course,additional_remarks,special_requests'
        })
      }
      if(onBehalfId) {
        VisaServices["OnBehalfId"] = onBehalfId
      }
      console.log("VisaServices", VisaServices)
      const response = await postServices(VisaServices);
      if (response) {
        dispatch(alertActions.setPopUpType('success'))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: "" }))
        dispatch(alertActions.setPopUpData({ heading: lang.sucess, message: lang.form_submitted_sucessfully }))
        resetForm();
        setIsLoading(false)

      } else {
        dispatch(alertActions.setPopUpType('error'))
        dispatch(alertActions.setPopUpData({ heading: lang.failed, message: lang.something_went_wrong_please_try_again }))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))

        setIsLoading(false)
      }
    }

  }
  const resetForm = () => {
    reset()
    setMobileCode(process.env.REACT_APP_MOBCODE)
    setOnBehalfId('')
    setValue('OnBehalfId', "")
    setUserText('')
    setLocation("")
    setDate(new Date())
    setCountryDispName("")
    setWalletData(null)
  }
  return (
    <React.Fragment>
      {
        isLoading &&
        <FulloverLoader />
      }
      <InnerBanner
        title={lang.visa_and_insurance_service}
      ></InnerBanner>
      <section className='content_service_main form_bg'>
        <div class='container'>
          <div className='form_breadcrumbs'>
            <ul>
              <li><Link to={process.env.REACT_APP_BASE_URL + 'services'}>{lang.services} </Link></li>
              <li><a className='active' href=''>{lang.visa_and_insurance_service}</a></li>
            </ul>
          </div>
          <div className='service_box'>
            {
              serviceDetails &&
              <ServiceBoxLeft
                serviceDetails={serviceDetails}
              />
            }
            <div className='service_box_col_rht'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='service_detail_formbox formstyle'>
                  <div className='service_detail_formhd'>
                    <h2>{lang.feel_like_contacting_us}</h2>
                    <p>{lang.submit_the_form_below_we_will_get_back_to_you_asap}</p>
                  </div>
                  <hr className='form_hd_hr'>
                  </hr>
                  {(myProfile && myProfile.isAgentLogin && !tokenData.data.isDefaultUser) &&
                    <UserList
                      register={register}
                      onBehalfId={onBehalfId}
                      setOnBehalfId={setOnBehalfId}
                      errors={errors}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      control={control}
                      userText={userText}
                      setUserText={setUserText}
                      walletData={walletData}
                      setWalletData={setWalletData}

                    />
                  }


                  <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.name}<span>*</span></label>
                        <input type="text" class="form-control"

                          {...register('name', {
                            required: lang.name_is_required,
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,

                            },
                            pattern: {
                              value: /[A-Za-z]/,
                              message: lang.please_enter_valid_name,
                            },
                          })}
                        >
                        </input>
                        <ErrorMessage
                          errors={errors}
                          name={'name'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.email}<span>*</span></label>
                        <input type="text" class="form-control"

                          {...register('email', {
                            required: lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: lang.invalid_email_address,
                            },
                          })}
                        >
                        </input>
                        <ErrorMessage
                          errors={errors}
                          name={'email'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.contact_number}<span>*</span></label>
                        <div class="form-group d-flex flex-wrap">
                          <div class="phonecode form_phone_select_arrow position-relative">
                            <select class="form-control"
                              {...register('mobileCode', {
                                required: {
                                  value: true,
                                  message:
                                    lang.phone_code_is_required,
                                },
                                onChange: (e) => {
                                  setMobileCode(e.target.value)
                                }, value: mobileCode
                              })}
                            >
                              {phoneCode.map((item, key) => {
                                return <option selected={mobileCode == item ? 'selected' : ''} value={item}>{item}</option>
                              })}
                            </select>
                          </div>
                          <div class="phonenumber">

                            <input type="text" class="form-control" placeholder=" "

                              {...register('contact_number', {
                                required: {
                                  value: true,
                                  message:
                                    lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message:
                                    lang.please_enter_atleast_six_numbers,
                                },

                                onChange: e => {

                                },
                              })}
                            >
                            </input>
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={'contact_number'}
                            render={({ message }) => (
                              <span className='error-message'>{message}</span>
                            )}
                          />
                        </div>
                      </div>
                    </div>


                    <div className='col-lg-6 col-md-12'>
                      <div class="form-group form_select_arrow DisplayinRmv  autocomplete">
                        <label> {lang.visiting_country}<span>*</span></label>
                        <Controller
                          name={"location"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <Autocomplete
                              getItemValue={(item) => current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                              items={locationList}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  className={
                                    isHighlighted
                                      ? " react-autocomplete-inner active "
                                      : "react-autocomplete-inner"
                                  }
                                >
                                  {current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                                </div>
                              )}
                              {...register("location", {
                                required: {
                                  value: true,
                                  message: lang.please_select_a_location,
                                },
                              })}
                              onChange={(event) => {locationRequestHandler(event)
                                event.target.value == "" && onChange(event.target.value)}}
                              onSelect={(label, item) => selectLocationHandler(label, item, onChange)}
                              value={location}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={'location'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group form_select_arrow ">
                        <label>
                          {lang.please_select_the_service}<span>*</span>
                        </label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          // value={selectedService}
                          {...register("service", {
                            required: {
                              value: true,
                              message: lang.please_select_the_service,
                            },
                            // onChange: (event)=> setSelectedService(event.target.value)
                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="Visa Application Form">{lang.visa_application_form}</option>
                          <option value="VIP Visa Application Form">{lang.vip_visa_application_form}</option>
                          <option value="Biometric Services">{lang.biometric_services}</option>
                          <option value="Insurance">{lang.insurance}</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={"service"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group form_select_arrow">
                        <label>
                          {lang.tendative_travel_date}<span>*</span>
                        </label>
                        <DatePicker
                          minDate={new Date()}
                          locale={current_culture}
                          dateFormat={"dd/MM/yyyy"}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown={true}
                          selected={date}
                          onChange={(value) => {
                            setDate(value);
                          }}
                        />
                      </div>
                    </div>

                    <div className='col-lg-6 col-md-12'>
                      <div class="form-group form_select_arrow">
                        <label>{lang.number_of_applicants}<span>*</span></label>
                        <select name="no_of_adults" id="" class="form-control" {...register('no_of_applicants', {})}>
                          <option value="1"> 1 </option>
                          <option value="2"> 2 </option>
                          <option value="3"> 3 </option>
                          <option value="4"> 4 </option>
                          <option value="5"> 5 </option>
                          <option value="6"> 6 </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12  pt-3'>
                      <label> {lang.purpose_of_visit} <span></span></label>
                      <div className="form-group radio-outer">
                        <label className="tripradio">
                          {lang.tourism}
                          <input
                            type="radio"
                            class="form-control"
                            name="flight-trip"
                            defaultChecked={true}
                            onChange={(e) => setPurposeType("tourism")}
                          ></input>
                          <span class="checkmark"></span>
                        </label>
                        <label className="tripradio">
                          {lang.business}
                          <input
                            type="radio"
                            class="form-control"
                            name="flight-trip"
                            onChange={(e) => setPurposeType("business")}
                          ></input>
                          <span class="checkmark"></span>
                        </label>
                        <label className="tripradio">
                          {lang.employment}
                          <input
                            type="radio"
                            class="form-control"
                            name="flight-trip"
                            onChange={(e) => setPurposeType("employment")}
                          ></input>
                          <span class="checkmark"></span>
                        </label>
                        <label className="tripradio">
                          {lang.study}
                          <input
                            type="radio"
                            class="form-control"
                            name="flight-trip"
                            onChange={(e) => setPurposeType("study")}
                          ></input>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr class="mt-1 mb-3"></hr>

                  <div className='row'>
                    <div className='col-lg-12  pt-2'>
                      <label>{lang.Intended_number_of_entries} <span></span></label>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div className="form-group radio-outer">
                        <label className="tripradio">
                          {lang.one}
                          <input
                            type="radio"
                            class="form-control"
                            name="no_of_entries"
                            defaultChecked={true}
                            onChange={(e) => setNoOfEntries("one")}
                          ></input>
                          <span class="checkmark"></span>
                        </label>
                        <label className="tripradio">
                          {lang.two}
                          <input
                            type="radio"
                            class="form-control"
                            name="no_of_entries"
                            onChange={(e) => setNoOfEntries("two")}
                          ></input>
                          <span class="checkmark"></span>
                        </label>
                        <label className="tripradio">
                          {lang.multiple}
                          <input
                            type="radio"
                            class="form-control"
                            name="no_of_entries"
                            onChange={(e) => setNoOfEntries("multiple")}
                          ></input>
                          <span class="checkmark"></span>
                        </label>

                      </div>
                    </div>
                  </div>


                  <div className='row'>
                    <div className='col-lg-12 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.additional_information}</label>
                        <textarea class="form-control" name="" rows="" cols=""
                          {...register('additional_remarks', {
                          })}
                        ></textarea>
                      </div>
                    </div>


                    <div className='col-lg-12 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.special_requests}</label>
                        <textarea class="form-control" name="" rows="" cols=""
                          {...register('special_requests', {
                          })}
                        ></textarea>
                      </div>
                    </div>


                    <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                      <button type="submit" class="form_submit_btn">{lang.submit}</button>
                    </div>
                    {
                      tokenData.data.isDefaultUser &&
                      <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                        <p className='form_note'>{lang.note_now_you_are_on_guest_user_please} <a href='#' onClick={() => setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}</p>
                      </div>
                    }
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section >
    </React.Fragment >
  )
}
export default VisaServices;