import React from "react";
import fournotfourimg from '../../assets/images/404.png';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Login from "./login";
import Register from "./register";
import Forgot from "./forgot";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { getTokenDataInfo } from "../../redux/getBearortoken";


export default function FulloverLoader() {
  const dispatch = useDispatch();
  let lang = useSelector((state) => state.language.data);

  const [showItem, setShowItem] = useState(false);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const tokenData = useSelector((state) => state.tokenInfo);
  const logout = () => {
    localStorage.removeItem('token')
    window.location.reload();
  }
  return (


    <div className="full_overlay d-flex justify-content-center align-items-center flex-column">
      <div className="loader-lg">
        <div className="loader-lg-dot" />
        <div className="loader-lg-dot" />
        <div className="loader-lg-dot" />
        <div className="loader-lg-dot" />
        <div className="loader-lg-dot" />
        <div className="loader-lg-dot" />
      </div>
      <p className="font-16 mt-5">{lang.please_wait}</p>
    </div>


  )
}