import React,{useEffect,useState} from "react";
import InnerBanner from "../components/common/inner_banner";
import { useSelector } from "react-redux";
import { getWalletData } from "../services/common";
import moment from "moment";

function WalletDetails({ setShowLoginModal ,showRegisterModal}) {
  let lang = useSelector((state) => state.language.data);
  const tokenData = useSelector((state) => state.tokenInfo);
  
  const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("myProfile")))
  const [walletData, setWalletData] = useState("")
  
  if (tokenData.data.isDefaultUser && !showRegisterModal) {
   
    setShowLoginModal(true);
  }

  useEffect(() => {
    

    if(!tokenData.data.isDefaultUser) {

      setProfile( JSON.parse(localStorage.getItem("myProfile")))
      
      
      const fetchWalletData = async () => {
        const walletRes = await getWalletData()
        
        localStorage.setItem("walletData", JSON.stringify(walletRes))
        setWalletData(walletRes)

        
      }

      fetchWalletData()

    }

  
  }, [tokenData.data.isDefaultUser])

  console.log("wallet",walletData)
  return (
    <React.Fragment>
      <InnerBanner title={"Wallet"}></InnerBanner>
      <section className="wallet_details_main ">
        <div class="container">
          {tokenData.data.isDefaultUser ? (
            <div className="wallet_details_box">
              <h2>{lang.login_to_view_wallet}</h2>
            </div>
          ) : (
            <div className="wallet_details_box">
              <h2>{lang.transaction_history}</h2>

              <div className="row">
                <div className="col-lg-4">
                  <div className="wallet_amount_box">
                    <h3>{lang.balance_amount}</h3>
                    <p>
                      <span>KWD </span>
                      <span>{profile.wallet}</span>
                    </p>
                  </div>
                </div>

                {walletData ? <div className="col-lg-12">


                <div className="table-responsive">
                  <table id="wallettable">
                    <tbody>
                      <tr>
                        <th>{lang.enquiry_id}</th>
                        <th>{lang.date}</th>
                        <th>{lang.wallet_service_type}</th>
                        <th>{lang.wallet_trans_amount}</th>
                        <th>{lang.wallet_remarks}</th>
                      </tr>

                      { walletData.map((item,index)=>{
                        return (
                          <tr>
                        <td>{item.enquiryID}</td>
                        <td>{moment(item.modifiedDate).format("DD/MM/yyyy")}</td>
                        <td>{item.serviceName}</td>
                        <td>
                          <table className="walet_inr_table">
                            <tr>
                              <td>{item.amount}</td>
                              <td>KWD</td>
                              <td>{item.isTopUp ? "(Cr)" : "(Dr)"}</td>
                            </tr>
                          </table>
                        </td>
                        <td>{item.remarks}</td>
                      </tr>
                        )
                      })}

                    </tbody>
                  </table>
                  </div>


                </div> : <div>
                  Loading...
                  </div>}
              </div>
            </div>
          )}
        </div>
      </section>
    </React.Fragment>
  );
}

export default WalletDetails;
