import React, { useState, useEffect } from 'react';

import InnerBanner from '../../components/common/inner_banner';

import RoadsideAssistanceServiceDetails from "../../assets/images/roadside_assistance_servicedetail.jpg"

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { getServiceDetailsById } from '../../scripts/common';
import ServiceBoxLeft from './ServiceBoxLeft';
import { useNavigate } from 'react-router-dom';
import { getAirportList, getTimeData, postServices, setPhoneCodeList } from '../../services/common'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";

import {  Link} from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import FulloverLoader from '../../components/common/FulloverLoader';
import UserList from '../../components/common/UserList';
import ar from "date-fns/locale/ar"; // the locale you want

const serviceId = 16;
function RoadsideAssistance({ setShowLoginModal }) {
  const current_culture = useSelector(state => state.culture.value);
  let lang = useSelector((state) => state.language.data);
  registerLocale("ar", ar);
  let myProfile = JSON.parse(localStorage.getItem("myProfile"));
  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState('');
  const [walletData, setWalletData] = useState(null)
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm();
  const [phoneCode, setPhoneCode] = useState([])
  const [mobileCode, setMobileCode] = useState(process.env.REACT_APP_MOBCODE);

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const tokenData = useSelector((state) => state.tokenInfo);
  const [BusinessBankingServiceDetailsParams, setBusinessBankingServiceDetailsParams] = useState({
    name: '',
    email: '',
    phoneCode: '',
    contact_number: '',
    service: '',
    additionalInformation: ''

  })
  const [date, setDate] = useState();
  let navigate = useNavigate();
  const [serviceDetails, setserviceDetails] = useState();
  const [serviceType, setServiceType] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);

    const serviceDetailsData = getServiceDetailsById(serviceId);
    setserviceDetails(serviceDetailsData)
    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList()

      setPhoneCode(phoneCodeList)
    }

    fetchPhoneCode()
  }, []);
  const onSubmit = async (data) => {
    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true)
    } else {
      setIsLoading(true)
      let roadassistance = {
        ServiceID: serviceId,
        /* OnBehalfId: onBehalfId, */
        Data: JSON.stringify({
          name: data.name,
          email: data.email,
          contact_number: data.contact_number,
          mobile_code: data.mobileCode,
          service_type: data.service_type,
          select_other_specifically: data.select_other_specifically ?? '',
          date_of_service: moment(data.date_of_service).format(
            "DD/MM/YYYY"
          ),
          time_of_service: data.time_of_service,
          additional_information: data.additional_information,
          // fieldOrder:'name,email,mobile_code,contact_number,service_type,select_other_specifically,date_of_service,time_of_service,additional_information'
        })
      }
      if(onBehalfId) {
        roadassistance["OnBehalfId"] = onBehalfId
      }

      const response = await postServices(roadassistance);
      if (response) {
        dispatch(alertActions.setPopUpType('success'))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl:"" }))
        dispatch(alertActions.setPopUpData({ heading: lang.sucess, message: lang.form_submitted_sucessfully }))
        resetForm()
        setIsLoading(false)


      } else {
        dispatch(alertActions.setPopUpType('error'))
        dispatch(alertActions.setPopUpData({ heading: lang.failed, message: lang.something_went_wrong_please_try_again }))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))

        setIsLoading(false)

      }

    }

  }
  const resetForm=()=>{
    reset()
    setServiceType("")
    setMobileCode(process.env.REACT_APP_MOBCODE)
    setOnBehalfId("")
    setValue('OnBehalfId',"")
    setUserText('')
    setWalletData(null)
    
  }
  return (
    <React.Fragment>
      {
        isLoading &&
        <FulloverLoader />
      }
      <InnerBanner title={lang.road_assist}></InnerBanner>
      <section className='content_service_main form_bg'>
        <div class='container'>
          <div className='form_breadcrumbs'>
            <ul>
               <li><Link to={process.env.REACT_APP_BASE_URL+'services'}>{lang.services} </Link></li>
              <li><a className='active' href=''>{lang.road_assist}</a></li>
            </ul>
          </div>
          <div className='service_box'>
            {
              serviceDetails &&
              <ServiceBoxLeft
                serviceDetails={serviceDetails}
              />
            }
            <div className='service_box_col_rht'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='service_detail_formbox formstyle'>
                  <div className='service_detail_formhd'>
                    <h2>{lang.feel_like_contacting_us}</h2>
                    <p>{lang.submit_the_form_below_we_will_get_back_to_you_asap}</p>
                  </div>
                  <hr className='form_hd_hr'>
                  </hr>
                  {(myProfile && myProfile.isAgentLogin && ! tokenData.data.isDefaultUser) &&
                    <UserList
                      register={register}
                      onBehalfId={onBehalfId}
                      setOnBehalfId={setOnBehalfId}
                      errors={errors}
                      control={control}
                      userText={userText}
                      setUserText={setUserText}
                      walletData={walletData}
                      setWalletData={setWalletData}

                    />
                  }
                  <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.name}<span></span></label>
                        <input type="text" class="form-control"

                          {...register('name', {
                            required: lang.name_is_required,
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,

                            },
                            pattern: {
                              value: /[A-Za-z]/,
                              message: lang.please_enter_valid_name,
                            },
                          })}
                        >
                        </input>
                        <ErrorMessage
                          errors={errors}
                          name={'name'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.email}<span></span></label>
                        <input type="text" class="form-control"

                          {...register('email', {
                            required: lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: lang.invalid_email_address,
                            },


                          })}
                        >
                        </input>
                        <ErrorMessage
                          errors={errors}
                          name={'email'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.contact_number}<span></span></label>
                        <div class="form-group d-flex flex-wrap">
                          <div class="phonecode form_phone_select_arrow position-relative">
                            <select class="form-control"
                              {...register('mobileCode', {
                                required: {
                                  value: true,
                                  message:
                                    lang.phone_code_is_required,
                                },
                                onChange: (e) => {
                                  setMobileCode(e.target.value)
                                }, value: mobileCode
                              })}
                            >
                              {phoneCode.map((item, key) => {
                                return <option selected={mobileCode == item ? 'selected' : ''} value={item}>{item}</option>
                              })}
                            </select>
                          </div>
                          <div class="phonenumber">

                            <input type="text" class="form-control" placeholder=" "

                              {...register('contact_number', {
                                required: {
                                  value: true,
                                  message:
                                    lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message:
                                    lang.please_enter_atleast_six_numbers,
                                },

                                onChange: e => {

                                },
                              })}
                            >
                            </input>
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={'contact_number'}
                            render={({ message }) => (
                              <span className='error-message'>{message}</span>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group form_select_arrow'>
                        <label>{lang.please_select_the_service_type}<span></span></label>
                        <select name="" id="" class="form-control"

                          {...register('service_type', {
                            required: lang.service_type_is_required,
                            onChange: (e) => {
                              setServiceType(e.target.value)
                            }
                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="Towing Service">{lang.towing_service}</option>
                          <option value="Mechanical Repair Service">{lang.mechanical_repair_service}</option>
                          <option value="Battery Boosting Service">{lang.battery_boosting_service}</option>
                          <option value="Flat Tire Service">{lang.flat_tyre_service}</option>
                          <option value="Emergency Fuel Service">{lang.emergency_fuel_service}</option>
                          <option value="Lock-out Service">{lang.lock_out_service}</option>
                          <option value="Off Road Recovery Service">{lang.off_road_recovery_service}</option>
                          <option value="Other Services">{lang.other_service}</option>

                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={'service_type'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>
                    </div>

                    {serviceType == "Other Services" &&
                      <div className='col-lg-12 col-md-12'>
                        <div className='form-group'>
                          <label>{lang.if_you_have_selected_other_please_specify}<span>*</span></label>
                          <textarea class="form-control" name="" rows="" cols=""

                            {...register('select_other_specifically', {
                              required: lang.service_description_is_required,

                            })}
                          ></textarea>
                          <ErrorMessage
                            errors={errors}
                            name={'select_other_specifically'}
                            render={({ message }) => (
                              <span className='error-message'>{message}</span>
                            )}
                          />
                        </div>


                      </div>
                    }
                    <div class="col-lg-8 col-md-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12">
                          <label>{lang.date_and_time_of_the_service}<span></span></label>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <Controller
                              name={'date_of_service'}
                              control={control}
                              render={({
                                field: { onChange, value },
                              }) => (
                                <DatePicker
                                  minDate={new Date()}
                                  dateFormat={'dd/MM/yyyy'}
                                  locale={current_culture}
                                  className="form-control"
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown={true}
                                  showYearDropdown={true}
                                  selected={value}
                                  onChange={date => {
                                    
                                    onChange(date)
                                    setDate(new Date(date))
                                  }}
                                  value={date}
                                />
                              )}
                              {...register('date_of_service', {
                                required: lang.date_is_required,
                                value: date,
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={'date_of_service'}
                              render={({ message }) => (
                                <span className='error-message'>{message}</span>

                              )}
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <select name="" id="" class="form-control"
                              {...register('time_of_service')}
                            >
                              {getTimeData.map((item) => {
                                return (
                                  <option value={item}>{item}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.additional_information}</label>
                        <textarea class="form-control" name="" rows="" cols=""
                          {...register('additional_information', {

                          })}
                        ></textarea>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                      <button type="submit" class="form_submit_btn">{lang.submit}</button>
                    </div>
                    {
                      tokenData.data.isDefaultUser &&
                      <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                        <p className='form_note'>{lang.note_now_you_are_on_guest_user_please} <a href='#' onClick={() => setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}</p>
                      </div>
                    }
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section >
    </React.Fragment >
  )
}
export default RoadsideAssistance;