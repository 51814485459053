import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
    name: "alertStatus",
    initialState: {
        popUpType: '',
        redirect: false,
        redirectUrl: "",
        heading: '',
        message: ''
    },
    reducers: {
        setPopUpType: (state, action) => {
            state.popUpType = action.payload
        },
        setRedirect: (state, action) => {
            state.redirect = action.payload.redirect
            state.redirectUrl = action.payload.redirectUrl
        },
     
        setPopUpData: (state, action) => {
            state.heading = action.payload.heading
            state.message = action.payload.message
        }
    }
})

export default alertSlice.reducer
export const alertActions = alertSlice.actions