import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage } from '@hookform/error-message'
import FulloverLoader from "./FulloverLoader";
import Modal from 'react-bootstrap/Modal';
import qs from 'qs'
import Button from 'react-bootstrap/Button';
import { changePassword } from "../../services/common";
import { alertActions } from "../../redux/alertSlice";
import { Buffer } from 'buffer';
import { useSearchParams } from "react-router-dom";

export default function CHangePassword() {
let test = process.env.REACT_APP_BASE_URL



  let lang = useSelector((state) => state.language.data);
  const current_culture = useSelector(state => state.culture.value)
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const [showLoginModal, setShowLoginModal] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [searchQueryParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({ criteriaMode: "all" });
  useEffect(() => {

    let urlParams = searchQueryParams.get("info").replace("_","/").replace("-","+")
    const encoded = JSON.parse(Buffer.from(urlParams, 'base64').toString('utf-8'))
    /* debugger
    
    let encoded = JSON.parse(Object.keys(qs.parse(decodeURI(window.location.href).split('changePassword/')[1]))[0]) */
    setUserData(encoded)

  }, []);
  const onSubmit = async (data) => {
    setisLoading(true)
    const userInfo = {
      userID: userData.UserID,
      email: userData.Email,
      password: data.newPassword,
      culture: current_culture,
    };
    const passwordRes = await changePassword(userInfo);
    if (passwordRes && passwordRes.data == "password_changed") {
      setisLoading(false)

      dispatch(alertActions.setPopUpType("success"));
      dispatch(
        alertActions.setRedirect({
          redirect: true,
          redirectUrl: process.env.REACT_APP_BASE_URL,
        })
      );
      dispatch(
        alertActions.setPopUpData({
          heading: lang.sucess,
          message: lang.password_updated,
        })
      );
    } else {
      dispatch(alertActions.setPopUpType("error"));
      dispatch(
        alertActions.setPopUpData({
          heading: lang.failed,
          message: lang.something_went_wrong_please_try_again,
        })
      );
      dispatch(
        alertActions.setRedirect({
          redirect: true,
          redirectUrl: process.env.REACT_APP_BASE_URL,
        })
      );
    }
  }


  return (
    <>
      {isLoading && <FulloverLoader />}
      <Modal show={showLoginModal}
         onHide={() => setShowLoginModal(false)} 
        className="log_reg_popup">
        <Modal.Header closeButton>
          <Modal.Title>{lang.change_password}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>

            <div className='log-field'>
              <label>
                {lang.new_password}<span></span>
              </label>
              <input
                type="password"
                name="newPassword"
                class="form-control"
                {...register("newPassword", {
                  required: {
                    value: true,
                    message: lang.enter_new_password,
                  },
                  minLength: {
                    value: 8,
                    message:
                      lang.password_required_pattern,
                  },
                  pattern: {
                    value:
                      /^[a-zA-Z0-9!@#$%^&*()_=\[\]{};':"\\|\\~\\`,.<>\/?+-]+$/ &&
                      /[a-zA-Z]/ &&
                      /^[0-9]+$/ &&
                      /[!@#$%^&*()_=\[\]{};':"\\|\\~\\`,.<>\/?+-]/,
                    message:
                      lang.password_required_pattern,
                  },
                  validate: (value) =>
                    !(value === getValues().oldPassword) ||
                    lang.new_pswd_cant_same_as_old_pswd
                })}
              ></input>
              <ErrorMessage
                errors={errors}
                name={"newPassword"}
                render={({ message }) => (
                  <span className="error-message">{message}</span>
                )}
              />
            </div>
            <div className='log-field'>
              <label>
                {lang.confirm_password}<span></span>
              </label>
              <input
                type="password"
                name="confirm_pwd"
                class="form-control"
                {...register("confirm_pwd", {
                  required: {
                    value: true,
                    message: lang.confirm_password_required,
                  },
                  validate: (value) =>
                    value === getValues().newPassword ||
                    lang.password_mismatch,
                })}
              ></input>
              <ErrorMessage
                errors={errors}
                name={"confirm_pwd"}
                render={({ message }) => (
                  <span className="error-message">{message}</span>
                )}
              />
            </div>


          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="login" onClick={handleSubmit(onSubmit)}>
            {lang.update}
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}
