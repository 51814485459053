import React, { useState, useEffect } from 'react';
import qs from 'qs';
import InnerBanner from '../../components/common/inner_banner';
import { useNavigate } from "react-router-dom";
import PersonalAssistancServiceDetails from "../../assets/images/personalassistance_servicedetail.jpg"

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ServiceBoxLeft from './ServiceBoxLeft';
import { getServiceDetailsById } from '../../scripts/common';
import { postServices, setPhoneCodeList } from '../../services/common';

import { alertActions } from "../../redux/alertSlice";
import FulloverLoader from '../../components/common/FulloverLoader';
import UserList from '../../components/common/UserList';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
const serviceId = 22;

function AllocationAndPets({ setShowLoginModal }) {
    let lang = useSelector((state) => state.language.data);
    const [serviceDetails, setserviceDetails] = useState();
    useEffect(() => {
        window.scrollTo(0, 0);
        const serviceDetailsData = getServiceDetailsById(serviceId);
        setserviceDetails(serviceDetailsData)
    }, [])
    return (

        <React.Fragment>
            <InnerBanner title={lang.allocation_and_pets}></InnerBanner>

            <section className='content_service_main form_bg'>
                <div class='container'>
                    <div className='form_breadcrumbs'>
                        <ul>
                            <li><Link to={process.env.REACT_APP_BASE_URL + 'services'}>{lang.services} </Link></li>
                            <li><a className='active' href=''>{lang.allocation_and_pets}</a></li>
                        </ul>
                    </div>
                    <div className='service_box'>
                        {
                            serviceDetails &&
                            <ServiceBoxLeft
                                serviceDetails={serviceDetails}
                            />
                        }
                        <div className='service_box_col_rht'>
                            <form>
                                <div className='service_detail_formbox formstyle'>
                                    <div className='service_detail_formhd'>
                                        <h2>{lang.feel_like_contacting_us}</h2>
                                        {/* <p>{lang.submit_the_form_below_we_will_get_back_to_you_asap}</p> */}
                                    </div>
                                    <hr className='form_hd_hr'>
                                    </hr>
                                    <h3><span>{lang.phone}</span> : <span>{lang.contact_us_phone}</span></h3>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section >


        </React.Fragment >

    )
}
export default AllocationAndPets;