import React from "react";
import { useSelector } from "react-redux";


export default function HomeAboutProject(){


    let lang = useSelector((state) => state.language.data);

    return(
        <section className="home-about-project">
            <div className="container">
                <h2>{lang.home_about_head }</h2>
                <p>{lang.home_about_para_1}</p>
                <p>{lang.home_about_para_2}</p>
                <p>{lang.home_about_para_3}</p>
                <div className="list-section">
                    <ul>
                        <li>{lang.home_about_bullets_1}</li>
                        <li>{lang.home_about_bullets_2}</li>
                        <li>{lang.home_about_bullets_3} </li>
                        <li>{lang.home_about_bullets_4}</li>
                        <li>{lang.home_about_bullets_5}</li>
                        <li>{lang.home_about_bullets_6}</li>
                        <li>{lang.home_about_bullets_7}</li>
                        <li>{lang.home_about_bullets_8}</li>
                        <li>{lang.home_about_bullets_9} </li>
                    </ul>
                    <div className="abt-project-img-box">
                        <div className="img-box-parent">
                            <div className="child-box"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}