import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { getCmsData } from "../../services/common";

export default function ContactUs(){

    let lang = useSelector((state) => state.language.data);
    const [description, setDescription] = useState("")

    useEffect(()=> {
        window.scrollTo(0,0)

       /*  const fetchData = async() => {

            const cmsResponse = await getCmsData("ContactUs")
            setDescription(cmsResponse.description)

            console.log(cmsResponse)
        } 
        fetchData() */
        
    },[])

    return(
        <section className="cms_content_area">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 mb-4">
                        <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3477.1296199151475!2d47.9688135!3d29.3664937!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3fcf84dd2dc85a47%3A0xa7b5c8853dde5eb8!2sQ&#39;go%20Group!5e0!3m2!1sen!2sin!4v1673352181339!5m2!1sen!2sin"
                        width="100%"
                        height={385}
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        />
                    </div>
                    <div className="col-md-4">
                        <div className="contactAddrs">
                            <div className="contaddBox mb-4">
                                <h3>{lang.corporate_office}</h3>
                                <div className="d-flex justify-content-between">
                                    <div className="col-lg-8 col-9 p-0">
                                        <p>{lang.contact_us_address}</p>
                                    </div>
                                    <div className="col-lg-4 col-3 p-0 d-flex justify-content-end">
                                        <i className="contOfficeIcon" />
                                    </div>
                                </div>
                            </div>
                            <div className="contaddBox mb-4">
                                <h3>{lang.contact_us}</h3>
                                <div className="d-flex justify-content-between">
                                    <div className="col-lg-9 col-9 p-0">
                                        <p>{lang.email} : {lang.contact_us_email}</p>
                                        <p>{lang.phone} : <br></br>
                                        {lang.contact_us_phone}<br></br>
                                        {lang.contact_us_phone2}
                                        </p>
                                    </div>
                                    <div className="col-lg-3 col-3 p-0 d-flex justify-content-end">
                                        <i className="contmailIcon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}