import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import ar from "date-fns/locale/ar"; // the locale you want
import { Link } from 'react-router-dom';

import InnerBanner from "../../components/common/inner_banner";

import { getServiceDetailsById } from "../../scripts/common";
import ServiceBoxLeft from "./ServiceBoxLeft";
import { getSports, getSportStadiums, postServices } from "../../services/common";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import { getCountryOrCity, setPhoneCodeList } from "../../services/common";

import SportsNCulturalEventsDetail from "../../assets/images/sports_events_service.jpg";
import FulloverLoader from "../../components/common/FulloverLoader";
import UserList from '../../components/common/UserList';

function SportsNCulturalEvents({ setShowLoginModal }) {

  const tokenData = useSelector((state) => state.tokenInfo);
  let lang = useSelector((state) => state.language.data);
  const current_culture = useSelector(state => state.culture.value);
  registerLocale("ar", ar);
  let myProfile = JSON.parse(localStorage.getItem("myProfile"));

  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState('');
  const [walletData, setWalletData] = useState(null)
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [serviceDetails, setServiceDetails] = useState();
  const [serviceId, setServiceId] = useState(18);
  const [City, setCity] = useState("")
  const [cityList, setCityList] = useState([])
  const [cityLabel, setCityLabel] = useState("")

  const [Sports, setSports] = useState("")
  const [SportsList, setSportsList] = useState([])
  const [SportsLabel, setSportsLabel] = useState("")

  const [venue, setVenue] = useState("")
  const [venueList, setVenueList] = useState([])
  const [phoneCode, setPhoneCode] = useState([])
  const [mobCode, setMobCode] = useState(process.env.REACT_APP_MOBCODE)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {

    window.scrollTo(0, 0)
    const serviceDetailsData = getServiceDetailsById(serviceId);
    setServiceDetails(serviceDetailsData);

    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList()

      setPhoneCode(phoneCodeList)
    }

    fetchPhoneCode()
  }, []);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm();

  const cityRequestHandler = async (e) => {
    setCity(e.target.value);

    const cityResponse = await getCountryOrCity(e.target.value)

    if (cityResponse) {

      setCityList(cityResponse)
    }
  };
  const sportsRequestHandler = async (e) => {
    setSports(e.target.value);

    const SportsResponse = await getSports(e.target.value, current_culture)
    if (SportsResponse) {
      let temp = [];
      SportsResponse.map((sportsItem, key) => {
        
        if (sportsItem._source.searchtype == "1") {
          if (current_culture == "en")
            temp.push({
              label: sportsItem._source.caption,
              key: key
            })
          else
            temp.push({
              label: sportsItem._source.captionarabic,
              key: key
            })
        }
      })
      setSportsList(temp)
    }
  };

  const selectCityHandler = (value, item, onChange) => {
    setCity(value)
    setCityLabel(item._source.displayname)
    onChange(value)

  }
  const selectSportsHandler = (value, item, onChange) => {
    setSports(value)
    setSportsLabel(item.label)
    onChange(value)

  }

  const venueRequestHandler = async (e) => {
    setVenue(e.target.value);

    const venueResponse = await getSportStadiums(e.target.value)

    console.log("stadium", venueResponse);

    if (venueResponse) {

      setVenueList(venueResponse)
    }
  }

  const selectVenueHandler = (value, onChange) => {
    setVenue(value)
    onChange(value)
  }

  const resetForm = () => {
    reset()
    setMobCode(process.env.REACT_APP_MOBCODE)
    setCity("")
    setCityLabel("")
    setFromDate(new Date())
    setToDate(new Date())
    setVenue("")
    setVenueList([])
    setCityList([])
    setOnBehalfId("")
    setValue('OnBehalfId', "")
    setUserText('')
    setWalletData(null)
  }

  const onSubmit = async (data) => {

    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true)
    } else {

      setIsLoading(true)
      console.log(data);

      let sportsAndEventsParams = {
        ServiceID: serviceId,
        /* OnBehalfId: onBehalfId, */
        Data: JSON.stringify({
          full_name: `${data.firstName} ${data.lastName}`,
          email: data.email,
          mobile_code: data.phoneCode,
          contact_number: data.mobileno,
          select_sport_event: SportsLabel,
          country_city: cityLabel,
          venue_stadium: data.stadium,
          number_of_attendee: data.attendeecount,
          from: Moment(fromDate).format("DD/MM/YYYY"),
          to: Moment(toDate).format("DD/MM/YYYY"),
          additional_information: data.additionalInfo,
          special_requests: data.specialReq,
        }),
      };

      if(onBehalfId) {
        sportsAndEventsParams["OnBehalfId"] = onBehalfId
      }
      console.log(sportsAndEventsParams);
      const response = await postServices(sportsAndEventsParams);


      if (response) {
        dispatch(alertActions.setPopUpType('success'))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
        dispatch(alertActions.setPopUpData({
          heading: lang.sucess,
          message: lang.form_submitted_sucessfully,
        }))

        resetForm()
        /* alert('form submited')
      navigate(process.env.REACT_APP_BASE_URL, { replace: true }); */
        setIsLoading(false)
      } else {
        dispatch(alertActions.setPopUpType('error'))
        dispatch(alertActions.setPopUpData({
          heading: lang.failed,
          message: lang.something_went_wrong_please_try_again,
        }))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
        /* alert('something went wrong ,please try again') */
        setIsLoading(false)
      }

    }
  };

  return (
    <React.Fragment>
      {isLoading && <FulloverLoader />}

      <InnerBanner title={lang.sports_and_cultural_events}></InnerBanner>

      <section className="content_service_main form_bg">
        <div class="container">
          <div className="form_breadcrumbs">
            <ul>
              <li>
                <Link to={process.env.REACT_APP_BASE_URL + 'services'}>{lang.services} </Link>
              </li>
              <li>
                <a className="active" href="">
                  {lang.sports_and_cultural_events}
                </a>
              </li>
            </ul>
          </div>
          <div className="service_box">
            {serviceDetails && (
              <ServiceBoxLeft serviceDetails={serviceDetails} />
            )}
            <div className="service_box_col_rht">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="service_detail_formbox formstyle">
                  <div className="service_detail_formhd">
                    <h2>{lang.feel_like_contacting_us}</h2>
                    <p>
                      {lang.submit_the_form_below_we_will_get_back_to_you_asap}
                    </p>
                  </div>
                  <hr className="form_hd_hr"></hr>
                  {(myProfile && myProfile.isAgentLogin && !tokenData.data.isDefaultUser) &&
                    <UserList
                      register={register}
                      onBehalfId={onBehalfId}
                      setOnBehalfId={setOnBehalfId}
                      errors={errors}
                      control={control}
                      userText={userText}
                      setUserText={setUserText}
                      walletData={walletData}
                      setWalletData={setWalletData}

                    />
                  }
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group form_select_arrow DisplayinRmv autocomplete">
                        <label>
                          {lang.select_sports_event}<span>*</span>
                        </label>


                        <Controller
                          name={"sports"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <Autocomplete
                              getItemValue={(item) => item.label}
                              items={SportsList}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  className={
                                    isHighlighted
                                      ? " react-autocomplete-inner active "
                                      : "react-autocomplete-inner"
                                  }
                                >
                                  {item.label}
                                </div>
                              )}
                              {...register("sports", {
                                required: {
                                  value: true,
                                  message: lang.pls_select_a_sports_event,
                                },
                              })}
                              onChange={(event) => sportsRequestHandler(event)}
                              onSelect={(label, item) => selectSportsHandler(label, item, onChange)}
                              value={Sports}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"sports"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group form_select_arrow DisplayinRmv autocomplete">
                        <label>
                          {lang.country_city}<span>*</span>
                        </label>
                        <Controller
                          name={"city"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <Autocomplete
                              getItemValue={(item) => current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                              items={cityList}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  className={
                                    isHighlighted
                                      ? " react-autocomplete-inner active "
                                      : "react-autocomplete-inner"
                                  }
                                >
                                  {current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                                </div>
                              )}
                              {...register("city", {
                                required: {
                                  value: true,
                                  message: lang.please_select_a_country_city,
                                },
                              })}
                              onChange={(event) => cityRequestHandler(event)}
                              onSelect={(label, item) => selectCityHandler(label, item, onChange)}
                              value={City}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"city"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group form_select_arrow DisplayinRmv autocomplete">
                        <label>
                          {lang.venue_stadium}<span>*</span>
                        </label>
                        <Controller
                          name={"stadium"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <Autocomplete
                              getItemValue={(item) => item._source.caption}
                              items={venueList}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  className={
                                    isHighlighted
                                      ? " react-autocomplete-inner active "
                                      : "react-autocomplete-inner"
                                  }
                                >
                                  {item._source.caption}
                                </div>
                              )}
                              {...register("stadium", {
                                required: {
                                  value: true,
                                  message: lang.pls_select_venue,
                                },
                              })}
                              onChange={(event) => venueRequestHandler(event)}
                              onSelect={(label) => selectVenueHandler(label, onChange)}
                              value={venue}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"stadium"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.from}<span>*</span>
                        </label>
                        <DatePicker
                          minDate={new Date()}
                          locale={current_culture}
                          dateFormat={"dd/MM/yyyy"}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown={true}
                          selected={fromDate}
                          onChange={(value) => {
                            setFromDate(value);
                            setToDate(value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.to}<span>*</span>
                        </label>
                        <DatePicker
                          locale={current_culture}
                          minDate={fromDate}
                          dateFormat={"dd/MM/yyyy"}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown={true}
                          selected={toDate}
                          onChange={(value) => {
                            setToDate(value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group form_select_arrow">
                        <label>
                          {lang.no_of_attendees}<span>*</span>
                        </label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register("attendeecount", {
                            required: {
                              value: true,
                              message: lang.pls_select_no_of_attendees,
                            },
                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={"attendeecount"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>{lang.additional_information}</label>
                        <textarea
                          class="form-control"
                          name=""
                          rows=""
                          cols=""
                          {...register("additionalInfo")}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>{lang.special_requests}</label>
                        <textarea
                          class="form-control"
                          name=""
                          rows=""
                          cols=""
                          {...register("specialReq")}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              {lang.full_name}<span>*</span>
                            </label>
                            <input
                              type="name"
                              class="form-control"
                              {...register("firstName", {
                                required: lang.first_name_is_required,
                                pattern: {
                                  value: /[A-Za-z]/,
                                  message: lang.please_enter_valid_first_name,
                                },
                                minLength: {
                                  value: 2,
                                  message: lang.please_enter_minimum_two_characters,
                                },

                                value: "",
                              })}
                            ></input>
                            <ErrorMessage
                              errors={errors}
                              name={"firstName"}
                              render={({ message }) => (
                                <span className="error-message">{message}</span>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>&nbsp;</label>
                            <input
                              type="name"
                              class="form-control"
                              {...register("lastName", {
                                required: lang.lasr_name_is_required,
                                pattern: {
                                  value: /[A-Za-z]/,
                                  message: lang.please_enter_valid_first_name,
                                },
                                minLength: {
                                  value: 2,
                                  message: lang.please_enter_minimum_two_characters,
                                },

                                value: "",
                              })}
                            ></input>
                            <ErrorMessage
                              errors={errors}
                              name={"lastName"}
                              render={({ message }) => (
                                <span className="error-message">{message}</span>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.email}<span>*</span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          {...register("email", {
                            required: lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: lang.invalid_email_address,
                            },

                            value: "",
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"email"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.phone_number}<span>*</span>
                        </label>
                        <div class="form-group d-flex flex-wrap">
                          <div class="phonecode form_phone_select_arrow position-relative">
                            <select
                              class="form-control"
                              value={mobCode}
                              {...register("phoneCode", {
                                required: {
                                  value: true,
                                  message: "Phone Code is required",
                                },
                                onChange: (e) => setMobCode(e.target.value),
                                value: mobCode
                              })}
                            >
                              {phoneCode.map((item) => {
                                return <option value={item}>{item}</option>
                              })}
                            </select>
                            <ErrorMessage
                              errors={errors}
                              name={"phoneCode"}
                              render={({ message }) => (
                                <span className="error-message">
                                  {message}
                                </span>
                              )}
                            />
                          </div>
                          <div class="phonenumber">
                            <input
                              type="name"
                              class="form-control"
                              placeholder=" "
                              {...register("mobileno", {
                                required: {
                                  value: true,
                                  message: lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message: lang.please_enter_atleast_six_numbers,
                                },

                                value: "",
                              })}
                            ></input>

                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={"mobileno"}
                            render={({ message }) => (
                              <span className="error-message">{message}</span>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <button type="submit" class="form_submit_btn">
                        {lang.submit}
                      </button>
                    </div>
                    {tokenData.data.isDefaultUser && <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <p className="form_note">
                        {lang.note_now_you_are_on_guest_user_please}{" "}
                        <a href="#" onClick={() => setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}
                      </p>
                    </div>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default SportsNCulturalEvents;
