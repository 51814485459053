import React, { useEffect, useState } from 'react';


import InnerBanner from '../../components/common/inner_banner';

import MeetNAssistDetail from "../../assets/images/meet_assist_service.jpg"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from 'react-autocomplete';
import {  Link} from 'react-router-dom';
import moment from "moment";

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { getAirportList, getTimeData, postServices, setPhoneCodeList } from '../../services/common'
import { getServiceDetailsById } from '../../scripts/common';
import ServiceBoxLeft from './ServiceBoxLeft';
import UserList from '../../components/common/UserList';
import { useNavigate } from 'react-router-dom';


import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import FulloverLoader from '../../components/common/FulloverLoader';

const serviceId = 11;

function MeetNAssist({ setShowLoginModal }) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm();
  let lang = useSelector((state) => state.language.data);
  const current_culture = useSelector(state => state.culture.value);
  let myProfile = JSON.parse(localStorage.getItem("myProfile"));
  
  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState('');
  const [walletData, setWalletData] = useState(null)
  const [phoneCode, setPhoneCode] = useState([])
  const [mobileCode, setMobileCode] = useState(process.env.REACT_APP_MOBCODE);
  const [date, setDate] = useState();
  const tokenData = useSelector((state) => state.tokenInfo);
  const [airport, setAirport] = useState([])
  const [airportSelected, setAirportSelected] = useState('')
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [serviceDetails, setserviceDetails] = useState();
  const [airportDispName, setAirportDispName] = useState("")
  useEffect(() => {
    window.scrollTo(0, 0);

    const serviceDetailsData = getServiceDetailsById(serviceId);
    setserviceDetails(serviceDetailsData)

    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList()

      setPhoneCode(phoneCodeList)
    }

    fetchPhoneCode()
  }, []);

  const requestOptionHandlerAirport = async (e) => {
    setAirportSelected(e.target.value);
    let airportList = await getAirportList(e.target.value);

    if (airportList) {
      let airPortList = [];
      airportList.forEach((item) => {
       
        let { city, name, countryname, code, isallairport } = item._source;
        var label = "";

        if (current_culture == "ar") {
          city = item._source.arabiccity
          name = item._source.arabicname
          countryname =item._source.arabiccountryname
        }

        label = city + "-" + name + "-" + countryname + "(" + code + ")";
        let displayName = item._source.city + "-" + item._source.name + "-" + item._source.countryname + "(" + code + ")"
        

        airPortList.push({
          code: code,
          label: label,
          city: city,
          isallairport: isallairport,
          displayName: displayName
        });
      });
      setAirport(airPortList)
    }

  }
  const onSelectAirportHandler = (label, item, onChange) => {
   
    setAirportSelected(item.label)
    setAirportDispName(item.displayName)
    onChange(item.label)
  }
  const onSubmit = async (data) => {

    console.log("airport",airportSelected)
    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true)
    } else {
      setIsLoading(true)

      let meetAndAssistParams = {
        ServiceID: serviceId,
        /* OnBehalfId: onBehalfId, */
        Data: JSON.stringify({
          name: data.name,
          email: data.email,
          mobile_code: data.mobileCode,
          contact_number: data.contact_number,
          select_meet_type_assist: data.select_meet_type_assist,
          date_of_service: moment(data.date_of_service).format(
            "DD/MM/YYYY"
          ),
          estimated_time_for_arrival_departure: data.estimated_time_for_arrival_departure,
          number_of_passengers: data.number_of_passengers,
          select_airport:airportDispName,
          flight_number_with_airline_code: data.flight_number_with_airline_code,
          additional_information: data.additional_information,
          special_requests: data.special_requests,
          // fieldOrder:'name,email,mobile_code,contact_number,select_meet_type_assist,date_of_service,estimated_time_for_arrival_departure,number_of_passengers,select_airport,flight_number_with_airline_code,additional_information,special_requests'
        })
      }
      if(onBehalfId) {
        meetAndAssistParams["OnBehalfId"] = onBehalfId
      }
      const response = await postServices(meetAndAssistParams);
      if (response) {
        dispatch(alertActions.setPopUpType('success'))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: "" }))
        dispatch(alertActions.setPopUpData({ heading: 'Success', message: lang.form_submitted_sucessfully  }))
        resetForm();
        setIsLoading(false)

      } else {
        dispatch(alertActions.setPopUpType('error'))
        dispatch(alertActions.setPopUpData({ heading: 'Failed', message: lang.something_went_wrong_please_try_again }))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))

        setIsLoading(false)
      }
    }

  }
  const resetForm = () => {
    reset()
    setMobileCode(process.env.REACT_APP_MOBCODE)
    setAirportSelected("")
    setOnBehalfId("")
    setValue('OnBehalfId',"")
    setUserText('')
    setWalletData(null)

  }
  return (

    <React.Fragment>
      {
        isLoading &&
        <FulloverLoader />
      }
      <InnerBanner title={lang.meet_assist_detail}></InnerBanner>

      <section className='content_service_main form_bg'>
        <div class='container'>
          <div className='form_breadcrumbs'>
            <ul>
               <li><Link to={process.env.REACT_APP_BASE_URL+'services'}>{lang.services} </Link></li>
              <li><a className='active' href=''>{lang.meet_assist_detail}</a></li>
            </ul>
          </div>
          <div className='service_box'>
            {
              serviceDetails &&
              <ServiceBoxLeft
                serviceDetails={serviceDetails}
              />
            }
            <div className='service_box_col_rht'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='service_detail_formbox formstyle'>
                  <div className='service_detail_formhd'>
                    <h2>{lang.feel_like_contacting_us}</h2>
                    <p>{lang.submit_the_form_below_we_will_get_back_to_you_asap}</p>
                  </div>
                  <hr className='form_hd_hr'>
                  </hr>
                  {(myProfile && myProfile.isAgentLogin && ! tokenData.data.isDefaultUser) &&
                    <UserList
                      register={register}
                      onBehalfId={onBehalfId}
                      setOnBehalfId={setOnBehalfId}
                      errors={errors}
                      control={control}
                      userText={userText}
                      setUserText={setUserText}
                      walletData={walletData}
                      setWalletData={setWalletData}

                    />
                  }
                  <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.name}<span>*</span></label>
                        <input type="text" class="form-control"

                          {...register('name', {
                            required: lang.name_is_required,
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,

                            },
                            pattern: {
                              value: /[A-Za-z]/,
                              message:  lang.please_enter_valid_name,
                            },
                          })}
                        >
                        </input>
                        <ErrorMessage
                          errors={errors}
                          name={'name'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.email}<span>*</span></label>
                        <input type="text" class="form-control"

                          {...register('email', {
                            required:  lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: lang.invalid_email_address,
                            },


                          })}
                        >
                        </input>
                        <ErrorMessage
                          errors={errors}
                          name={'email'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.contact_number}<span>*</span></label>
                        <div class="form-group d-flex flex-wrap">
                          <div class="phonecode form_phone_select_arrow position-relative">
                            <select class="form-control"
                              {...register('mobileCode', {
                                required: {
                                  value: true,
                                  message:
                                  lang.phone_code_is_required,
                                },
                                onChange: (e) => {
                                  setMobileCode(e.target.value)
                                }, value: mobileCode
                              })}
                            >
                              {phoneCode.map((item, key) => {
                                return <option selected={mobileCode == item ? 'selected' : ''} value={item}>{item}</option>
                              })}
                            </select>
                          </div>
                          <div class="phonenumber">
                            <input type="text" class="form-control" placeholder=" "

                              {...register('contact_number', {
                                required: {
                                  value: true,
                                  message:
                                    lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message:
                                    lang.please_enter_atleast_six_numbers,
                                },

                                onChange: e => {

                                },
                              })}
                            >
                            </input>
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={'contact_number'}
                            render={({ message }) => (
                              <span className='error-message'>{message}</span>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group form_select_arrow'>
                        <label>{lang.select_meet_and_assist}<span>*</span></label>
                        <select name="" id="" class="form-control"
                          {...register('select_meet_type_assist', {
                            required: lang.meet_assist_type_required

                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="Standard Meet and Assist">{lang.standard_meet_and_assist}</option>
                          <option value="VIP / Fast Track">{lang.vip_fast_track}</option>
                          {/* <option value="VIP Meet and Assist">{lang.vip_meet_and_assist}</option> */}
                          <option value="Luggage Pick Up">{lang.luggage_pickup}</option>
                          {/* <option value="Arrival">{lang.arrival}</option>
                          <option value="Departure">{lang.departure}</option>
                          <option value="Transfer/Transit">{lang.select_transfer_transit}</option> */}
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={'select_meet_type_assist'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group form_select_arrow DisplayinRmv autocomplete'>
                        <label>{lang.select_airport}<span>*</span></label>
                        <Controller
                          name={'select_airport'}
                          control={control}
                          render={({
                            field: { onChange, value },
                          }) => (<Autocomplete className="form-control"
                            onChange={(e) => {
                              requestOptionHandlerAirport(e)
                              e.target.value == "" && onChange(e.target.value)

                            }}
                            items={airport}
                            getItemValue={(item) => item.code}
                            renderItem={(item, isHighlighted) =>
                              <div className="react-autocomplete" >
                                <div className={isHighlighted ? " react-autocomplete-inner active " : "react-autocomplete-inner"} >
                                  {item.label}
                                </div>
                              </div>
                            }
                            value={airportSelected}
                            onSelect={(label, item) => onSelectAirportHandler(label, item, onChange)}

                          />)}
                          {...register('select_airport', {
                            required: lang.airport_is_required,
                            value: airportSelected,
                          })}
                        />

                        <ErrorMessage
                          errors={errors}
                          name={'select_airport'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>

                          )}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-5'>
                      <div className='form-group'>
                        <label>{lang.date_of_service}<span>*</span></label>
                        <Controller
                          name={'date_of_service'}
                          control={control}
                          render={({
                            field: { onChange, value },
                          }) => (
                            <DatePicker
                              minDate={new Date()}
                              dateFormat={'dd/MM/yyyy'}
                              className="form-control"
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown={true}
                              showYearDropdown={true}
                              selected={value}
                              onChange={date => {
                                ;
                                onChange(date)
                                setDate(new Date(date))
                              }}
                              value={date}
                            />
                          )}
                          {...register('date_of_service', {
                            required: lang.date_is_required,
                            value: date,
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={'date_of_service'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>

                          )}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-7'>
                      <div className='form-group'>
                        <label>{lang.flight_no_with_airline_code}<span>*</span></label>
                        <input type="name" class="form-control" placeholder='FLY55235'
                          {...register('flight_number_with_airline_code', {
                            required: lang.flight_no_with_airline_code_is_required,
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={'flight_number_with_airline_code'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>

                          )}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                      <div className='form-group'>
                        <label>{lang.estimated_time_for_arrival_departure}</label>
                        <select name="" id="" class="form-control"
                          {...register('estimated_time_for_arrival_departure')}
                        >
                          {getTimeData.map((item) => {
                            return (
                              <option value={item}>{item}</option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                      <div className='form-group form_select_arrow'>
                        <label>{lang.no_of_passsengers}<span>*</span></label>
                        <select name="" id="" class="form-control"
                          {...register('number_of_passengers')}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="3">4</option>
                          <option value="3">5</option>
                          <option value="3">6</option>
                          <option value="3">7</option>
                          <option value="3">8</option>
                          <option value="3">9</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.additional_information}</label>
                        <textarea class="form-control" name="" rows="" cols=""
                          {...register('additional_information', {

                          })}
                        ></textarea>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.special_requests}</label>
                        <textarea class="form-control" name="" rows="" cols=""
                          {...register('special_requests', {

                          })}
                        ></textarea>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                      <button type="submit" class="form_submit_btn">{lang.submit}</button>
                    </div>
                    {
                      tokenData.data.isDefaultUser &&
                      <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                        <p className='form_note'>{lang.note_now_you_are_on_guest_user_please} <a href='#' onClick={() => setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}</p>
                      </div>
                    }
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>


    </React.Fragment>

  )
}
export default MeetNAssist;