import React from "react";
import headerImg from "../../assets/images/qgo_clm_1.jpg";
import emailLogo from "../../assets/images/email_logo.png";
import servicesimg from "../../assets/images/banner_govermental_services.jpg";
import servicesimgAr from "../../assets/images/banner_govermental_services_ar.jpg";
import img1 from "../../assets/images/qgo_clm_2.jpg";
import dotimg from "../../assets/images/dot_tkt.jpg";
import ticketEmail from "../../assets/images/tkt_email.jpg";
import ticketPhone from "../../assets/images/tkt_phone.jpg";
import moment from "moment";
import { useSelector } from "react-redux";
import InnerBanner from "../../components/common/inner_banner";

function VoucherWeb({
  ticketRef,
  ticketDownloadHandler,
  enqData,
  enquiryDetails,
  isMobView,
  download,
  appView
}) {
  const lang = useSelector((state) => state.language.data);
  const current_culture = useSelector((state) => state.culture.value);

  let direction = "";
  let alignR = "";
  let alignL = "";

  if (current_culture == "ar") {
    alignR = "left";
    alignL = "right";
    direction = "rtl";
  } else {
    alignR = "right";
    alignL = "left";
    direction = "ltr";
  }

  return (
    <div className={isMobView ? "d-none" : ""}>
      {!download && <InnerBanner title={lang.voucher} />}
      <div className="content_service_main">
        <div className="container">
          {!download && <div className="vchr_dwn_main">
            <div className="downLoad_box">
              {appView != "true" && <ul>
                <li onClick={ticketDownloadHandler}>
                  {" "}
                  <a href="#">
                    {" "}
                    <span>
                      {" "}
                      <i className="vchr_download"> </i> {lang.download}{" "}
                    </span>{" "}
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="#">
                    {" "}
                    <span>
                      {" "}
                      <i className="vchr_mail"> </i> {lang.mail}{" "}
                    </span>{" "}
                  </a>{" "}
                </li>
              </ul>}
            </div>
          </div>}
          <table
            ref={ticketRef}
            id="ticketArea"
            width={700}
            border={0}
            align="center"
            cellPadding={0}
            cellSpacing={0}
            style={{
              fontFamily: "Arial, Helvetica, sans-serif",
              direction: direction,
              unicodeBidi: "embed",
              WebkitPrintColorAdjust: "exact",   PrintColorAdjust: "exact"
            }}
          >
            <tbody>
              <tr>
                <td
                  width={700}
                  style={{
                    backgroundColor: `#015089`,

                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <table width={700} border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width={700} style={{ padding: "20px 0" }}>
                          <table
                            width={700}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td width={35}>&nbsp;</td>
                                <td
                                  width={630}
                                  style={{
                                    borderBottom: "solid #2d78af 1px",
                                    paddingTop: 25,
                                    paddingBottom: 30,
                                  }}
                                  align="center"
                                  valign="middle"
                                >
                                  <img src={emailLogo} alt />
                                </td>
                                <td width={35}>&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td width={700} valign="top" tyle="padding:0;">
                          <table
                            width={700}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td width={35}>&nbsp;</td>
                                <td
                                  style={{ paddingTop: 5 }}
                                  width={630}
                                  align="center"
                                  valign="middle"
                                >
                                  <table
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            fontSize: 18,
                                            color: "#FFF",
                                            fontWeight: "bold",
                                            padding: "0 5px",
                                          }}
                                        >
                                          {" "}
                                          {lang.evoucher_confirm}{" "}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td width={35}>&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td width={700} valign="top" style={{ padding: 0 }}>
                          <table
                            width={700}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td width={35}>&nbsp;</td>
                                <td
                                  style={{ paddingTop: 10 }}
                                  width={630}
                                  align="center"
                                  valign="middle"
                                >
                                  <table
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            fontSize: 16,
                                            color: "#FFF",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          {" "}
                                          {lang.ref_no}.{" "}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: 16,
                                            color: "#FFF",
                                            fontWeight: "bold",
                                            padding: "0 5px",
                                          }}
                                        >
                                          {" "}
                                          : {enquiryDetails.enquiryID}{" "}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td width={35}>&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td width={700} valign="top" style={{ padding: 0 }}>
                          <table
                            width={700}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td width={35}>&nbsp;</td>
                                <td
                                  style={{ paddingTop: 10 }}
                                  width={630}
                                  align="center"
                                  valign="middle"
                                >
                                  <table
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            fontSize: 16,
                                            color: "#FFF",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          {" "}
                                          {lang.date_of_issue}{" "}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: 16,
                                            color: "#FFF",
                                            fontWeight: "bold",
                                            padding: "0 5px",
                                          }}
                                        >
                                          {" "}
                                          :{" "}
                                          {moment(
                                            new Date(enquiryDetails.enquiryDate)
                                          ).format("DD-MM-yyyy")}{" "}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td width={35}>&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width={700}
                          valign="top"
                          bgcolor="#015089"
                          style={{ padding: 0 }}
                        >
                          <table
                            width={700}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td width={35}>&nbsp;</td>
                                <td width={630} align="center" valign="middle">
                                  &nbsp;
                                </td>
                                <td width={35}>&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td width={700}>
                  <img
                    src={current_culture == "en" ? servicesimg : servicesimgAr}
                    alt
                  />
                </td>
              </tr>
              <tr>
                <td width={700} valign="top" style={{ padding: 0 }}>
                  <table width={700} border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                        <td bgcolor="#FFFFFF" width={630} valign="middle">
                          <table
                            width={630}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td width={25}>&nbsp;</td>
                                <td
                                  width={580}
                                  style={{
                                    paddingTop: 30,
                                    paddingBottom: 30,
                                    color: "#004e87",
                                    fontSize: 18,
                                    textTransform: "uppercase",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {current_culture == "en"
                                    ? enquiryDetails.enquiryService.title_En
                                    : enquiryDetails.enquiryService.title_Ar
                                    ? enquiryDetails.enquiryService.title_Ar
                                    : enquiryDetails.enquiryService.title_En}
                                </td>

                                <td width={25}>&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>

                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td width={700} valign="top" style={{ padding: 0 }}>
                  <table width={700} border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                        <td bgcolor="#FFFFFF" width={630} valign="middle">
                          <table
                            width={630}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td width={25}>&nbsp;</td>
                                <td
                                  width={580}
                                  height={80}
                                  valign="middle"
                                  style={{
                                    paddingBottom: 20,
                                    borderBottom: "solid #ecf0f3 1px",
                                    backgroundRepeat: "no-repeat",
                                  }}
                                >
                                  <table
                                    width={580}
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            color: "#004e87",
                                            fontSize: 16,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {" "}
                                          {lang.confrm_no}{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            color: "#8d613a",
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            paddingTop: 5,
                                          }}
                                        >
                                          {" "}
                                          QG0095669{" "}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td width={25}>&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td width={700} valign="top" style={{ padding: 0 }}>
                  <table width={700} border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                        <td bgcolor="#FFFFFF" width={630} valign="middle">
                          <table
                            width={630}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td width={25}>&nbsp;</td>
                                <td
                                  width={580}
                                  valign="top"
                                  style={{ backgroundRepeat: "no-repeat" }}
                                >
                                  <table
                                    width={580}
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          width={580}
                                          style={{
                                            color: "#000000",
                                            fontSize: 16,
                                            fontWeight: "bold",
                                            paddingTop: 20,
                                          }}
                                        >
                                          {" "}
                                          {lang.user_details}{" "}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td width={25}>&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              {enqData.map((field, index) => {
                let fieldname = field[0];
                return fieldname != "additional_information" ||
                  fieldname != "fieldOrder" ? (
                  <tr id={"looping"+index}>
                    <td width={700} valign="top" style={{ padding: 0 }}>
                      <table
                        width={700}
                        border={0}
                        cellSpacing={0}
                        cellPadding={0}
                      >
                        <tbody>
                          <tr>
                            <td width={35} bgcolor="#f4f4f4">
                              &nbsp;
                            </td>
                            <td bgcolor="#FFFFFF" width={630} valign="middle">
                              <table
                                width={630}
                                border={0}
                                cellSpacing={0}
                                cellPadding={0}
                              >
                                <tbody>
                                  <tr>
                                    <td width={25}>&nbsp;</td>
                                    <td width={580} valign="top">
                                      <table
                                        width={580}
                                        border={0}
                                        cellSpacing={0}
                                        cellPadding={0}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              width={580}
                                              style={{
                                                color: "#b1b1b1",
                                                fontSize: 16,
                                                fontWeight: "bold",
                                                paddingTop: 25,
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              {" "}
                                              {lang[fieldname]}{" "}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              width={580}
                                              style={{
                                                color: "#000000",
                                                fontSize: 16,
                                                fontWeight: "bold",
                                                paddingTop: 5,
                                                textAlign: alignL,
                                                direction: "ltr",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              {" "}
                                              {field[1]}{" "}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td width={25}>&nbsp;</td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td width={35} bgcolor="#f4f4f4">
                              &nbsp;
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ) : (
                  <></>
                );
              })}
              {/*
            <tr>
          <td width={700} valign="top" style={{padding: 0}}><table width={700} border={0} cellSpacing={0} cellPadding={0}>
              <tbody>
                <tr>
                  <td width={35} bgcolor="#f4f4f4">&nbsp;</td>
                  <td bgcolor="#FFFFFF" width={630} valign="middle"><table width={630} border={0} cellSpacing={0} cellPadding={0}>
                      <tbody>
                        <tr>
                          <td width={25}>&nbsp;</td>
                          <td width={580} valign="top"><table width={580} border={0} cellSpacing={0} cellPadding={0}>
                              <tbody>
                                <tr>
                                  <td style={{color: '#b1b1b1', fontSize: 16, fontWeight: 'bold', paddingTop: 25}}>Email</td>
                                </tr>
                                <tr>
                                  <td width={580} style={{color: '#000000', fontSize: 16, fontWeight: 'bold', paddingTop: 5}}> {enquiryDetails.data.email} </td>
                                </tr>
                              </tbody>
                            </table></td>
                          <td width={25}>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table></td>
                  <td width={35} bgcolor="#f4f4f4">&nbsp;</td>
                </tr>
              </tbody>
            </table></td>
        </tr>
            <tr>
          <td width={700} valign="top" style={{padding: 0}}><table width={700} border={0} cellSpacing={0} cellPadding={0}>
              <tbody>
                <tr>
                  <td width={35} bgcolor="#f4f4f4">&nbsp;</td>
                  <td bgcolor="#FFFFFF" width={630} valign="middle"><table width={630} border={0} cellSpacing={0} cellPadding={0}>
                      <tbody>
                        <tr>
                          <td width={25}>&nbsp;</td>
                          <td width={580} valign="top"><table width={580} border={0} cellSpacing={0} cellPadding={0}>
                              <tbody>
                                <tr>
                                  <td style={{color: '#b1b1b1', fontSize: 16, fontWeight: 'bold', paddingTop: 25}}>Contact Number</td>
                                </tr>
                                <tr>
                                  <td width={580} style={{color: '#000000', fontSize: 16, fontWeight: 'bold', paddingTop: 5}}> ({enquiryDetails.data.mobile_code}){enquiryDetails.data.contact_number} </td>
                                </tr>
                              </tbody>
                            </table></td>
                          <td width={25}>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table></td>
                  <td width={35} bgcolor="#f4f4f4">&nbsp;</td>
                </tr>
              </tbody>
            </table></td>
        </tr>
            */}
              <tr>
                <td width={700} valign="top" style={{ padding: 0 }}>
                  <table width={700} border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                        <td bgcolor="#FFFFFF" width={630} valign="middle">
                          <table
                            width={630}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td width={25}>&nbsp;</td>
                                <td width={580} valign="top">
                                  <table
                                    width={580}
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            color: "#000000",
                                            fontSize: 16,
                                            fontWeight: "bold",
                                            paddingTop: 25,
                                            borderBottom: "solid #ecf0f3 1px",
                                          }}
                                        />
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td width={25}>&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td width={700} valign="top" style={{ padding: 0 }}>
                  <table width={700} border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                        <td bgcolor="#FFFFFF" width={630} valign="middle">
                          <table
                            width={630}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td width={25}>&nbsp;</td>
                                <td width={580} valign="top">
                                  <table
                                    width={580}
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            color: "#000000",
                                            fontSize: 16,
                                            fontWeight: "bold",
                                            paddingTop: 25,
                                          }}
                                        >
                                          {" "}
                                          {lang.additional_information}{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          width={580}
                                          style={{
                                            color: "#b1b1b1",
                                            fontSize: 14,
                                            fontWeight: "normal",
                                            paddingTop: 5,
                                          }}
                                        >
                                          {" "}
                                          {
                                            enquiryDetails.data
                                              .additional_information
                                          }{" "}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td width={25}>&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td width={700} valign="top" style={{ padding: 0 }}>
                  <table width={700} border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                        <td bgcolor="#FFFFFF" width={630} valign="middle">
                          <table
                            width={630}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td width={25}>&nbsp;</td>
                                <td width={580} valign="top">
                                  &nbsp;
                                </td>
                                <td width={25}>&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td width={700} valign="top" style={{ padding: 0 }}>
                  <table width={700} border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                        <td width={630} valign="middle">
                          <table
                            width={630}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td
                                  bgcolor="#f4f4f4"
                                  width={630}
                                  height={30}
                                  valign="top"
                                  style={{}}
                                >
                                  <table
                                    width={630}
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          height={30}
                                          style={{
                                            background: "#FFF",
                                            borderBottomLeftRadius: 15,
                                            borderBottomRightRadius: 15,
                                          }}
                                        >
                                          &nbsp;
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>

                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td
                  bgcolor="#f4f4f4"
                  width={700}
                  valign="top"
                  style={{ padding: 0 }}
                >
                  <table width={700} border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                        <td bgcolor="#f4f4f4" width={630} valign="middle">
                          <table
                            width={630}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td width={630} valign="top">
                                  &nbsp;
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            {/*   <tr>
                <td
                  bgcolor="#f4f4f4"
                  width={700}
                  valign="top"
                  style={{ padding: 0 }}
                >
                  <table width={700} border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                        <td bgcolor="#f4f4f4" width={630} valign="middle">
                          <table
                            width={630}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td
                                  width={630}
                                  valign="top"
                                  style={{
                                    color: "#005da0",
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    paddingTop: 25,
                                    paddingBottom: 10,
                                  }}
                                >
                                  {" "}
                                  {lang.voucher_rules_n_cond}{" "}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr> */}
             {/*  <tr>
                <td
                  bgcolor="#f4f4f4"
                  width={700}
                  valign="top"
                  style={{ padding: 0 }}
                >
                  <table width={700} border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                        <td bgcolor="#f4f4f4" width={630} valign="middle">
                          <table
                            width={630}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td width={630} valign="top">
                                  <table
                                    width={630}
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                  >
                                    <tbody>
                                      <tr>
                                        <td width={20}>
                                          <img
                                            style={{ paddingTop: 10 }}
                                            src={dotimg}
                                          />
                                        </td>
                                        <td
                                          width={610}
                                          style={{
                                            fontSize: 13,
                                            paddingTop: 10,
                                          }}
                                        >
                                          {" "}
                                          {lang.vouch_rule_1}{" "}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr> */}
             {/*  <tr>
                <td
                  bgcolor="#f4f4f4"
                  width={700}
                  valign="top"
                  style={{ padding: 0 }}
                >
                  <table width={700} border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                        <td bgcolor="#f4f4f4" width={630} valign="middle">
                          <table
                            width={630}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td width={630} valign="top">
                                  <table
                                    width={630}
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                  >
                                    <tbody>
                                      <tr>
                                        <td width={20}>
                                          <img
                                            style={{ paddingTop: 10 }}
                                            src={dotimg}
                                          />
                                        </td>
                                        <td
                                          width={610}
                                          style={{
                                            fontSize: 13,
                                            paddingTop: 10,
                                          }}
                                        >
                                          {" "}
                                          {lang.vouch_rule_2}{" "}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td
                  bgcolor="#f4f4f4"
                  width={700}
                  valign="top"
                  style={{ padding: 0 }}
                >
                  <table width={700} border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                        <td bgcolor="#f4f4f4" width={630} valign="middle">
                          <table
                            width={630}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td width={630} valign="top">
                                  <table
                                    width={630}
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                  >
                                    <tbody>
                                      <tr>
                                        <td width={20}>
                                          <img
                                            style={{ paddingTop: 10 }}
                                            src={dotimg}
                                          />
                                        </td>
                                        <td
                                          width={610}
                                          style={{
                                            fontSize: 13,
                                            paddingTop: 10,
                                          }}
                                        >
                                          {" "}
                                          {lang.vouch_rule_3}{" "}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td
                  bgcolor="#f4f4f4"
                  width={700}
                  valign="top"
                  style={{ padding: 0 }}
                >
                  <table width={700} border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                        <td bgcolor="#f4f4f4" width={630} valign="middle">
                          <table
                            width={630}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td
                                  width={630}
                                  style={{ paddingTop: 30 }}
                                  valign="top"
                                >
                                  &nbsp;
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr> */}
              {/* <tr>
                <td
                  bgcolor="#f4f4f4"
                  width={700}
                  valign="top"
                  style={{ padding: 0 }}
                >
                  <table width={700} border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                        <td bgcolor="#f4f4f4" width={630} valign="middle">
                          <table
                            width={630}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td
                                  width={630}
                                  height={90}
                                  valign="top"
                                  style={{
                                    background: "#fff",
                                    border: "solid #eaeaea 1px",
                                  }}
                                >
                                  &nbsp;
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr> */}
              <tr>
                <td
                  bgcolor="#f4f4f4"
                  width={700}
                  valign="top"
                  style={{ padding: 0 }}
                >
                  <table width={700} border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                        <td bgcolor="#f4f4f4" width={630} valign="middle">
                          <table
                            width={630}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td width={630} valign="top">
                                  &nbsp;
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width={35} bgcolor="#f4f4f4">
                          &nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td
                  bgcolor="#f4f4f4"
                  width={700}
                  valign="top"
                  style={{ padding: 0 }}
                >
                  <table width={700} border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width={35} bgcolor="#005da0">
                          &nbsp;
                        </td>
                        <td bgcolor="#005da0" width={630} valign="middle">
                          <table
                            width={630}
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td
                                  width={630}
                                  style={{ padding: "30px 0" }}
                                  valign="top"
                                >
                                  <table
                                    width={630}
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                  >
                                    <tbody>
                                      <tr>
                                        <td width={315}>
                                          <img
                                            src={emailLogo}
                                            width={162}
                                            height={62}
                                            alt
                                          />
                                        </td>
                                        <td width={315} align={alignR}>
                                          <table
                                            border={0}
                                            cellSpacing={0}
                                            cellPadding={0}
                                          >
                                            <tbody>
                                              <tr>
                                                <td width={10}>&nbsp;</td>
                                                <td
                                                  width={30}
                                                  style={{ paddingTop: 10 }}
                                                >
                                                  <img src={ticketEmail} />
                                                </td>
                                                <td
                                                  width=" "
                                                  style={{
                                                    paddingTop: 10,
                                                    fontSize: 14,
                                                    color: "#fff",
                                                  }}
                                                >
                                                  {" "}
                                                  {"concierge@qgoaviation.com"}{" "}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td width={10}>&nbsp;</td>
                                                <td
                                                  width={30}
                                                  style={{ paddingTop: 10 }}
                                                >
                                                  <img src={ticketPhone} />
                                                </td>
                                                <td
                                                  width=" "
                                                  style={{
                                                    paddingTop: 10,
                                                    fontSize: 14,
                                                    color: "#fff",
                                                    textAlign: alignL,
                                                    direction: "ltr",
                                                  }}
                                                >
                                                  {" "}
                                                  {"+965 1888125"}{" "}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width={35} bgcolor="#005da0">
                          &nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default VoucherWeb;
