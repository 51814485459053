import React,{useRef} from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import ticketEmail from "../../assets/images/tkt_email.jpg";
import ticketPhone from "../../assets/images/tkt_phone.jpg";
import servicesimg from "../../assets/images/mob_government_services.jpg"
import InnerBanner from "../../components/common/inner_banner";
import jsPDF from "jspdf";


function VoucherMob({ticketDownloadHandler, enqData, enquiryDetails,mobDownload,appView}) {

    const lang = useSelector((state) => state.language.data);
    const current_culture = useSelector(state => state.culture.value);


  return (
    <div>
      {mobDownload != "true" && <InnerBanner title={lang.voucher}/>}
	  <div className="content_service_main">
	  
	        {mobDownload != "true" && <div className="vchr_dwn_main_mob">
            <div className="downLoad_box">
          	   {appView != "true" && <ul>
                <li  onClick={ticketDownloadHandler}> <a href="#"> <span> <i className="vchr_download"> </i> {lang.download} </span> </a> </li>
                <li> <a href="#"> <span> <i className="vchr_mail"> </i> {lang.mail} </span> </a> </li>
              </ul>}
        </div>
          </div>}
	  
      <div id="ticketArea" className="mob_wrappper">
        <div className="mob_hdr_wtr01">
          <div className="mob_header">
            {" "}
            <a href="#">
              <div className="mob_logo" />
            </a>
            <hr />
            <div className="mob_head_content">
              <h2>{lang.evoucher_confirm}</h2>
              <p>{lang.ref_no}.  {enquiryDetails.enquiryID}</p>
              <p>{lang.date_of_issue} {moment(
                                        new Date(enquiryDetails.enquiryDate)
                                      ).format("DD-MM-yyyy")}</p>
            </div>
          </div>
        </div>
        <div className="mob_content_area">
          <div className="mob_banner">
            {" "}
            <img src={servicesimg}/>
            <div className="mob_banner_head">{current_culture == "en" ? enquiryDetails.enquiryService.title_En : enquiryDetails.enquiryService.title_Ar ? enquiryDetails.enquiryService.title_Ar : enquiryDetails.enquiryService.title_En}</div>
          </div>
          <div className="mob_service_dtls">
            <h2>{current_culture == "en" ? enquiryDetails.enquiryService.title_En : enquiryDetails.enquiryService.title_Ar ? enquiryDetails.enquiryService.title_Ar : enquiryDetails.enquiryService.title_En} </h2>
            <div className="mob_confirmation_sec">
              <h3>{lang.confrm_no}</h3>
              <p>QG0095669</p>
            </div>
            <div className="mob_user_sec">
              <h2>{lang.user_details}</h2>
              {enqData.map((field,index)=>{
                let fieldname = field[0]
                return (
                  (fieldname != "additional_information" || fieldname != "fieldOrder") ?
                    <div className="formsec">
                <h4 style={{ wordBreak: "break-all"}}>{lang[fieldname]}</h4>
                <p style={{ wordBreak: "break-all"}}>{field[1]}</p>
              </div> :
              <></>
                )
              })}
             
            </div>
          </div>
          <div className="mob_rules">
            <h2>{lang.voucher_rules_n_cond}</h2>
            <ul>
              <li>
                {lang.vouch_rule_1}
              </li>
              <li>
                {lang.vouch_rule_2}
              </li>
              <li>{lang.vouch_rule_3}</li>
            </ul>
          </div>
          <div className="mob_ads" />
        </div>
        <div className="mob_footer">
          <div className="mob_footer_divide">
            <div className="mob_footer_logo" />
          </div>
          <div className="mob_footer_divide">
            <ul>
              <li>
                <i className>
                  <img src={ticketEmail} />
                </i>
                {lang.voucher_email}
              </li>
              <li>
                <i className>
                  <img src={ticketPhone} />
                </i>
                {lang.voucher_phone}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
	</div>
  );
}

export default VoucherMob;
