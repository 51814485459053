import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useSelector } from "react-redux";
import bnrlogo from '../../assets/images/bnr_logo.png';




export default function BannerSlider() {
    let lang = useSelector((state) => state.language.data);

    return (
        <div className="new_cap banner-slider">

 
            

            <h1>{lang.tag_line1}  <span>{lang.tag_line2}</span></h1>
			
			<img style={{width:"160px"}} src={bnrlogo}  />



            {/* <OwlCarousel items={1} className="owl-theme" loop nav margin={0} >
                <div className="carousel-box">
                    <h2>{lang.coincerage_service_explained} <br />{lang.make_most_of_it}</h2>
                    <p>{lang.coincergae_description}</p>
                </div>

                <div className="carousel-box">
                    <h2>{lang.car_rental_explained} <br />{lang.make_most_of_it}</h2>
                    <p>{lang.car_rental_description}</p>
                </div>
            </OwlCarousel> */}
        </div>
    )
}