import React from "react";
import Logo from "../../assets/images/logo.png";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Login from "./login";
import Register from "./register";
import Forgot from "./forgot";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { getTokenDataInfo } from "../../redux/getBearortoken";
import { forgotPassword, getMyProfile } from "../../services/common";
import { alertActions } from "../../redux/alertSlice";
import FulloverLoader from "./FulloverLoader";
import { changeCulture } from "../../redux/CultureSlice";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSearchParams } from "react-router-dom";

export default function Header({
  showLoginModal,
  setShowLoginModal,
  showRegisterModal,
  setShowRegisterModal,
  setVoucherLangLoader
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let lang = useSelector((state) => state.language.data);

  const [searchParms, setSearchParms] = useSearchParams()

  const enqId = searchParms.get("enquiryId");
  const current_culture = useSelector((state) => state.culture.value);

  const myProfile = JSON.parse(localStorage.getItem("myProfile"));

  const [showItem, setShowItem] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [walletLoader, setWalletLoader] = useState(true);
  const [walletBalance, setWalletBalance] = useState("");

  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const tokenData = useSelector((state) => state.tokenInfo);
  const logout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("myProfile");
    window.location.reload();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ criteriaMode: "all" });

  const onForgetSubmit = async (data) => {
    console.log(data);

    const info = { data: data.email, culture: current_culture };

    setIsLoading(true);
    const forgotPassRes = await forgotPassword(info);

    if (forgotPassRes.data == "Link_mailed ") {
      dispatch(alertActions.setPopUpType("success"));
      dispatch(
        alertActions.setRedirect({
          redirect: false,
          redirectUrl: process.env.REACT_APP_BASE_URL,
        })
      );
      dispatch(
        alertActions.setPopUpData({
          heading: "Success",
          message: "Link sent to your Email",
        })
      );
    } else {
      dispatch(alertActions.setPopUpType("error"));
      dispatch(
        alertActions.setPopUpData({
          heading: "Failed",
          message: "Invalid Email",
        })
      );
      dispatch(
        alertActions.setRedirect({
          redirect: false,
          redirectUrl: process.env.REACT_APP_BASE_URL,
        })
      );
    }
    setIsLoading(false);
  };
  const cultureChange = (e, culture) => {
    e.preventDefault();

    dispatch(changeCulture(culture));
    localStorage.setItem("culture", culture);
    


    //for handling culture change in voucher page

    const url_segment = process.env.REACT_APP_BASE_URL.replace("/", "");
    let current_url = "";
    if (url_segment != "")
      current_url = window.location.href.replace(url_segment, "");
    else current_url = window.location.href;
  
    let url_culture = current_url.split("/")[3];

    

    
    if ((url_culture == "en" || url_culture == "ar") &&  current_url.split("/")[4].split("?")[0] == "Voucher") {

      setVoucherLangLoader(true)
      

      let url = process.env.REACT_APP_BASE_URL + culture + "/Voucher?enquiryId=" + enqId

      navigate(url)

    
      window.location.reload();
      
    }
    
    
     window.location.reload();
  };

  const [isActive, setIsActive] = useState(false);
  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };

  const fetchMyProfile = async () => {
    const myProfile = await getMyProfile();
    console.log(myProfile);
    setWalletBalance(myProfile.wallet);
    setWalletLoader(false);
    localStorage.setItem("myProfile", JSON.stringify(myProfile));
  };
  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-9">
            <div className="logo">
              <Link to={process.env.REACT_APP_BASE_URL}>
                <img src={Logo} />
              </Link>
            </div>
          </div>
          <div className="col-lg-9 col-md-6 col-3">
            <div className="Mob-menu-btn" onClick={handleClick}>
              <div className="m-btn">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <ul className={isActive ? "show main-menu" : "main-menu"}>
              <span className="Menuclose" onClick={handleClick}>
                X
              </span>
              <li>
                <a href={process.env.REACT_APP_BASE_URL}>
                  <span>{lang.home}</span>
                </a>
              </li>
              <li>
                <Link to={process.env.REACT_APP_BASE_URL + "About-Us"}>
                  <span>{lang.about_us}</span>
                </Link>
              </li>
              <li>
                <Link to={process.env.REACT_APP_BASE_URL + "services"}>
                  <span>{lang.services}</span>
                </Link>
              </li>
              <li>
                <Link to={process.env.REACT_APP_BASE_URL + "Contact-Us"}>
                  <span>{lang.contact_us}</span>
                </Link>
              </li>
              <li>
                {(current_culture == "en" && (
                  <span onClick={(e) => cultureChange(e, "ar")}>عربي</span>
                )) || (
                  <span onClick={(e) => cultureChange(e, "en")}>English</span>
                )}
              </li>
              {(tokenData.data.isDefaultUser && (
                <>
                  <li
                    className="LoginMenu"
                    onClick={() => setShowLoginModal(true)}
                  >
                    <span>{lang.login}</span>
                  </li>
                  <li
                    className="RegisterMenu"
                    onClick={() => setShowRegisterModal(true)}
                  >
                    <span>{lang.register}</span>
                  </li>
                </>
              )) || (
                <>
                  <Dropdown>
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      <span>
                        <i className="usr-icon"></i>
                        {myProfile ? myProfile.firstName : "User"}
                        <i className="drop-icon"></i>
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <Link
                          to={process.env.REACT_APP_BASE_URL + "My-Profile"}
                        >
                          {lang.my_profile}
                          {/* <a href="">My Profile</a> */}
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link
                          to={process.env.REACT_APP_BASE_URL + "My-Enquiries"}
                        >
                          {lang.my_enquiries}
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          logout();
                        }}
                      >
                        {lang.logout}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
              {!tokenData.data.isDefaultUser && (
                <Dropdown className="walletdrop" onClick={fetchMyProfile}>
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    <span>
                      {lang.wallet}<i className="drop-icon"></i>
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item className="asd">
                      {walletLoader ? (
                        <span>{lang.wallet_loading}</span>
                      ) : (
                        <Link
                          className="aa"
                          to={process.env.REACT_APP_BASE_URL + "Wallet"}
                        >
                          <i className="wallet-icon"></i>KWD{" "}
                          {walletBalance}
                          {/* <a href="">My Profile</a> */}
                        </Link>
                      )}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </ul>
          </div>
        </div>
      </div>
      <React.Fragment>
        {isLoading && <FulloverLoader />}
        <Login
          showLoginModal={showLoginModal}
          setShowLoginModal={setShowLoginModal}
          setShowRegisterModal={setShowRegisterModal}
          setShowForgotPasswordModal={setShowForgotPasswordModal}
        ></Login>

        <Register
          showRegisterModal={showRegisterModal}
          setShowRegisterModal={setShowRegisterModal}
          setShowLoginModal={setShowLoginModal}
        ></Register>

        <Modal
          show={showForgotPasswordModal}
          onHide={() => setShowForgotPasswordModal(false)}
          className="log_reg_popup"
        >
          <Modal.Header closeButton>
            <Modal.Title>{lang.forgot_password}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Forgot register={register} errors={errors}></Forgot>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="login" onClick={handleSubmit(onForgetSubmit)}>
              {lang.submit}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    </header>
  );
}
