import React from 'react';
import { useSelector } from 'react-redux';
import noImage from "../../assets/images/no_image.jpg"

const ServiceBoxLeft = ({
    serviceDetails
}) => {
    const current_culture = useSelector(state => state.culture.value);


    return (
        <div className='service_box_col_lft'>
            <div className='service_detail_box'>
                <div className='service_detail_image'> <img src={serviceDetails.image} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = noImage;
                }} /> </div>
                {
                    current_culture == 'en' &&
                    <div className='service_detail_description'> {serviceDetails.description_En} </div>
                    ||
                    <div className='service_detail_description'> {serviceDetails.description_Ar} </div>
                }
            </div>
        </div>
    );
}

export default ServiceBoxLeft;
