import React, { useState,useEffect } from "react";

import InnerBanner from "../../components/common/inner_banner";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import {  Link} from 'react-router-dom';

import FlightTicketAcquisitionDetail from "../../assets/images/ticket_acquisition_service.jpg";
import Autocomplete from "react-autocomplete";
import { getAirline, getAirportList, getCountryOrCity, getPhoneCode, setPhoneCodeList } from "../../services/common";
import { getServiceDetailsById } from "../../scripts/common";
import ServiceBoxLeft from "./ServiceBoxLeft";
import { postServices } from "../../services/common";
import FulloverLoader from "../../components/common/FulloverLoader";
import UserList from '../../components/common/UserList';
import ar from "date-fns/locale/ar"; // the locale you want

function FlightTicketAcquisition({setShowLoginModal}) {

  const current_culture = useSelector(state => state.culture.value);
  registerLocale("ar", ar);
  

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm();

  const tokenData =useSelector((state) => state.tokenInfo);
  let lang = useSelector((state) => state.language.data);
  let myProfile = JSON.parse(localStorage.getItem("myProfile"));

  const [departureDate, setDepartureDate] = useState(new Date());
  const [returnDate, setReturnDate] = useState(new Date());
  const [departCityList, setDepartCityList] = useState([]);
  const [departCity, setDepartCity] = useState("");
  const [deptCityLabel, setDeptCityLabel] = useState("")
  const [airportList, setAirportList] = useState([]);
  const [airport, setAirport] = useState("");
  const [airportLabel, setAirportLabel] = useState("")
  const [prefferedAirline, setPrefferedAirline] = useState("")
  const [airlineList, setAirlineList] = useState([])
  const [airlineLabel, setAirlineLabel] = useState("")
  const [tripType, setTripType] = useState("oneway");
  const [serviceDetails, setServiceDetails] = useState();
  const [serviceId, setServiceId] = useState(27);
  const [phoneCode, setPhoneCode] = useState([])
  const [mobCode, setMobCode] = useState(process.env.REACT_APP_MOBCODE)
  const [isLoading, setIsLoading] = useState(false)
  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState('');
  const [walletData, setWalletData] = useState(null)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {

    window.scrollTo(0,0)
    const serviceDetailsData = getServiceDetailsById(serviceId);
    setServiceDetails(serviceDetailsData);

    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList()

      setPhoneCode(phoneCodeList)
    } 

    fetchPhoneCode()
    

  }, []);

  
  const resetForm=()=>{
    reset()
    setMobCode(process.env.REACT_APP_MOBCODE)
    setDepartCity("")
    setDepartCityList([])
    setDeptCityLabel("")
    setAirport("")
    setAirportLabel("")
    setAirportList([])
    setTripType("oneway")
    setDepartureDate(new Date())
    setReturnDate(new Date())
    setPrefferedAirline("")
    setAirlineLabel("")
    setAirlineList([])
    setOnBehalfId("")
    setValue('OnBehalfId',"")
    setUserText('')
    setWalletData(null)
  }

  const departureRequestHandler = async (e) => {
    setDepartCity(e.target.value);
    

    const departCityResponse = await getCountryOrCity(e.target.value)

    if (departCityResponse) {
     

    setDepartCityList(departCityResponse)
    }

    
  };

  const selectDepartHandler = (value,item,onChange) => {
    setDepartCity(value)
    onChange(value)
    setDeptCityLabel(item._source.displayname)

  }

  const airportRequestHandler = async (e) => {
    setAirport(e.target.value);
    let airportList = await getAirportList(e.target.value);

    if (airportList) {
      let airPortList = [];
      airportList.forEach((item) => {
        let { city, name, countryname, code, isallairport } = item._source;
        var label = "";

        if (current_culture == "ar") {
          label = item._source.arabiccity + "-" + item._source.arabicname + "-" + item._source.arabiccountryname + "(" + code + ")";
        } else {
          label = city + "-" + name + "-" + countryname + "(" + code + ")";
        }

        
        

        airPortList.push({
          code: code,
          label: label,
          city: city,
          isallairport: isallairport,
          name: name,
          countryname:countryname
        });
      });
      setAirportList(airPortList);
    }
  };

  const selectAirportHandler = (label, item, onChange) => {
    setAirport(label);
    let { city, name, countryname, code, isallairport } = item;
    setAirportLabel(city + "-" + name + "-" + countryname + "(" + code + ")")
    onChange(label);
  };

  const airlineRequestHandler = async (event) => {

    setPrefferedAirline(event.target.value)
    const airlineListResponse = await getAirline(event.target.value)

    if(airlineListResponse) {
      setAirlineList(airlineListResponse)
      
    }
 }

    const selectAirlineHandler = (value, item,onChange) => {

      setPrefferedAirline(value)
      setAirlineLabel(item._source.airline +' ('+item._source.code+')')
      onChange(value)
    }

  const onSubmit = async (data) => {

    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true)
    } else {
      setIsLoading(true)
    

    
    let flightTicketAcqPrams = {
      ServiceID: serviceId,
      /* OnBehalfId: onBehalfId, */
      Data: JSON.stringify({
        name: data.Name,
        email: data.email,
        mobile_code: mobCode,
        contact_number: data.mobileno,
        country_or_city: deptCityLabel,
        airport: airportLabel,
        request_type: tripType,
        departure_date: Moment(departureDate).format("DD/MM/YYYY"),
        arrival_date: Moment(returnDate).format("DD/MM/YYYY"),
        ticket_class: data.ticketClass,
        no_of_adults: data.adultCount,
        no_of_children: data.childrenCount,
        preferred_airline: airlineLabel,
        special_requests: data.specialRequest,
        additional_remarks: data.additionalInfo,
      }),
    };
    if(onBehalfId) {
      flightTicketAcqPrams["OnBehalfId"] = onBehalfId
    }
    
    const response = await postServices(flightTicketAcqPrams);
    
      if (response) {
        dispatch(alertActions.setPopUpType('success'))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
        dispatch(alertActions.setPopUpData({ heading: lang.sucess, message: lang.form_submitted_sucessfully }))

        /* alert('form submited')
        navigate(process.env.REACT_APP_BASE_URL, { replace: true }); */
        setIsLoading(false)
        resetForm()
      } else {
        dispatch(alertActions.setPopUpType('error'))
        dispatch(alertActions.setPopUpData({ heading: 'Failed', message: 'Something went wrong, Please Try again' }))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
        /* alert('something went wrong ,please try again') */
        setIsLoading(false)
      }

      
  }
  };

  return (
    <React.Fragment>
      {isLoading && <FulloverLoader/>}
      <InnerBanner title={lang.flight_ticket_acquisition}></InnerBanner>

      <section className="content_service_main form_bg">
        <div class="container">
          <div className="form_breadcrumbs">
            <ul>
              <li>
              <Link to={process.env.REACT_APP_BASE_URL+'services'}>{lang.services} </Link>
              </li>
              <li>
                <a className="active" href="">
                  {lang.flight_ticket_acquisition}
                </a>
              </li>
            </ul>
          </div>
          <div className="service_box">
          {serviceDetails && (
              <ServiceBoxLeft serviceDetails={serviceDetails} />
            )}
            <div className="service_box_col_rht">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="service_detail_formbox formstyle">
                  <div className="service_detail_formhd">
                    <h2>{lang.feel_like_contacting_us}</h2>
                    <p>
                    {lang.submit_the_form_below_we_will_get_back_to_you_asap}
                    </p>
                  </div>
                  <hr className="form_hd_hr"></hr>
                  {(myProfile && myProfile.isAgentLogin && ! tokenData.data.isDefaultUser) &&
                    <UserList
                      register={register}
                      onBehalfId={onBehalfId}
                      setOnBehalfId={setOnBehalfId}
                      errors={errors}
                      control={control}
                      userText={userText}
                      setUserText={setUserText}
                      walletData={walletData}
                      setWalletData={setWalletData}
                    />
                  }
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>
                          {lang.name}<span>*</span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          {...register("Name", {
                            required: lang.name_is_required,
                            pattern: {
                              value: /[A-Za-z]/,
                              message: lang.please_enter_valid_name,
                            },
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,
                            },

                            value: "",
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"Name"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>
                          {lang.email}<span>*</span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          {...register("email", {
                            required: lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: lang.invalid_email_address,
                            },

                            value: "",
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"email"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>
                        {lang.contact_number}<span>*</span>
                        </label>
                        <div class="form-group d-flex flex-wrap">
                          <div class="phonecode form_phone_select_arrow position-relative">
                            <select
                              class="form-control"
                              value={mobCode}
                              {...register("phoneCode", {
                                required: {
                                  value: true,
                                  message: lang.phone_code_is_required,
                                },
                                onChange:(e)=>setMobCode(e.target.value),
                                value: mobCode
                              })}
                            >
                              {phoneCode.map((item)=> {
                                return <option value={item}>{item}</option>
                              })}
                              </select>
                              <ErrorMessage
                                errors={errors}
                                name={"phoneCode"}
                                render={({ message }) => (
                                  <span className="error-message">
                                    {message}
                                  </span>
                                )}
                              />
                          </div>
                          <div class="phonenumber">
                            <input
                              type="name"
                              class="form-control"
                              placeholder=" "
                              {...register("mobileno", {
                                required: {
                                  value: true,
                                  message: lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                  lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message: lang.please_enter_atleast_six_numbers,
                                },

                                value: "",
                              })}
                            ></input>
     
                          </div>
						     <ErrorMessage
                              errors={errors}
                              name={"mobileno"}
                              render={({ message }) => (
                                <span className="error-message">{message}</span>
                              )}
                            />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group form_select_arrow DisplayinRmv autocomplete">
                        <label>
                          {lang.select_country_city}<span>*</span>
                        </label>
                        <Controller
                          name={"departCity"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                        <Autocomplete
                          getItemValue={(item) => current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                          items={departCityList}
                          renderItem={(item, isHighlighted) => (
                            <div
                              className={
                                isHighlighted
                                  ? " react-autocomplete-inner active "
                                  : "react-autocomplete-inner"
                              }
                            >
                              {current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                            </div>
                          )}
                          {...register("departCity", {
                            required: {
                              value: true,
                              message: lang.please_select_a_country_city,
                            },
                          })}
                          onChange={(event) => departureRequestHandler(event)}
                          onSelect={(label,item)=>selectDepartHandler(label,item,onChange)}
                          value={departCity}
                        />
                        )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"departCity"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                        {/* <select name="" id="" class="form-control">
                          <option value="">Select</option>
                          <option value="">-</option>
                          <option value="">-</option>
                          <option value="">-</option>
                        </select> */}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group form_select_arrow DisplayinRmv autocomplete">
                        <label>
                          {lang.select_airport}<span>*</span>
                        </label>
                        {/* <select name="" id="" class="form-control">
                          <option value="">Select</option>
                          <option value="">-</option>
                          <option value="">-</option>
                          <option value="">-</option>
                        </select> */}
                        <Controller
                          name={"airport"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <Autocomplete
                            
                              getItemValue={(item) => item.label}
                              items={airportList}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  className={
                                    isHighlighted
                                      ? " react-autocomplete-inner active "
                                      : "react-autocomplete-inner"
                                  }
                                >
                                  {item.label}
                                </div>
                              )}
                              {...register("airport", {
                                required: {
                                  value: true,
                                  message: lang.please_select_an_airport,
                                },
                              })}
                              onChange={airportRequestHandler}
                              value={airport}
                              onSelect={(label, item) =>
                                selectAirportHandler(label, item, onChange)
                              }
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"airport"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <label>&nbsp;</label>
                      <div className="form-group radio-outer">
                        <label className="tripradio">
                          {lang.one_way}
                          <input
                            type="radio"
                            class="form-control"
                            name="flight-trip"
                            defaultChecked={true}
                            onChange={(e) => setTripType("oneway")}
                          ></input>
                          <span class="checkmark"></span>
                        </label>
                        <label className="tripradio">
                          {lang.round_trip}
                          <input
                            type="radio"
                            class="form-control"
                            name="flight-trip"
                            onChange={(e) => setTripType("roundtrip")}
                          ></input>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.departure}<span>*</span>
                        </label>
                        {/* <input type="name" class="form-control" placeholder='DD/MM/YYYY'></input> */}
                        <DatePicker
                          minDate={new Date()}
                          locale={current_culture}
                          dateFormat={"dd/MM/yyyy"}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown={true}
                          selected={departureDate}
                          onChange={(value) => {
                            setDepartureDate(value);
                            setReturnDate(value);
                          }}
                        />
                      </div>
                    </div>
                    <div className={tripType === "oneway" ? "col-lg-6 col-md-6 disable" : "col-lg-6 col-md-6"}>
                      <div className="form-group">
                        <label>
                          {lang.return_date}<span>*</span>
                        </label>
                        <DatePicker
                          disabled={tripType === "oneway"}
                          locale={current_culture}
                          minDate={departureDate}
                          selected={returnDate}
                          dateFormat={"dd/MM/yyyy"}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown={true}
                          onChange={(value) => setReturnDate(value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.ticket_class}<span>*</span>
                        </label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register("ticketClass", {
                            required: {
                              value: true,
                              message: lang.please_select_a_ticket_category,
                            },
                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="First Class">{lang.first_class}</option>
                          <option value="Business Class">{lang.business_class}</option>
                          <option value="Economy Class">{lang.economy_class}</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={"ticketClass"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label>
                        {lang.no_of_adults}<span>*</span>
                        </label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register("adultCount", {
                            required: {
                              value: true,
                              message: lang.please_select_adult_count,
                            },
                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={"adultCount"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.no_of_children}<span>*</span>
                        </label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register("childrenCount", {
                            required: {
                              value: true,
                              message: lang.please_select_children_count,
                            },
                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={"childrenCount"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 ">
                      <div className="form-group DisplayinRmv autocomplete">
                        <label>
                          {lang.preferred_airline}<span>*</span>
                        </label>
                        <Controller
                          name={"airline"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                        <Autocomplete
                          getItemValue={(item) => {
                            const value = (current_culture == "ar" ? item._source.airlinearabic : item._source.airline) +' ('+item._source.code+')'
                            return value
                          }}
                          items={airlineList}
                          renderItem={(item, isHighlighted) => (
                            <div
                              className={
                                isHighlighted
                                  ? " react-autocomplete-inner active "
                                  : "react-autocomplete-inner"
                              }
                            >
                              {(current_culture == "ar" ? item._source.airlinearabic : item._source.airline) +' ('+item._source.code+')'}
                            </div>
                          )}
                          {...register("airline", {
                            required: {
                              value: true,
                              message: lang.please_select_preferred_airline,
                            },
                          })}
                          onChange={(event) => airlineRequestHandler(event)}
                          onSelect={(label,item)=> selectAirlineHandler(label,item,onChange)}
                          value={prefferedAirline}
                        />
                        )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"airline"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>
                          {lang.special_request}
                        </label>
                        <textarea
                          class="form-control"
                          name=""
                          rows=""
                          cols=""
                          {...register("specialRequest")}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>
                          {lang.additional_information}
                        </label>
                        <textarea
                          class="form-control"
                          name=""
                          rows=""
                          cols=""
                          {...register("additionalInfo")}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <button type="submit" class="form_submit_btn">
                        {lang.submit}
                      </button>
                    </div>
                    {tokenData.data.isDefaultUser && <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <p className="form_note">
                       {lang.note_now_you_are_on_guest_user_please}{" "}
                        <a href="#" onClick={()=>setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}
                      </p>
                    </div>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default FlightTicketAcquisition;
