import React from "react";
import BannerSlider from "./banner_slider";
import bnr_new from '../../assets/images/home_banner.jpg';


export default function Banner(){
    return(
        <section className="home-banner">
		<img  src={bnr_new}  />
            <BannerSlider></BannerSlider>
        </section>
    )
}