import React, { useEffect, useState } from 'react';

import InnerBanner from '../../components/common/inner_banner';


import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { getMyBookings } from '../../services/common';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getTokenApiRequest } from '../../services/getTokenApiRequest';


function MyEnquiries() {
  const [services, setServices] = useState([]);
  const [servicesBinded, setServicesBinded] = useState(false);
  const [servicesFiltered, setServicesFiltered] = useState([]);
  const tokenData = useSelector((state) => state.tokenInfo);
  const [filterText, setFilterText] = useState('');
  const lang = useSelector((state) => state.language.data);
  const current_culture = useSelector((state) => state.culture.value);

  const naviagte = useNavigate();
  const [showHideDetails, setshowHideDetails] = useState([]);
  useEffect(() => {
    if (tokenData.data.isDefaultUser) {
      naviagte(process.env.REACT_APP_BASE_URL)
    } else {
      const getServicesData = async () => {
        const response = await getMyBookings()
        if (response) {
          setServices(response)
          setServicesFiltered(response)
          setServicesBinded(true)
        }
      }
      getServicesData();
    }
  }, []);
  const resetFilter = () => {
    setServicesFiltered([...services])
    setFilterText('')
  }
  const filterEnquiry = () => {
    let tempData = [...services];

    setServicesFiltered(tempData.filter((item) => {
      if (item.enquiryID == filterText)
        return true;
      else if (item.service.toLowerCase().includes(filterText.toLowerCase()))
        return true
      else if (item.status.toLowerCase().includes(filterText.toLowerCase()))
        return true
      else if (moment(item.enquiryDate).format('DD/MM/YYYY').toString() == filterText)
        return true
      else if (item.data.name && (item.data.name.toString()).includes(filterText.toString()))
        return true
      else if (item.data.email && (item.data.email.toString()).includes(filterText.toString()))
        return true
      else
        return false

    }))
  }

  
  return (
    <React.Fragment>
      <InnerBanner
        title={lang.my_enquiries}
      ></InnerBanner>
      <section className='enquiries_main '>
        <div class='container'>
          <div className='enquirie_box'>
            <div className='row'>
              <div className='col-lg-6'></div>
              <div className='col-lg-6 enquiry_search'>
                <div className='enquiry_searchoutr'>
                  <h3>{lang.filter}</h3>
                  <div className='srch_lft'>
                    <input type="" id="" name="" class="form-control" value={filterText} onChange={(e) => {
                      setFilterText(e.target.value)
                    }} placeholder=" ">
                    </input>
                    {
                      filterText != "" &&
                      <a class="clearBtn_enquiry" onClick={() => resetFilter()}>X</a>
                    }
                  </div>
                  <div className="srch_rht">
                    <button type="button" onClick={() => filterEnquiry()} id="" class="btn btn-block"><i className='enquiry_icon'></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>


                {
                  servicesFiltered.map((service, sKey) => {
                    
                    return (

                      < div className='enquirylistbox' >
                        <div className='enquiry_list'>
                          <ul>
                            <li>
                              <h3>{lang.enquiry_id}</h3>
                              <p>{service.enquiryID}</p>
                            </li>
                            <li>
                              <h3>{lang.tittle}</h3>
                              <p>{service.service}</p>
                            </li>
                            <li>
                              <h3>{lang.submited_date}</h3>
                              <p>{moment(service.enquiryDate).format('DD/MM/YYYY')}</p>
                            </li>
                            <li>
                              <h3>{lang.status}</h3>
                              <p>{service.status}</p>
                            </li>
                            <li>
                              <h3>{lang.last_updated_date}</h3>
                              <p>{moment(service.updatedDate).format('DD/MM/YYYY')}</p>
                            </li>
                            <li>
							<a href={`${process.env.REACT_APP_BASE_URL}${current_culture}/Voucher?enquiryId=${service.enquiryID}&isMobileApp=false`} target="_blank" rel='noreferrer'>
                            <button type="button"
                                class="enquiry_voucher_btn">{lang.ticket}</button>
                              </a>
                              <button type="button"
                                onClick={(event) => {
                                  if (showHideDetails.includes(service.enquiryID)) {
                                    setshowHideDetails([...showHideDetails].filter(item => item !== service.enquiryID))
                                  } else {
                                    setshowHideDetails([...showHideDetails, service.enquiryID])
                                  }
                                }}
                                class="enquiry_list_btn">{showHideDetails.includes(service.enquiryID) ? lang.hide : lang.view}</button>
                            </li>
                            
							
                           
                          </ul>
                        </div>
                        {
                          showHideDetails.includes(service.enquiryID) &&
                          <div className='enquiry_view_show'>
                            <ul>
                              {Object.entries(service.data).map(([key, data], keyTrip, { length }) => {
                              
                                if (data === undefined || (data).replace(/\s/g, '') == "")
                                  return (<></>)
                                else
                                  return (<>
                                    <li> <span>{lang[key]}</span> <span>{data}</span> </li>
                                  </>
                                  )
                              })}



                              {/* {serviceOrder.map(item => {
                                ;
                                if (serviceDetails[item] === undefined || (serviceDetails[item]).replace(/\s/g, '') == "")
                                  return (<></>)
                                else
                                  return (<>
                                    <li> <span>{lang[item]}</span> <span>{serviceDetails[item]}</span> </li>
                                    <li> <span>{item}</span> <span>{serviceDetails[item]}</span> </li>
                                  </>
                                  )
                              })} */}

                            </ul>
                          </div>
                        }

                      </div>)
                  })
                }
                {
                  (servicesFiltered.length == 0 && services.length != 0) &&
                  <div className="databox_main">
                    <div className="theme_boxWrap">
                      <div className="no_data d-flex flex-column justify-content-center align-items-center mb-2">
                        <i className="no_result_data_filter" />
                        <h4>{lang.no_records_found}</h4>
                        <p>{lang.sry_no_results}</p>
                        <a href="#" className="resetBtn" onClick={() => resetFilter()}>{lang.reset_filter}</a>
                      </div>
                    </div>
                  </div>
                }
                {
                  (services.length == 0 && servicesBinded) &&
                  <div className="databox_main">
                    <div className="theme_boxWrap">
                      <div className="no_data d-flex flex-column justify-content-center align-items-center mb-2">
                        <i className="no_result_data_filter" />
                        <h4>{lang.no_records_found}</h4>
                      </div>
                    </div>
                  </div>
                }


              </div>
            </div>
          </div>
        </div>
      </section >
    </React.Fragment >
  )
}
export default MyEnquiries;