import React from "react";
import ErrorClose from '../../assets/images/error.png';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { alertActions } from "../../redux/alertSlice";



export default function AlertBoxError() {
    let lang = useSelector((state) => state.language.data);

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const isRedirect = useSelector((state) => state.alert.redirect)
    const redirectUrl = useSelector((state) => state.alert.redirectUrl)
    const heading = useSelector((state) => state.alert.heading)
    const message = useSelector((state) => state.alert.message)
    const alertCloseHandler = () => {
        dispatch(alertActions.setPopUpType(''));
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: "" }))
        if (isRedirect) {
            navigate(redirectUrl, { replace: true });

        }
    };

    return (
        <div className="alertboxOverlauy">
            <div className="alertContent error">
                <div className="AlertError">
                    <img src={ErrorClose}></img>
                </div>
                <div className="ErrorData">
                    <h4>{heading}</h4>
                    <p>{message}</p>
                </div>
                <div className="AlertClose" onClick={alertCloseHandler}>
                {lang.close}

                </div>
            </div>
        </div>
    )
} 