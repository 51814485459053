import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Travel from '../../assets/images/service-slider-1.jpg';
import Hotel from '../../assets/images/home_banner.jpg';
import Flight from '../../assets/images/about-img-1.jpg';
import Car from '../../assets/images/about-img-2.jpg';
import Banking from '../../assets/images/service-slider-1.jpg';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { serviceUrlList } from "../../scripts/common";
import noImage from "../../assets/images/no_image.jpg"



export default function HomeServicesSlider() {
    const services = useSelector((state) => state.serviceInfo);
    const current_culture = useSelector(state => state.culture.value);
    let lang = useSelector((state) => state.language.data);

    return (
        <section className="home-services">
            {services.data != null &&
                <div className="container">
                    <h3>{lang.concierage_services}</h3>
                    <div className="services-slider">
                        <OwlCarousel items={4} className="owl-theme" loop nav margin={55}
                            responsive={{
                                0: {
                                    items: 1,
                                },
                                400: {
                                    items: 1,
                                },
                                600: {
                                    items: 2,
                                },
                                768: {
                                    items: 3,
                                    margin: 20,
                                },
                                1000: {
                                    items: 4,
                                }
                            }} >

                            {services.data.map((service, key) => {
                                return (
                                    <div className="slider-box">
                                        <img src={service.thumbnail} onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = noImage;
                                        }} />
                                        {current_culture == 'en' &&
                                            <span className="slider-text">{service.title_En}</span>
                                            ||
                                            <span className="slider-text">{service.title_Ar}</span>
                                        }
                                        {/* service.title_Ar */}
                                        <span className="slider-cta">
                                            <Link className="cta-btn" to={process.env.REACT_APP_BASE_URL + serviceUrlList[service.serviceID]}>{lang.view}</Link>
                                        </span>
                                    </div>
                                )
                            })}
                            {/* <div className="slider-box">
                            <img src={Travel} />
                            <span className="slider-text">Travel Concierge</span>
                            <span className="slider-cta">
                                <a href="#" className="cta-btn">View</a>
                            </span>
                        </div>
                        <div className="slider-box">
                            <img src={Hotel} />
                            <span className="slider-text">Hotel Concierge</span>
                            <span className="slider-cta">
                                <a href="#" className="cta-btn">View</a>
                            </span>
                        </div>
                        <div className="slider-box">
                            <img src={Flight} />
                            <span className="slider-text">Flight Concierge</span>
                            <span className="slider-cta">
                                <a href="#" className="cta-btn">View</a>
                            </span>
                        </div>
                        <div className="slider-box">
                            <img src={Car} />
                            <span className="slider-text">Car Concierge</span>
                            <span className="slider-cta">
                                <a href="#" className="cta-btn">View</a>
                            </span>
                        </div>
                        <div className="slider-box">
                            <img src={Banking} />
                            <span className="slider-text">Banking Concierge</span>
                            <span className="slider-cta">
                                <a href="#" className="cta-btn">View</a>
                            </span>
                        </div> */}
                        </OwlCarousel>
                    </div>
                    <div className="service-view-all">
                        <Link to={process.env.REACT_APP_BASE_URL + 'services'} >{lang.view_all}</Link>
                    </div>
                </div>
            }

        </section>

    )
}