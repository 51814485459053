import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';



const getlanguageinfo = createAsyncThunk('api/langEn', (token) => {

    if (!localStorage.getItem('culture')) {
        localStorage.setItem('culture', process.env.REACT_APP_CULTURE);
    }

    let abc = localStorage.getItem('culture');

    return axios.get(process.env.REACT_APP_API_URL + 'CommonService/GetResourceByCulture?culture=' + localStorage.getItem('culture'), {
        headers: {
            "Authorization": `Bearer ` + token,
        }
    }
    ).then(function (response) {
        return response.data.data[localStorage.getItem('culture')]

    })

});



const languageSlice = createSlice({
    name: 'en_data',
    initialState: {
        data: [],
        error: '',
        loading: true
    },
    extraReducers: {
        [getlanguageinfo.fulfilled]: (state, action) => {

            state.loading = false;
            state.data = action.payload;
        },
        [getlanguageinfo.pending]: (state) => {
            state.loading = true;
        },
        [getlanguageinfo.rejected]: (state) => {
            state.loading = false;
            state.error = "Resource Key Error"
        },

    }

});

export { getlanguageinfo };

export default languageSlice.reducer;