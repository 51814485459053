import React from "react";
import AppImg from '../../assets/images/app-img.jpg';
import QrImg from '../../assets/images/qr-code.svg';
import PlayStoreg from '../../assets/images/play-store.png';
import AppStore from '../../assets/images/app-store.png';
import { useSelector } from "react-redux";


export default function HomeAppSection(){

    let lang = useSelector((state) => state.language.data);

    return(
        <section className="home-app">
            <div className="container">
                <div className="app-dwd-content">
                    <img src={AppImg} />
                    <div className="cont-rgt">
                        <div className="qr-sec">
                            <img src={QrImg} />
                            <div className="qr-sec-cont">
                                
                                <p>{lang.app_tag_line1}.</p>
                                <h4>{lang.app_tag_line2}</h4>
                            </div>
                        </div>
                        <div className="appstore-sec">
                            <a href="http://market.android.com/details?id=com.travel.qgo">
                            <img  src={PlayStoreg} />
                            </a>
                            <a href="https://apps.apple.com/in/app/qgo-concierge/id1662498282">
                            <img src={AppStore} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}