import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import ar from "date-fns/locale/ar"; // the locale you want
import { Link } from 'react-router-dom';

import InnerBanner from "../../components/common/inner_banner";

import LimousineCarrentalServiceDetails from "../../assets/images/limousine_carrental_servicedetail.jpg";
import { getTimeData } from "../../services/common";
import { getServiceDetailsById } from "../../scripts/common";
import ServiceBoxLeft from "./ServiceBoxLeft";
import { postServices } from "../../services/common";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import { setPhoneCodeList, getCountryOrCity, getCountryOrCityorAirport } from "../../services/common";

import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import UserList from '../../components/common/UserList';
import FulloverLoader from "../../components/common/FulloverLoader";

function LimousineCarRental({ setShowLoginModal }) {

  const tokenData = useSelector((state) => state.tokenInfo);
  let lang = useSelector((state) => state.language.data);
  const current_culture = useSelector(state => state.culture.value);
  registerLocale("ar", ar);
  let myProfile = JSON.parse(localStorage.getItem("myProfile"));

  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState('');
  const [walletData, setWalletData] = useState(null)
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [serviceDetails, setServiceDetails] = useState();
  const [serviceId, setServiceId] = useState(9);
  const [isLoading, setIsLoading] = useState(false)
  const [pickupLocation, setPickupLocation] = useState("")
  const [pickupLocationList, setPickupLocationList] = useState([])
  const [pickupLocationLabel, setPickupLocationLabel] = useState("")
  const [dropoffLocation, setDropoffLocation] = useState("")
  const [dropoffLocationList, setDropoffLocationList] = useState([])
  const [dropoffLocationLabel, setDropoffLocationLabel] = useState("")
  const [mobCode, setMobCode] = useState(process.env.REACT_APP_MOBCODE)
  const [selectedService, setSelectedService] = useState("")
  const [phoneCode, setPhoneCode] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {

    window.scrollTo(0, 0)
    const serviceDetailsData = getServiceDetailsById(serviceId);
    setServiceDetails(serviceDetailsData);

    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList()

      setPhoneCode(phoneCodeList)
    }

    fetchPhoneCode()

  }, []);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm();

  const resetForm = () => {
    reset()
    setMobCode(process.env.REACT_APP_MOBCODE)
    setStartDate(new Date())
    setPickupLocation("")
    setPickupLocationLabel("")
    setPickupLocationList([])
    setEndDate(new Date())
    setDropoffLocation("")
    setDropoffLocationLabel("")
    setDropoffLocationList([])
    setSelectedService("")
    setOnBehalfId("")
    setValue('OnBehalfId', "")
    setUserText('')
    setWalletData(null)
  }

  const pickupLocationRequestHandler = async (e) => {
    setPickupLocation(e.target.value);


    const locationResponse = await getCountryOrCityorAirport(e.target.value)

    if (locationResponse) {


      setPickupLocationList(locationResponse)
    }


  };

  const selectPickupLocationHandler = (value, item, onChange) => {
    setPickupLocation(value)
    setPickupLocationLabel(item._source.displayname)
    onChange(value)

  }

  const dropoffLocationRequestHandler = async (e) => {
    setDropoffLocation(e.target.value);


    const locationResponse = await getCountryOrCityorAirport(e.target.value)

    if (locationResponse) {


      setDropoffLocationList(locationResponse)
    }


  };

  const selectDropoffLocationHandler = (value, item, onChange) => {
    setDropoffLocation(value)
    setDropoffLocationLabel(item._source.displayname)
    onChange(value)

  }

  const onSubmit = async (data) => {

    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true)
    } else {
      setIsLoading(true)
      console.log(data);

      let limousineRentalParams = {
        ServiceID: serviceId,
        /* OnBehalfId: onBehalfId, */
        Data: JSON.stringify({
          full_name: `${data.firstName} ${data.lastName}`,
          email: data.email,
          mobile_code: mobCode,
          Phone_number: data.mobileno,
          first_name: data.firstName,
          last_name: data.lastName,
          area_code: "",
          service_type: selectedService,
          start_date: Moment(startDate).format("DD/MM/YYYY"),
          start_time: data.startTime,
          end_time: data.endTime,
          pick_up_location: pickupLocationLabel,
          end_date: Moment(endDate).format("DD/MM/YYYY"),
          drop_off_location: dropoffLocationLabel,
          arrival_flight_no: data.arrFlightNo,
          departure_flight_no: data.deptFlightNo,
          type_of_car: data.carType,
          no_of_piece_of_luggage: data.luggage,
          special_requests: data.specialReq,
        }),
      };
      if (onBehalfId) {
        limousineRentalParams["OnBehalfId"] = onBehalfId
      }
      console.log(limousineRentalParams);
      const response = await postServices(limousineRentalParams);


      if (response) {
        dispatch(alertActions.setPopUpType('success'))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
        dispatch(alertActions.setPopUpData({ heading: lang.sucess, message: lang.form_submitted_sucessfully }))

        resetForm()
        /* alert('form submited')
        navigate(process.env.REACT_APP_BASE_URL, { replace: true }); */
        setIsLoading(false)
      } else {
        dispatch(alertActions.setPopUpType('error'))
        dispatch(alertActions.setPopUpData({ heading: lang.failed, message: lang.something_went_wrong_please_try_again }))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
        /* alert('something went wrong ,please try again') */
        setIsLoading(false)
      }

    }
  };

  console.log(selectedService)
  return (
    <React.Fragment>
      {isLoading && <FulloverLoader />}

      <InnerBanner title={lang.limousine_service_car_rental_form}></InnerBanner>
      <section className="content_service_main form_bg">
        <div class="container">
          <div className="form_breadcrumbs">
            <ul>
              <li>
                <Link to={process.env.REACT_APP_BASE_URL + 'services'}>{lang.services} </Link>
              </li>
              <li>
                <a className="active" href="">
                  {lang.limousine_service_car_rental_form}
                </a>
              </li>
            </ul>
          </div>
          <div className="service_box">
            {serviceDetails && (
              <ServiceBoxLeft serviceDetails={serviceDetails} />
            )}
            <div className="service_box_col_rht">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="service_detail_formbox formstyle">
                  <div className="service_detail_formhd">
                    <h2>{lang.feel_like_contacting_us}</h2>
                    <p>
                      {lang.submit_the_form_below_we_will_get_back_to_you_asap}
                    </p>
                  </div>
                  <hr className="form_hd_hr"></hr>
                  {(myProfile && myProfile.isAgentLogin && !tokenData.data.isDefaultUser) &&
                    <UserList
                      register={register}
                      onBehalfId={onBehalfId}
                      setOnBehalfId={setOnBehalfId}
                      errors={errors}
                      control={control}
                      userText={userText}
                      setUserText={setUserText}
                      walletData={walletData}
                      setWalletData={setWalletData}
                    />
                  }
                  <div className='col-lg-6 col-md-12'>
                    <div className='form-group form_select_arrow'>
                      <label>{lang.please_select_the_service}<span>*</span></label>
                      <select name="" id="" class="form-control"
                        {...register('select_service_type', {
                          required: lang.service_type_is_required,
                          onChange: (event) => setSelectedService(event.target.value)

                        })}
                      >
                        <option value="">{lang.select}</option>
                        <option value="Limousine Service in Kuwait">{lang.limousine_service_in_kuwait}</option>
                        {/* <option value="Airport Pick-up and Drop-off (Kuwait)">{lang.airport_pickup_and_dropoff_kuwait}</option>
                        <option value="Airport Pick-up and Drop-off (Global)">{lang.airport_pickup_and_dropoff_global}</option> */}
                        <option value="Airport Limo Service">{lang.airport_limo_service}</option>
                        <option value="Car Rental- Local / International">{lang.local_and_international_car_rental}</option>
                      </select>
                      <ErrorMessage
                        errors={errors}
                        name={'select_service_type'}
                        render={({ message }) => (
                          <span className='error-message'>{message}</span>
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12 col-md-12">
                      <label>{lang.start}<span>*</span></label>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <DatePicker
                          minDate={new Date()}
                          locale={current_culture}
                          dateFormat={"dd/MM/yyyy"}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown={true}
                          selected={startDate}
                          onChange={(value) => {
                            setStartDate(new Date(value));
                            setEndDate(new Date(value));
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register("startTime")}
                        >
                          {getTimeData.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group DisplayinRmv autocomplete">
                        <label>
                          {lang.pickup_location}<span>*</span>
                        </label>
                        <Controller
                          name={"pickupLocation"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <Autocomplete
                              getItemValue={(item) => current_culture === "ar" ? item._source.arabicname : item._source.name}
                              items={pickupLocationList}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  className={
                                    isHighlighted
                                      ? " react-autocomplete-inner active "
                                      : "react-autocomplete-inner"
                                  }
                                >
                                  {current_culture === "ar" ? item._source.arabicname : item._source.name}
                                </div>
                              )}
                              {...register("pickupLocation", {
                                required: {
                                  value: true,
                                  message: lang.please_select_a_location,
                                },
                              })}
                              onChange={(event) => {pickupLocationRequestHandler(event)
                                event.target.value == "" && onChange(event.target.value)}}
                              onSelect={(label, item) => selectPickupLocationHandler(label, item, onChange)}
                              value={pickupLocation}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"pickupLocation"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-lg-12 col-md-12">
                      <label>{lang.end}<span>*</span></label>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <DatePicker
                          minDate={startDate}
                          locale={current_culture}
                          dateFormat={"dd/MM/yyyy"}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown={true}
                          selected={endDate}
                          onChange={(value) => {
                            setEndDate(new Date(value));
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register("endTime")}
                        >
                          {getTimeData.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group DisplayinRmv autocomplete">
                        <label>
                          {lang.drop_off_location}<span>*</span>
                        </label>
                        <Controller
                          name={"dropoffLocation"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <Autocomplete
                              getItemValue={(item) => current_culture === "ar" ? item._source.arabicname : item._source.name}
                              items={dropoffLocationList}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  className={
                                    isHighlighted
                                      ? " react-autocomplete-inner active "
                                      : "react-autocomplete-inner"
                                  }
                                >
                                  {current_culture === "ar" ? item._source.arabicname : item._source.name}
                                </div>
                              )}
                              {...register("dropoffLocation", {
                                required: {
                                  value: true,
                                  message: lang.please_select_a_location,
                                },
                              })}
                              onChange={(event) => {dropoffLocationRequestHandler(event)
                                event.target.value == "" && onChange(event.target.value)}}
                              onSelect={(label, item) => selectDropoffLocationHandler(label, item, onChange)}
                              value={dropoffLocation}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"dropoffLocation"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.arrival_flight_no}<span>*</span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          placeholder=""
                          {...register("arrFlightNo", {
                            required: {
                              value: true,
                              message: lang.flight_no_required,
                            },
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"arrFlightNo"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {lang.departure_flight_no}<span>*</span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          placeholder=""
                          {...register("deptFlightNo", {
                            required: {
                              value: true,
                              message: lang.flight_no_required,
                            },
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"deptFlightNo"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group form_select_arrow">
                        <label>
                          {lang.type_of_car}<span>*</span><span></span>
                        </label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register("carType", {
                            required: {
                              value: true,
                              message: lang.please_select_car_type,
                            },
                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="Standard">{lang.vehicle_type_standard}</option>
                          <option value="Luxury">{lang.vehicle_type_luxury}</option>
                          <option value="Van">{lang.vehicle_type_van}</option>
                          <option value="SUVs">{lang.vehicle_type_suv}</option>
                        </select>

                        <ErrorMessage
                          errors={errors}
                          name={"carType"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group form_select_arrow">
                        <label>
                          {lang.no_of_pieces_of_luggage}<span>*</span><span></span>
                        </label>
                        <select
                          name=""
                          id=""
                          class="form-control"
                          {...register("luggage", {
                            required: {
                              value: true,
                              message: lang.please_select_luggage_count,
                            },
                          })}
                        >
                          <option value="">{lang.select}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name={"luggage"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>{lang.special_request}:</label>
                        <textarea
                          class="form-control"
                          name=""
                          rows=""
                          cols=""
                          {...register("specialReq")}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>
                          {lang.first_name}<span>*</span><span></span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          placeholder=""
                          {...register("firstName", {
                            required: lang.first_name_is_required,
                            pattern: {
                              value: /[A-Za-z]/,
                              message: lang.please_enter_valid_first_name,
                            },
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,

                            },
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"firstName"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>
                          {lang.last_name}<span>*</span><span></span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          placeholder=""
                          {...register("lastName", {
                            required: lang.last_name_is_required,
                            pattern: {
                              value: /[A-Za-z]/,
                              message: lang.please_enter_valid_last_name,
                            },
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,

                            },
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"lastName"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>
                          {lang.email}<span>*</span>
                        </label>
                        <input
                          type="text"
                          {...register("email", {
                            required: lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: lang.invalid_email_address,
                            },

                            onChange: (e) => { },
                          })}
                          class="form-control"
                        ></input>
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name={"email"}
                        render={({ message }) => (
                          <span className="error-message">{message}</span>
                        )}
                      />{" "}
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>
                          {lang.contact_number}<span>*</span>
                        </label>
                        <div class="form-group d-flex">
                          <div class="phonecode form_phone_select_arrow position-relative">
                            <select
                              class="form-control"
                              value={mobCode}
                              {...register("phoneCode", {
                                required: {
                                  value: true,
                                  message: lang.phone_code_is_required,
                                },
                                onChange: (e) => setMobCode(e.target.value),
                                value: mobCode
                              })}
                            >
                              {phoneCode.map((item) => {
                                return <option value={item}>{item}</option>
                              })}
                            </select>
                            <ErrorMessage
                              errors={errors}
                              name={"phoneCode"}
                              render={({ message }) => (
                                <span className="error-message">
                                  {message}
                                </span>
                              )}
                            />
                          </div>
                          <div class="phonenumber">
                            <input
                              type="name"
                              class="form-control"
                              {...register("mobileno", {
                                required: {
                                  value: true,
                                  message: lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message: lang.please_enter_atleast_six_numbers,
                                },

                                onChange: (e) => { },
                              })}
                              placeholder=" "
                            ></input>
                          </div>
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name={"mobileno"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <button type="submit" class="form_submit_btn">
                        {lang.submit}
                      </button>
                    </div>
                    {tokenData.data.isDefaultUser && <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <p className="form_note">
                        {lang.note_now_you_are_on_guest_user_please}{" "}
                        <a href="#" onClick={() => setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}
                      </p>
                    </div>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default LimousineCarRental;
