import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getUserList } from '../../services/common';
import { ErrorMessage } from '@hookform/error-message';
import Autocomplete from "react-autocomplete";
import { useForm, Controller } from 'react-hook-form';
import PreLoader from './PreLoader';
const UserList = ({
    register,
    onBehalfId,
    setOnBehalfId,
    errors,
    control,
    userText,
    setUserText,
    walletData,
    setWalletData

}) => {

    let lang = useSelector((state) => state.language.data);

    const [userList, setUserList] = useState([]);
    
    useEffect(() => {
        const initilize = async () => {
            const userListResponse = await getUserList();
            let tempUserArray = []
            if (userListResponse) {
                userListResponse.map((user, Uid) => {
                    
                    tempUserArray.push({
                        label: user.firstName + ' ' + user.lastName + ' / ' + user.countryCode + user.phone,
                        id: user.id,
                        wallet:user.wallet
                    })

                })
                setUserList(tempUserArray)
            }
        }
        initilize();
    }, []);
    const selectUser = (value, onChange, item) => {

        
        setOnBehalfId(item.id)
        onChange(value)
        setUserText(value)
        setWalletData(item.wallet)

    }
    if (userList.length == 0)
        return <><PreLoader />  </>
    return (
        <div className='row'>
            <div className='col-lg-6 col-md-12 col-12'>
                <div className='form-group DisplayinRmv autocomplete form_select_arrow'>
                    <label> {lang.on_behalf} </label>


                    <Controller
                        name={"OnBehalfId"}
                        control={control}

                        render={({ field: { onChange, value, name } }) => (
                            <Autocomplete
                                getItemValue={(item) => {

                                    return item.label
                                }
                                }
                                items={userList}

                                renderItem={(item, isHighlighted) => (
                                    <div
                                        className={
                                            isHighlighted
                                                ? " react-autocomplete-inner active "
                                                : "react-autocomplete-inner"
                                        }
                                    >
                                        {item.label}
                                    </div>
                                )}
                                {...register("OnBehalfId", {
                                    required: {
                                        value: true,
                                        message: lang.user_required
                                    },
                                })}
                                shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                onChange={(event) => {
                                    setUserText(event.target.value)
                                    onChange(event.target.value)
                                    setWalletData(null)
                                }}
                                onSelect={(label, item) => selectUser(label, onChange, item)}
                                value={userText}
                            />
                        )}
                    />



                    <ErrorMessage
                        errors={errors}
                        name={'OnBehalfId'}
                        render={({ message }) => (
                            <span className='error-message'>{message}</span>
                        )}
                    />
                </div>
                
            </div>
            {walletData != null && <div className="col-lg-4 col-md-12 col-12">
                <div className='form-group'>
            <label> {lang.wallet_balance}</label>
                  <div className="wallet_amount_box">
                    
                    <p>
                      <span>KWD </span>
                      <span>{walletData}</span>
                    </p>
                  </div>
                  </div>
                </div>}
        </div>
    );
}

export default UserList;
