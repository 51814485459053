import React from "react";
import SuccessTick from "../../assets/images/tick-inside-circle.svg";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import { useNavigate } from "react-router-dom";

export default function AlertBoxSuccess() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const isRedirect = useSelector((state) => state.alert.redirect)
  const redirectUrl = useSelector((state) => state.alert.redirectUrl)
  const heading = useSelector((state) => state.alert.heading)
  const message = useSelector((state) => state.alert.message)
  let lang = useSelector((state) => state.language.data);


  console.log(isRedirect, redirectUrl);

  const alertCloseHandler = () => {
    dispatch(alertActions.setPopUpType(''));
    dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: "" }))
    if (isRedirect) {
      window.scrollTo(0, 0);
      navigate(redirectUrl, { replace: true });

    }

  };

  return (
    <div className="alertboxOverlauy">
      <div className="alertContent">
        <div className="AlertSuccess">
          <img src={SuccessTick}></img>
        </div>
        <div className="SuccessData">
          <h4>{heading}</h4>
          <p>{message}</p>
        </div>
        <div className="AlertClose" onClick={alertCloseHandler}>
          {lang.close}
        </div>
      </div>
    </div>
  );
}
