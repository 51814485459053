import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  getCardTypes,
  getCustomerTypes,
  loginRequest,
  registerRequest,
  checkSpecialUser,
} from "../../services/common";
import { setTokenData } from "../../redux/getBearortoken";
import { useSelector, useDispatch } from "react-redux";
import FulloverLoader from "./FulloverLoader";

import { alertActions } from "../../redux/alertSlice";
import { getMyProfile } from "../../services/common";
import { setPhoneCodeList } from "../../services/common";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

export default function Register({
  showRegisterModal,
  setShowRegisterModal,
  setShowLoginModal,
}) {
  let lang = useSelector((state) => state.language.data);
  const dispatch = useDispatch();
  const {
    register: register,
    handleSubmit: handleSubmit,
    formState: { errors: errors },
    reset,
    resetField,
    getValues,
  } = useForm({ criteriaMode: "all" });
  const [isLoading, setIsLoading] = useState(false);
  const [phoneCode, setPhoneCode] = useState([]);
  const [mobCode, setMobCode] = useState(process.env.REACT_APP_MOBCODE);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [cardTypes, setCardTypes] = useState([]);
  const [customerType, setCustomerType] = useState(1);
  const [isSpecialUser, setIsSpecialUser] = useState(false);
  const [splCountModalOpen, setSplCountModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null)
  const [inputCardNumber, setInputCardNumber] = useState("")



  useEffect(() => {
    const initialize = async () => {
      const phoneCodeList = await setPhoneCodeList();
      setPhoneCode(phoneCodeList);
      
     
      const CustomerTypeList = await getCustomerTypes();
     
      setCustomerTypes(CustomerTypeList);
      const cardTypeList = await getCardTypes();
      setCardTypes(cardTypeList);
      setSelectedCard(cardTypeList.filter((card)=>card.customerType == 5)[0])
    };

    initialize();
  }, []);

  const specialUserCheck = async () => {
    const specialUserCheckRes = await checkSpecialUser();
    setIsSpecialUser(specialUserCheckRes);
  };

  

  const onSubmit = async (data) => {
    setSplCountModalOpen(false);
    setIsLoading(true);
    
    let params = {
      userID: 0,
      firstName: data.firstName,
      lastName: data.lastName,
      email: customerType !== 2 ? data.email : data.kipicemail,
      countryCode: mobCode,
      phone: data.phone,
      password: data.password,
      civilID: customerType !== 2 ? data.civilID : data.empID,
      culture: "en",
      cardNumber: customerType == 5 ? data.cardNumberAbk /* + data.cardNumberAbksuffix */ : data.cardNumber,
      cardTypeId : customerType == 5 ? selectedCard.cardTypeId : null,
      customerType: customerType,
      customerCode: customerType == 4 ? data.CustomerCode : null,
      isSpecialUser: isSpecialUser,
    }
    const response = await registerRequest(params);
  
    if (response) {
      if (response == "user_exists") {
        dispatch(alertActions.setPopUpType("error"));

        dispatch(
          alertActions.setPopUpData({
            heading: lang.user_exists,
            message: lang.user_already_exists,
          })
        );

        dispatch(
          alertActions.setRedirect({
            redirect: false,
            redirectUrl: process.env.REACT_APP_BASE_URL,
          })
        );
        setIsLoading(false);
      } else if (response == "incorrect_code") {
        dispatch(alertActions.setPopUpType("error"));

        dispatch(
          alertActions.setPopUpData({
            heading: lang.error,
            message: lang.incorrect_code,
          })
        );

        dispatch(
          alertActions.setRedirect({
            redirect: false,
            redirectUrl: process.env.REACT_APP_BASE_URL,
          })
        );
        setIsLoading(false);
      } else if (response == "special_count_exceed") {
        setIsSpecialUser(false);
        setSplCountModalOpen(true);
        setShowRegisterModal(false);
        setIsLoading(false);
      } else if (response == "already_used") {
        dispatch(alertActions.setPopUpType("error"));

        dispatch(
          alertActions.setPopUpData({
            heading: lang.error,
            message: lang.already_used,
          })
        );

        dispatch(
          alertActions.setRedirect({
            redirect: false,
            redirectUrl: process.env.REACT_APP_BASE_URL,
          })
        );
        setIsLoading(false);
      } else if (response == "error") {
        dispatch(alertActions.setPopUpType("error"));

        dispatch(
          alertActions.setPopUpData({
            heading: lang.error,
            message: lang.something_went_wrong_please_try_again,
          })
        );

        dispatch(
          alertActions.setRedirect({
            redirect: false,
            redirectUrl: process.env.REACT_APP_BASE_URL,
          })
        );
        setIsLoading(false);
      } else {
        const response = await loginRequest({
          username: customerType !== 2 ? data.email : data.kipicemail,
          password: data.password,
          grandtype: "password",
        });
        if (response) {
          if (response == "invalid") {
            dispatch(alertActions.setPopUpType("error"));
            dispatch(
              alertActions.setRedirect({
                redirect: false,
                redirectUrl: process.env.REACT_APP_BASE_URL,
              })
            );
            setIsLoading(false);
          } else {
            dispatch(setTokenData(response.data));
            setShowRegisterModal(false);

            dispatch(alertActions.setPopUpType("success"));
            dispatch(
              alertActions.setPopUpData({
                heading: lang.sucess,
                message: lang.reg_sucess,
              })
            );

            dispatch(
              alertActions.setRedirect({
                redirect: false,
                redirectUrl: process.env.REACT_APP_BASE_URL,
              })
            );
            setIsLoading(false);

            const myProfile = await getMyProfile();
            console.log(myProfile);

            localStorage.setItem("myProfile", JSON.stringify(myProfile));
          }
        }
      }
    }
  };
  return (
    <>
      {isLoading && <FulloverLoader />}
      <Modal
        show={showRegisterModal}
        onHide={() => setShowRegisterModal(false)}
        className="log_reg_popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>{lang.create_account}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="register_rdo">
            <div class="form-group radio-outer registerFrm">
              {customerTypes.map((customer, cKey) => {
                return (
                  <label class="tripradio">
                    {customer.customerType}
                    <input
                      type="radio"
                      {...register("customerType")}
                      class="form-control"
                      name="flight-trip"
                      value={customer.customerTypeId}
                      checked={customer.customerTypeId == customerType}
                      onChange={() => {
                        setCustomerType(customer.customerTypeId);
                        
                        if (customer.customerTypeId == 4) {
                          specialUserCheck();
                        } else {
                          setIsSpecialUser(false);
                        }
                        resetField("empID");
                        resetField("civilID");
                        resetField("kipicemail");
                        resetField("email");
                      }}
                    ></input>
                    <span class="checkmark"></span>
                  </label>
                );
              })}
              {/* <label class="tripradio">NBK  <input type="radio" class="form-control" name="flight-trip" checked="checked"></input> <span class="checkmark"></span></label>
                            <label class="tripradio">KIPIC <input type="radio" class="form-control" name="flight-trip"></input><span class="checkmark"></span></label>
                            <label class="tripradio">Customer <input type="radio" class="form-control" name="flight-trip"></input><span class="checkmark"></span></label> */}
            </div>
          </div>
          {customerType == 4 && (
            <div className="firstspecialC_msg">
              <h4>
                {lang.kfh_spcl_costumer}:{" "}
                <span className="spec_txt">
                  <i
                    className={
                      isSpecialUser ? "first_msg_tick" : "first_msg_untick"
                    }
                  ></i>{" "}
                  <span>
                    {isSpecialUser ? lang.kfh_spl_yes : lang.kfh_spl_no}
                  </span>
                </span>{" "}
              </h4>
            </div>
          )}

          <div className="log-field">
            <label>{lang.first_name}</label>
            <input
              type="text"
              className="form-control"
              placeholder={lang.first_name}
              id=""
              autoComplete="off"
              {...register("firstName", {
                required: {
                  value: true,
                  message: lang.first_name_is_required,
                },
                minLength: {
                  value: 2,
                  message: lang.please_enter_minimum_two_characters,
                },
                maxLength: {
                  value: 40,
                  message: lang.maximum_fourty_characters,
                },
                pattern: {
                  value: /[A-Za-z]/,
                  message: lang.please_enter_valid_name,
                },
                onChange: (evt) => {
                  evt.target.value = evt.target.value.replace(
                    /[^A-Za-z]+/g,
                    ""
                  );
                },
              })}
            />
            <ErrorMessage
              errors={errors}
              name={"firstName"}
              render={({ message }) => (
                <span className="error-message">{message}</span>
              )}
            />
          </div>
          <div className="log-field">
            <label>{lang.last_name}</label>
            <input
              type="text"
              className="form-control"
              placeholder={lang.last_name}
              id=""
              autoComplete="off"
              {...register("lastName", {
                required: {
                  value: true,
                  message: lang.last_name_is_required,
                },
                minLength: {
                  value: 2,
                  message: lang.please_enter_minimum_two_characters,
                },
                maxLength: {
                  value: 40,
                  message: lang.maximum_fourty_characters,
                },
                pattern: {
                  value: /[A-Za-z]/,
                  message: lang.please_enter_valid_name,
                },
                onChange: (evt) => {
                  evt.target.value = evt.target.value.replace(
                    /[^A-Za-z]+/g,
                    ""
                  );
                },
              })}
            />
            <ErrorMessage
              errors={errors}
              name={"lastName"}
              render={({ message }) => (
                <span className="error-message">{message}</span>
              )}
            />
          </div>
          {customerType === 1 || customerType === 3 || customerType === 4 || customerType === 5 ? (
            <div className="log-field">
              <label>{lang.email}</label>
              <input
                type="text"
                className="form-control"
                placeholder={lang.email}
                id="email"
                autoComplete="off"
                {...register("email", {
                  required: {
                    value: true,
                    message: lang.email_is_required,
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: lang.invalid_email_address,
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name={"email"}
                render={({ message }) => (
                  <span className="error-message">{message}</span>
                )}
              />
            </div>
          ) : (
            <div className="log-field">
              <label>{lang.email}</label>
              <input
                type="text"
                className="form-control"
                placeholder={lang.email}
                id="email"
                autoComplete="off"
                {...register("kipicemail", {
                  required: {
                    value: true,
                    message: lang.email_is_required,
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@kipic.com.kw/i,
                    message: lang.kipic_email_validation,
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name={"kipicemail"}
                render={({ message }) => (
                  <span className="error-message">{message}</span>
                )}
              />
            </div>
          )}

          {customerType === 1 || customerType === 3 || customerType === 4 || customerType === 5 ? (
            <div className="log-field">
              <label>{lang.civil_id_number}</label>
              <input
                type="text"
                className="form-control"
                placeholder={lang.civil_id_number}
                autoComplete="off"
                {...register("civilID", {
                  required: {
                    value: true,
                    message: lang.civil_id_required,
                  },
                  minLength: {
                    value: 12,
                    message: lang.civil_id_validation,
                  },
                  maxLength: {
                    value: 12,
                    message: lang.civil_id_validation,
                  },
                  onChange: (evt) => {
                    evt.target.value = evt.target.value.replace(/[^0-9]+$/, "");
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name={"civilID"}
                render={({ message }) => (
                  <span className="error-message">{message}</span>
                )}
              />
            </div>
          ) : (
            <div className="log-field">
              <label>{lang.kipic_emp_id}</label>
              <input
                type="text"
                className="form-control"
                placeholder={lang.kipic_emp_id}
                autoComplete="off"
                {...register("empID", {
                  required: {
                    value: true,
                    message: lang.emp_Id_req,
                  },
                  minLength: {
                    value: 12,
                    message: lang.emp_id_validation,
                  },
                  maxLength: {
                    value: 12,
                    message: lang.emp_id_validation,
                  },
                  onChange: (evt) => {
                    evt.target.value = evt.target.value.replace(/[^0-9]+$/, "");
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name={"empID"}
                render={({ message }) => (
                  <span className="error-message">{message}</span>
                )}
              />
            </div>
          )}

          {customerType == 4 && (
            <div className="log-field">
              <label>{lang.kfh_customer_code}</label>
              <input
                type="text"
                className="form-control"
                placeholder={"Customer Code"}
                autoComplete="off"
                {...register("CustomerCode", {
                  required: {
                    value: true,
                    message: lang.kfh_cust_code_req_valid,
                  },
                  /* minLength: {
                  value: 12,
                  message: lang.civil_id_validation,
                }, */
                  /* maxLength: {
                  value: 12,
                  message: lang.civil_id_validation,
                }, */
                  /* onChange: (evt) => {
                  evt.target.value = evt.target.value.replace(/[^0-9]+$/, "");
                }, */
                })}
              />
              <ErrorMessage
                errors={errors}
                name={"CustomerCode"}
                render={({ message }) => (
                  <span className="error-message">{message}</span>
                )}
              />
            </div>
          )}

          {customerType == 1 && (
            <div className="log-field">
              <label>{lang.card_number_abk}</label>
              <input
                type="text"
                className="form-control"
                placeholder={lang.card_bin}
                autoComplete="off"
                {...register("cardNumber", {
                  required: {
                    value: true,
                    message: lang.card_num_req,
                  },
                  pattern: {
                    value: /[0-9]{8}/,
                    message: lang.invalid_card,
                  },

                  validate: (value) =>
                    value == "46445202" ||
                    value == "46445208" ||
                    lang.nkb_premium_apply,
                })}
              />
              <ErrorMessage
                errors={errors}
                name={"cardNumber"}
                render={({ message }) => (
                  <span className="error-message">{message}</span>
                )}
              />
            </div>
          )}
  
  {/* ABK card bin option */}
         {/*  {customerType == 5 &&
            <div className="log-field">
              <label>{lang.card_number_abk}</label>
              <div className="form-phone_abk ">

                <div className="abk_form_select_arrow form_select_arrow">
                <select 
                  name={"countryCode"}
                  value={selectedCard ? selectedCard?.cardNumber : null}
                  {...register("cardNumberAbk", {
                    onChange: (e) => {
                      
                      let cardObj = cardTypes.find((card)=> card.cardNumber == e.target.value)
                      resetField("cardNumber");
                      setSelectedCard(cardObj ? cardObj : null)
                    }
                  })}
                >
                  {cardTypes.filter((card)=>card.customerType == 5).map((item) => {
                    return <option value={item.cardNumber}>{item.cardNumber}</option>;
                  })}
                </select>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={lang.pls_select_abk_card}
                  id="cardNumberAbk"
                  autoComplete="off"
                  {...register("cardNumberAbksuffix", {
                    required: {
                      value: true,
                      message: lang.card_num_req,
                    },
                   
                    onChange : (e) =>{
                      
                      setInputCardNumber(e.target.value)}
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name={"cardNumberAbksuffix"}
                  render={({ message }) => (
                    <span className="error-message">{message}</span>
                  )}
                />
              </div>
            </div>
          } */}

            {customerType == 5 &&
            <div className="log-field">
              <label>{lang.pls_select_abk_card}</label>
              <div className="form_select_arrow position-relative">
                <select
                  name={"countryCode"}
                  value={selectedCard ? selectedCard?.cardNumber : null}
                  {...register("cardNumberAbk", {
                    onChange: (e) => {
                      debugger
                      let cardObj = cardTypes.find((card)=> card.cardNumber == e.target.value)
                      resetField("cardNumber");
                      setSelectedCard(cardObj ? cardObj : null)
                    }
                  })}
                >
                  {cardTypes.filter((card)=>card.customerType == 5).map((item) => {
                    return <option value={item.cardNumber}>{item.cardNumber}</option>;
                  })}
                </select>
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder={lang.card_bin}
                  id="cardNumberAbk"
                  autoComplete="off"
                  {...register("cardNumberAbk", {
                    required: {
                      value: true,
                      message: lang.card_num_req,
                    },
                    pattern: {
                      value: /[0-9]/,
                      message: lang.invalid_card,
                    },
  
                    validate: (value) =>
                      abkCardNumberValidation(value)||
                      lang.nkb_premium_apply,
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name={"phone"}
                  render={({ message }) => (
                    <span className="error-message">{message}</span>
                  )}
                /> */}
              </div>
            </div>
          }

  {/* ABK card bin option */}

          <div className="log-field">
            <label>{lang.Phone_number}</label>
            <div className="form-phone">
              <div className="form_select_arrow_arrow form_select_arrow">
              <select
                name={"countryCode"}
                value={mobCode}
                {...register("countryCode", {
                  onChange: (e) => setMobCode(e.target.value),
                  value: mobCode,
                })}
              >
                {phoneCode.map((item) => {
                  return <option value={item}>{item}</option>;
                })}
              </select>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder={lang.phone_number}
                id="PhoneNumber"
                autoComplete="off"
                {...register("phone", {
                  required: {
                    value: true,
                    message: lang.phone_number_is_required,
                  },
                  minLength: {
                    value: 5,
                    message: lang.enter_min_5_numbers,
                  },
                  maxLength: {
                    value: 15,
                    message: lang.please_enter_no_more_than_fifteen_numbers,
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: lang.invalid_phone_number,
                  },
                  onChange: (evt) => {
                    evt.target.value = evt.target.value.replace(/[^0-9]+$/, "");
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name={"phone"}
                render={({ message }) => (
                  <span className="error-message">{message}</span>
                )}
              />
            </div>
          </div>
          <div className="log-field">
            <label>{lang.password}</label>
            <input
              type="password"
              className="form-control"
              placeholder={lang.password}
              id="password"
              {...register("password", {
                required: {
                  value: true,
                  message: lang.password_req,
                },
                minLength: {
                  value: 8,
                  message: lang.password_required_pattern,
                },
                pattern: {
                  value:
                    /^[a-zA-Z0-9!@#$%^&*()_=\[\]{};':"\\|\\~\\`,.<>\/?+-]+$/ &&
                    /[a-zA-Z]/ &&
                    /^[0-9]+$/ &&
                    /[!@#$%^&*()_=\[\]{};':"\\|\\~\\`,.<>\/?+-]/,
                  message: lang.password_required_pattern,
                },
              })}
            />
            <ErrorMessage
              errors={errors}
              name={"password"}
              render={({ message }) => (
                <span className="error-message">{message}</span>
              )}
            />
          </div>
          <div className="log-field">
            <label>{lang.confirm_password}</label>
            <input
              type="password"
              className="form-control"
              placeholder={lang.confirm_password}
              id="confirm_pwd"
              {...register("confirm_pwd", {
                required: {
                  value: true,
                  message: lang.confirm_password_required,
                },
                validate: (value) =>
                  value === getValues().password || lang.password_mismatch,
              })}
            />
            <ErrorMessage
              errors={errors}
              name={"confirm_pwd"}
              render={({ message }) => (
                <span className="error-message">{message}</span>
              )}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="login" onClick={handleSubmit(onSubmit)}>
            {lang.register}
          </Button>
          <div className="log_sign">
            <p>{lang.have_an_account}</p>
            <a
              onClick={() => {
                setShowRegisterModal(false);
                setShowLoginModal(true);
              }}
            >
              {lang.login}
            </a>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Special user count exceed modal */}
      <Modal
        show={splCountModalOpen}
        onHide={() => setSplCountModalOpen(false)}
        backdrop="static"
        animation={false}
        className="modal_alrt log_reg_popup"
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>{lang.kfh_spl_user_exceed}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn btn-Cancel"
            onClick={() => setSplCountModalOpen(false)}
          >
            {lang.spl_user_exceed_cancel}
          </Button>
          <Button
            variant="login"
            className="btn btn-login"
            onClick={handleSubmit(onSubmit)}
          >
            {lang.spl_user_exceed_continue}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Special user count exceed modal */}
    </>
  );
}
