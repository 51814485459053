import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import qs from 'qs'
const url = process.env.REACT_APP_API_URL + 'token';
const urlRefreshToken = process.env.REACT_APP_API_URL + 'refreshToken';
const getTokenDataInfo = createAsyncThunk('api/token', () => {
    const tokenSession = localStorage.getItem('token')
    if (tokenSession) {

        const tokenData = JSON.parse(tokenSession)

        const now = new Date()
        const expiry = new Date(tokenData.expiry);
        if (now > expiry) {
            localStorage.removeItem('token')

        } else {

            return axios.post(urlRefreshToken, {
                token: tokenData.token,
            }
            ).then((response) => {
                if (response.data.error != null) {
                    localStorage.removeItem('myProfile');
                    return axios.post(url, {
                        username: 'qgo@caxita.com',
                        password: '321',
                        grandtype: 'password'
                    }
                    ).then((response) => {
                        console.log(response);
                        if (response.data.data) {

                            localStorage.setItem('token', JSON.stringify({
                                ...response.data.data,
                                expiryTime: response.data.data.expiry,
                            }));
                        }
                        return response.data.data;
                    })
                } else {

                    if (response.data.data) {
                        const now = new Date()
                        localStorage.setItem('token', JSON.stringify({
                            ...response.data.data,
                            expiryTime: response.data.data.expiry,
                        }));
                    }
                }
                return response.data.data;
            }).catch(()=>{
                return axios.post(url, {
                    username: 'qgo@caxita.com',
                    password: '321',
                    grandtype: 'password'
                }
                ).then((response) => {
                    console.log(response);
                    if (response.data.data) {

                        localStorage.setItem('token', JSON.stringify({
                            ...response.data.data,
                            expiryTime: response.data.data.expiry,
                        }));
                    }
                    return response.data.data;
                })
            })

        }
    }
    return axios.post(url, {
        username: 'qgo@caxita.com',
        password: '321',
        grandtype: 'password'
    }
    ).then((response) => {
        console.log(response);
        if (response.data.data) {

            localStorage.setItem('token', JSON.stringify({
                ...response.data.data,
                expiryTime: response.data.data.expiry,
            }));
        }
        return response.data.data;
    })
});

const tokenInfoSlice = createSlice({
    name: 'data',
    initialState: {
        data: {
            "token": "0",
            "token_type": "",
            "expires_in": "",
            "refresh_token": "",
            "UserName": "",
            "Name": "",
            "IsAuthorized": "",
            "IsLoginUser": "",
            "Role": "",
            ".issued": "",
            ".expires": ""
        },
        error: '',
        loading: true
    },
    reducers: {
        setTokenData(state, action) {
            state.data = action.payload;
        },
        setTokenErrorData(state, action) {
            state.loading = false;
            state.error = "Token api error"
        },

    },
    extraReducers: {
        [getTokenDataInfo.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {

                state.data = action.payload;
            } else {
                state.error = "Token api error"
            }
        },
        [getTokenDataInfo.pending]: (state) => {
            state.loading = true;
        },
        [getTokenDataInfo.rejected]: (state) => {
            state.loading = false;
            state.error = "Token api error"
        },

    }

});

export { getTokenDataInfo };
export const { setTokenData } = tokenInfoSlice.actions
export default tokenInfoSlice.reducer;