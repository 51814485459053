import React, { useEffect } from "react";
import Banner from "../components/home/banner";
import HomeAboutProject from "../components/home/about_project";
import HomeServicesSlider from "../components/home/home_services";
import HomeAppSection from "../components/home/home_app_download";


export default function HomePage(){
    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);
    return(
        <React.Fragment>
            <Banner></Banner>
            <HomeAboutProject></HomeAboutProject>
            <HomeServicesSlider></HomeServicesSlider>
            <HomeAppSection></HomeAppSection>
        </React.Fragment>
    )
}