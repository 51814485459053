import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import ar from "date-fns/locale/ar"; // the locale you want
import { Link } from 'react-router-dom';

import InnerBanner from "../../components/common/inner_banner";

import MICEInternationalWeddingPlanningDetail from "../../assets/images/wedding_planning_service.jpg";
import { getServiceDetailsById } from "../../scripts/common";
import ServiceBoxLeft from "./ServiceBoxLeft";
import { postServices } from "../../services/common";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import FulloverLoader from "../../components/common/FulloverLoader";
import UserList from '../../components/common/UserList';
import { setPhoneCodeList, getCountryOrCity } from "../../services/common";

function MICEInternationalWeddingPlanning({ setShowLoginModal }) {

  const tokenData = useSelector((state) => state.tokenInfo);
  const current_culture = useSelector(state => state.culture.value);
  registerLocale("ar", ar);
  let myProfile = JSON.parse(localStorage.getItem("myProfile"));

  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState('');
  const [walletData, setWalletData] = useState(null)
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [serviceDetails, setServiceDetails] = useState();
  const [serviceId, setServiceId] = useState(13);
  const [phoneCode, setPhoneCode] = useState([])
  const [location, setLocation] = useState("")
  const [locationList, setLocationList] = useState([])
  const [locationLabel, setLocationLabel] = useState("")
  const [mobCode, setMobCode] = useState(process.env.REACT_APP_MOBCODE)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let lang = useSelector((state) => state.language.data);


  useEffect(() => {
    window.scrollTo(0, 0)
    const serviceDetailsData = getServiceDetailsById(serviceId);
    setServiceDetails(serviceDetailsData);

    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList()

      setPhoneCode(phoneCodeList)
    }

    fetchPhoneCode()

  }, []);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm();

  const resetForm = () => {
    reset()
    setMobCode(process.env.REACT_APP_MOBCODE)
    setLocation("")
    setLocationLabel("")
    setLocationList([])
    setFromDate(new Date())
    setToDate(new Date())
    setOnBehalfId("")
    setValue('OnBehalfId', "")
    setUserText('')
    setWalletData(null)

  }

  const locationRequestHandler = async (e) => {
    setLocation(e.target.value);


    const locationResponse = await getCountryOrCity(e.target.value)

    if (locationResponse) {
      console.log("deaprtCityList", locationResponse)

      setLocationList(locationResponse)
    }


  };

  const selectLocationHandler = (value, item, onChange) => {
    setLocation(value)
    setLocationLabel(item._source.displayname)
    onChange(value)

  }

  const onSubmit = async (data) => {

    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true)
    } else {

      setIsLoading(true)
      console.log(data);

      let weddingPlanningParams = {
        ServiceID: serviceId,
       /*  OnBehalfId: onBehalfId, */
        Data: JSON.stringify({
          name: data.Name,
          email: data.email,
          mobile_code: data.phoneCode,
          contact_number: mobCode,
          destination: locationLabel,
          subject: data.subject,
          from: Moment(fromDate).format("DD/MM/YYYY"),
          to: Moment(toDate).format("DD/MM/YYYY"),
          additional_information: data.additionalInfo,
          special_requests: data.specialReq,
        }),
      };
      if(onBehalfId) {
        weddingPlanningParams["OnBehalfId"] = onBehalfId
      }
      console.log(weddingPlanningParams);
      const response = await postServices(weddingPlanningParams);


      if (response) {
        dispatch(alertActions.setPopUpType('success'))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
        dispatch(alertActions.setPopUpData({ heading: lang.sucess, message: lang.form_submitted_sucessfully }))

        resetForm()
        /* alert('form submited')
        navigate(process.env.REACT_APP_BASE_URL, { replace: true }); */
        setIsLoading(false)
      } else {
        dispatch(alertActions.setPopUpType('error'))
        dispatch(alertActions.setPopUpData({ heading: lang.failed, message: lang.something_went_wrong_please_try_again }))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))
        /* alert('something went wrong ,please try again') */
        setIsLoading(false)
      }

    }
  };

  return (
    <React.Fragment>
      {isLoading && <FulloverLoader />}

      <InnerBanner title={lang.mice_international_wedding_planning}></InnerBanner>

      <section className="content_service_main form_bg">
        <div class="container">
          <div className="form_breadcrumbs">
            <ul>
              <li>
                <Link to={process.env.REACT_APP_BASE_URL + 'services'}>{lang.services} </Link>
              </li>
              <li>
                <a className="active" href="">
                  {lang.mice_international_wedding_planning}
                </a>
              </li>
            </ul>
          </div>
          <div className="service_box">
            {serviceDetails && (
              <ServiceBoxLeft serviceDetails={serviceDetails} />
            )}
            <div className="service_box_col_rht">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="service_detail_formbox formstyle">
                  <div className="service_detail_formhd">
                    <h2>{lang.feel_like_contacting_us}</h2>
                    <p>
                      {lang.submit_the_form_below_we_will_get_back_to_you_asap}
                    </p>
                  </div>
                  <hr className="form_hd_hr"></hr>

                  {(myProfile && myProfile.isAgentLogin && !tokenData.data.isDefaultUser) &&
                    <UserList
                      register={register}
                      onBehalfId={onBehalfId}
                      setOnBehalfId={setOnBehalfId}
                      errors={errors}
                      control={control}
                      userText={userText}
                      setUserText={setUserText}
                      walletData={walletData}
                      setWalletData={setWalletData}

                    />
                  }
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>
                          {lang.name}<span>*</span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          {...register("Name", {
                            required: lang.name_is_required,
                            pattern: {
                              value: /[A-Za-z]/,
                              message: lang.please_enter_valid_name,
                            },
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,
                            },

                            value: "",
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"Name"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>
                          {lang.email}<span>*</span>
                        </label>
                        <input
                          type="name"
                          class="form-control"
                          {...register("email", {
                            required: lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: lang.invalid_email_address,
                            },

                            value: "",
                          })}
                        ></input>
                        <ErrorMessage
                          errors={errors}
                          name={"email"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>
                          {lang.contact_number}<span>*</span>
                        </label>
                        <div class="form-group d-flex flex-wrap">
                          <div class="phonecode form_phone_select_arrow position-relative">
                            <select
                              class="form-control"
                              value={mobCode}
                              {...register("phoneCode", {
                                required: {
                                  value: true,
                                  message: lang.phone_code_is_required,
                                },
                                onChange: (e) => setMobCode(e.target.value),
                                value: mobCode
                              })}
                            >
                              {phoneCode.map((item) => {
                                return <option value={item}>{item}</option>
                              })}
                            </select>
                            <ErrorMessage
                              errors={errors}
                              name={"phoneCode"}
                              render={({ message }) => (
                                <span className="error-message">
                                  {message}
                                </span>
                              )}
                            />
                          </div>
                          <div class="phonenumber">
                            <input
                              type="name"
                              class="form-control"
                              placeholder=" "
                              {...register("mobileno", {
                                required: {
                                  value: true,
                                  message: lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message: lang.please_enter_atleast_six_numbers,
                                },

                                value: "",
                              })}
                            ></input>

                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={"mobileno"}
                            render={({ message }) => (
                              <span className="error-message">{message}</span>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group form_select_arrow DisplayinRmv autocomplete">
                        <label>
                          {lang.destination}<span>*</span>
                        </label>
                        <Controller
                          name={"location"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <Autocomplete
                              getItemValue={(item) => current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                              items={locationList}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  className={
                                    isHighlighted
                                      ? " react-autocomplete-inner active "
                                      : "react-autocomplete-inner"
                                  }
                                >
                                  {current_culture === "ar" ? item._source.displaynamear : item._source.displayname}
                                </div>
                              )}
                              {...register("location", {
                                required: {
                                  value: true,
                                  message: lang.please_select_a_location,
                                },
                              })}
                              onChange={(event) => locationRequestHandler(event)}
                              onSelect={(label, item) => selectLocationHandler(label, item, onChange)}
                              value={location}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"location"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group form_select_arrow">
                        <label>
                          {lang.subject}<span>*</span>
                        </label>
                        <select name="" id="" class="form-control" {...register("subject", { required: lang.pls_select_subject })}>
                          <option value="">{lang.select}</option>
                          <option value="Meeting">{lang.Meeting}</option>
                          <option value="Incentive">{lang.Incentive}</option>
                          <option value="Conference">{lang.Conference}</option>
                          <option value="Exhibition">{lang.Exhibition}</option>
                          <option value="Weeding">{lang.Weeding}</option>
                        </select>
                      </div>
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name={"subject"}
                      render={({ message }) => (
                        <span className="error-message">{message}</span>
                      )}
                    />


                    <div className="col-lg-12 col-md-12">
                      <div className="row">


                        <div className="col-lg-4 col-md-6">
                          <div className="form-group form_select_arrow">
                            <label>
                              {lang.from}<span>*</span>
                            </label>
                            <DatePicker
                              minDate={new Date()}
                              locale={current_culture}
                              dateFormat={"dd/MM/yyyy"}
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown={true}
                              showYearDropdown={true}
                              selected={fromDate}
                              onChange={(value) => {
                                setFromDate(value);
                                setToDate(value);
                              }}
                            />
                          </div>
                        </div>


                        <div className="col-lg-4 col-md-6">
                          <div className="form-group form_select_arrow">
                            <label>
                              {lang.to}<span>*</span>
                            </label>
                            <DatePicker
                              minDate={fromDate}
                              locale={current_culture}
                              dateFormat={"dd/MM/yyyy"}
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown={true}
                              showYearDropdown={true}
                              selected={toDate}
                              onChange={(value) => {
                                setToDate(value);
                              }}
                            />
                          </div>
                        </div>


                      </div>
                    </div>






                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>{lang.additional_information}</label>
                        <textarea
                          class="form-control"
                          name=""
                          rows=""
                          cols=""
                          {...register("additionalInfo")}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>{lang.special_request}</label>
                        <textarea
                          class="form-control"
                          name=""
                          rows=""
                          cols=""
                          {...register("specialReq")}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <button type="submit" class="form_submit_btn">
                        {lang.submit}
                      </button>
                    </div>
                    {tokenData.data.isDefaultUser && <div className="col-lg-12 col-md-12 d-flex justify-content-end">
                      <p className="form_note">
                        {lang.note_now_you_are_on_guest_user_please}{" "}
                        <a href="#" onClick={() => setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}
                      </p>
                    </div>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default MICEInternationalWeddingPlanning;
