import React, { useState, useEffect } from 'react';

import InnerBanner from '../../components/common/inner_banner';

import RestaurantReservationServiceDetails from "../../assets/images/restaurant_reservation_servicedetail.jpg"
import Autocomplete from "react-autocomplete";

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Link } from 'react-router-dom';

import { getServiceDetailsById } from '../../scripts/common';
import ServiceBoxLeft from './ServiceBoxLeft';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getCountryOrCity, getHotels, getTimeData, postServices, setPhoneCodeList } from '../../services/common'
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../redux/alertSlice";
import FulloverLoader from '../../components/common/FulloverLoader';
import UserList from '../../components/common/UserList';

const serviceId = 15;
function RestaurantReservation({ setShowLoginModal }) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    clearErrors
  } = useForm();

  const [BusinessBankingServiceDetailsParams, setBusinessBankingServiceDetailsParams] = useState({
    name: '',
    email: '',
    phoneCode: '',
    contact_number: '',
    service: '',
    additionalInformation: ''

  })
  let lang = useSelector((state) => state.language.data);

  let myProfile = JSON.parse(localStorage.getItem("myProfile"));

  const [onBehalfId, setOnBehalfId] = useState();
  const [userText, setUserText] = useState('');
  const [walletData, setWalletData] = useState(null)



  const [date, setDate] = useState();
  const [phoneCode, setPhoneCode] = useState([])
  const [mobileCode, setMobileCode] = useState(process.env.REACT_APP_MOBCODE);

  const [hotel, setHotel] = useState("")
  const [City, setCity] = useState("")
  const [cityList, setCityList] = useState([])
  let navigate = useNavigate();
  const tokenData = useSelector((state) => state.tokenInfo);
  const [hotelList, setHotelList] = useState([])

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [serviceDetails, setserviceDetails] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);

    const serviceDetailsData = getServiceDetailsById(serviceId);
    setserviceDetails(serviceDetailsData)
    const fetchPhoneCode = async () => {
      const phoneCodeList = await setPhoneCodeList()

      setPhoneCode(phoneCodeList)
    }

    fetchPhoneCode()
  }, []);
  useEffect(() => {
    //  alert('in')
  }, [onBehalfId]);
  const cityRequestHandler = async (e) => {
    setCity(e.target.value);

    const cityResponse = await getCountryOrCity(e.target.value)

    if (cityResponse) {

      setCityList(cityResponse)
    }
  };

  const selectCityHandler = (value, onChange) => {
    setCity(value)
    onChange(value)

  }
  const hotelRequestHandler = async (e) => {
    setHotel(e.target.value);

    const hotelResponse = await getHotels(e.target.value)

    if (hotelResponse) {

      setHotelList(hotelResponse)
    }
  };

  const selectHotelHandler = (value, onChange) => {
    setHotel(value)
    onChange(value)

  }
  const onSubmit = async (data) => {
    
    if (tokenData.data.isDefaultUser) {
      setShowLoginModal(true)
    } else {
      setIsLoading(true)

      let restaurantReservation = {
        ServiceID: serviceId,
       /*  OnBehalfId: onBehalfId, */
        Data: JSON.stringify({
          name: data.name,
          email: data.email,
          contact_number: data.contact_number,
          mobile_code: data.mobileCode,
          date_of_reservation: moment(data.date_of_reservation).format(
            "DD/MM/YYYY"
          ),
          time_of_reservation: data.time_of_reservation,
          name_of_restaurant: data.name_of_restaurant,
          restaurant_location: data.restaurant_location,
          no_of_adults: data.no_of_adults,
          no_of_children: data.no_of_children,
          select_course: data.select_course,
          additional_remarks: data.additional_remarks,
          special_requests: data.special_requests,
          // fieldOrder:'name,email,mobile_code,contact_number,date_of_reservation,time_of_reservation,name_of_restaurant,restaurant_location,no_of_adults,no_of_children,select_course,additional_remarks,special_requests'

        })
      }
      if(onBehalfId) {
        restaurantReservation["OnBehalfId"] = onBehalfId
      }
      
      const response = await postServices(restaurantReservation);
      if (response) {
        dispatch(alertActions.setPopUpType('success'))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: "" }))
        dispatch(alertActions.setPopUpData({ heading: lang.sucess, message: lang.form_submitted_sucessfully }))
        resetForm();
        setIsLoading(false)

      } else {
        dispatch(alertActions.setPopUpType('error'))
        dispatch(alertActions.setPopUpData({ heading: lang.failed, message: lang.something_went_wrong_please_try_again }))
        dispatch(alertActions.setRedirect({ redirect: false, redirectUrl: process.env.REACT_APP_BASE_URL }))

        setIsLoading(false)
      }
    }

  }
  const resetForm = () => {
    reset()
    setMobileCode(process.env.REACT_APP_MOBCODE)
    setCity('')
    setHotel('')
    setOnBehalfId('')
    setValue('OnBehalfId', "")
    setUserText('')
    setWalletData(null)
  }
  return (
    <React.Fragment>
      {
        isLoading &&
        <FulloverLoader />
      }
      <InnerBanner
        title={lang.restaurants_reservation}
      ></InnerBanner>
      <section className='content_service_main form_bg'>
        <div class='container'>
          <div className='form_breadcrumbs'>
            <ul>
              <li><Link to={process.env.REACT_APP_BASE_URL + 'services'}>{lang.services} </Link></li>
              <li><a className='active' href=''>{lang.restaurants_reservation}</a></li>
            </ul>
          </div>
          <div className='service_box'>
            {
              serviceDetails &&
              <ServiceBoxLeft
                serviceDetails={serviceDetails}
              />
            }
            <div className='service_box_col_rht'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='service_detail_formbox formstyle'>
                  <div className='service_detail_formhd'>
                    <h2>{lang.feel_like_contacting_us}</h2>
                    <p>{lang.submit_the_form_below_we_will_get_back_to_you_asap}</p>
                  </div>
                  <hr className='form_hd_hr'>
                  </hr>
                  {(myProfile && myProfile.isAgentLogin && !tokenData.data.isDefaultUser) &&
                    <UserList
                      register={register}
                      onBehalfId={onBehalfId}
                      setOnBehalfId={setOnBehalfId}
                      errors={errors}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      control={control}
                      userText={userText}
                      setUserText={setUserText}
                      walletData={walletData}
                      setWalletData={setWalletData}

                    />
                  }
                  <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.name}<span></span></label>
                        <input type="text" class="form-control"

                          {...register('name', {
                            required: lang.name_is_required,
                            minLength: {
                              value: 2,
                              message: lang.please_enter_minimum_two_characters,

                            },
                            pattern: {
                              value: /[A-Za-z]/,
                              message: lang.please_enter_valid_name,
                            },
                          })}
                        >
                        </input>
                        <ErrorMessage
                          errors={errors}
                          name={'name'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.email}<span></span></label>
                        <input type="text" class="form-control"

                          {...register('email', {
                            required: lang.email_is_required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: lang.invalid_email_address,
                            },


                          })}
                        >
                        </input>
                        <ErrorMessage
                          errors={errors}
                          name={'email'}
                          render={({ message }) => (
                            <span className='error-message'>{message}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.contact_number}<span></span></label>
                        <div class="form-group d-flex flex-wrap">
                          <div class="phonecode form_phone_select_arrow position-relative">
                            <select class="form-control"
                              {...register('mobileCode', {
                                required: {
                                  value: true,
                                  message:
                                    lang.phone_code_is_required,
                                },
                                onChange: (e) => {
                                  setMobileCode(e.target.value)
                                }, value: mobileCode
                              })}
                            >
                              {phoneCode.map((item, key) => {
                                return <option selected={mobileCode == item ? 'selected' : ''} value={item}>{item}</option>
                              })}
                            </select>
                          </div>
                          <div class="phonenumber">

                            <input type="text" class="form-control" placeholder=" "

                              {...register('contact_number', {
                                required: {
                                  value: true,
                                  message:
                                    lang.phone_number_is_required,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.invalid_phone_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_fifteen_numbers,
                                },
                                minLength: {
                                  value: 6,
                                  message:
                                    lang.please_enter_atleast_six_numbers,
                                },

                                onChange: e => {

                                },
                              })}
                            >
                            </input>
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={'contact_number'}
                            render={({ message }) => (
                              <span className='error-message'>{message}</span>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group DisplayinRmv autocomplete'>
                        <label>{lang.name_of_restaurant}<span>*</span></label>



                        <Controller
                          name={"name_of_restaurant"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <Autocomplete
                              getItemValue={(item) => item._source.displayname}
                              items={hotelList}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  className={
                                    isHighlighted
                                      ? " react-autocomplete-inner active "
                                      : "react-autocomplete-inner"
                                  }
                                >
                                  {item._source.displayname}
                                </div>
                              )}
                              {...register("name_of_restaurant", {
                                required: {
                                  value: true,
                                  message: lang.please_select_a_restaurant,
                                },
                              })}
                              onChange={(event) => hotelRequestHandler(event)}
                              onSelect={(label) => selectHotelHandler(label, onChange)}
                              value={hotel}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"name_of_restaurant"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />




                      </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                      <div className='form-group form_select_arrow DisplayinRmv autocomplete'>
                        <label>{lang.please_select_restaurant_location}<span>*</span></label>

                        <Controller
                          name={"restaurant_location"}
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <Autocomplete
                              getItemValue={(item) => item._source.displayname}
                              items={cityList}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  className={
                                    isHighlighted
                                      ? " react-autocomplete-inner active "
                                      : "react-autocomplete-inner"
                                  }
                                >
                                  {item._source.displayname}
                                </div>
                              )}
                              {...register("restaurant_location", {
                                required: {
                                  value: true,
                                  message: lang.please_select_restaurant_location,
                                },
                              })}
                              onChange={(event) => cityRequestHandler(event)}
                              onSelect={(label) => selectCityHandler(label, onChange)}
                              value={City}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"restaurant_location"}
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />






                      </div>
                    </div>
                    <div className='col-lg-8 col-md-12'>
                      <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                          <label>{lang.date_time_of_reservation}<span>*</span></label>
                        </div>
                        <div className='col-lg-6 col-md-6'>
                          <div className='form-group'>
                            <Controller
                              name={'date_of_reservation'}
                              control={control}
                              render={({
                                field: { onChange, value },
                              }) => (
                                <DatePicker
                                  minDate={new Date()}
                                  dateFormat={'dd/MM/yyyy'}
                                  className="form-control"
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown={true}
                                  showYearDropdown={true}
                                  selected={value}
                                  onChange={date => {
                                    
                                    onChange(date)
                                    setDate(new Date(date))
                                  }}
                                  value={date}
                                />
                              )}
                              {...register('date_of_reservation', {
                                required: lang.date_is_required,
                                value: date,
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={'date_of_reservation'}
                              render={({ message }) => (
                                <span className='error-message'>{message}</span>

                              )}
                            />
                          </div>
                        </div>
                        <div className='col-lg-6 col-md-6'>
                          <div className='form-group'>
                            <select name="" id="" class="form-control"
                              {...register('time_of_reservation')}
                            >
                              {getTimeData.map((item) => {
                                return (
                                  <option value={item}>{item}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row pt-3'>
                    <div className='col-lg-12'>
                      <h3>{lang.no_of_people}<span></span></h3>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                      <div className='form-group form_select_arrow'>
                        <label>{lang.adult}<span></span></label>
                        <select name="" id="" class="form-control"
                          {...register('no_of_adults')}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="3">4</option>
                          <option value="3">5</option>
                          <option value="3">6</option>
                          <option value="3">7</option>
                          <option value="3">8</option>
                          <option value="3">9</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                      <div className='form-group form_select_arrow'>
                        <label>{lang.child}<span></span></label>
                        <select name="" id="" class="form-control"
                          {...register('no_of_children')}
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="3">4</option>
                          <option value="3">5</option>
                          <option value="3">6</option>
                          <option value="3">7</option>
                          <option value="3">8</option>
                          <option value="3">9</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <label>{lang.select_course}<span>*</span></label>
                    </div>
                    <div className='col-lg-12 col-md-12'>
                      <div class="form-group radio-outer">
                        <label class="tripradio">{lang.breakfast}
                          <input type="radio" class="form-control" name="select_course" value="Breakfast" checked
                            {...register('select_course')}
                          >
                          </input>
                          <span class="checkmark"></span></label>
                        <label class="tripradio">{lang.lunch}
                          <input type="radio" class="form-control" name="select_course" value="Lunch"
                            {...register('select_course')}
                          >
                          </input>
                          <span class="checkmark"></span></label>
                        <label class="tripradio">{lang.dinner}
                          <input type="radio" class="form-control" name="select_course" value="Dinner"
                            {...register('select_course')}
                          >
                          </input>
                          <span class="checkmark"></span></label>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.special_requests}</label>
                        <textarea class="form-control" name="" rows="" cols=""
                          {...register('special_requests', {
                          })}
                        ></textarea>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12'>
                      <div className='form-group'>
                        <label>{lang.additional_remarks}</label>
                        <textarea class="form-control" name="" rows="" cols=""
                          {...register('additional_remarks', {
                          })}
                        ></textarea>
                      </div>
                    </div>

                    <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                      <button type="submit" class="form_submit_btn">{lang.submit}</button>
                    </div>
                    {
                      tokenData.data.isDefaultUser &&
                      <div className='col-lg-12 col-md-12 d-flex justify-content-end'>
                        <p className='form_note'>{lang.note_now_you_are_on_guest_user_please} <a href='#' onClick={() => setShowLoginModal(true)}>{lang.login}</a> {lang.to_submit_your_enquiry}</p>
                      </div>
                    }
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section >
    </React.Fragment >
  )
}
export default RestaurantReservation;