import reduxStore from '../redux/store'


reduxStore.subscribe(getServiceDetailsById)


export function getServiceDetailsById(serviceId) {
    let store = reduxStore.getState();
    const serviceInfo = store.serviceInfo;
    if (serviceInfo.loading == false && serviceInfo.error == "" && serviceId) {
        let serviceData = serviceInfo.data.find((serviceItem) => {
            return serviceItem.serviceID == serviceId

        })
        return serviceData;
    }
}
export const serviceUrlList = {
    1: 'Business-Banking-Outsourcing-Contact-Center',
    2: 'Car-Renewal',
    3: 'CPR-Passport-Licence-Renewal',
    4: 'Flight-Ticket-Acquisition',
    5: 'Global-Airport-Services',
    6: 'Global-Concierge-Service',
    7: 'Hotel-Bookings',
    8: 'House-Maid-Services',
    9: 'Limousine-Car-Rental-Services',
    10: 'Luggage-Pickup-Service',
    11: 'Meet-And-Assist',
    12: 'Messenger-Services',
    13: 'MICE-International-Wedding-Planning',
    14: 'Personal-Assistance',
    15: 'Restaurant-Reservation',
    16: 'Roadside-Assistance',
    17: 'Soft-Skill-Training',
    18: 'Sports-And-Cultural-Events',
    19: 'Holiday-Packages',
    20: 'VVIP-Services',
    21: 'Holiday-Packages',
    22: 'Allocations-And-Pets',
    23: 'Governmental-Services',
    24: 'Life-Style-Services',
    26 : 'Personal-Services',
    27: 'VISA-Services'
}